import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
// import Logo from 'components/Logo';

// ==============================|| DRAWER HEADER ||============================== //
const userType = localStorage.getItem("userType");

const DrawerHeader = ({ open }) => {
  let navigate = useNavigate();
  const theme = useTheme();
const handleNavigate = () => {
  navigate('/dashboard/admin'); 
}
  return (
    // only available in paid version
    <DrawerHeaderStyled theme={theme} open={open}>
      <Stack direction="row" spacing={1} alignItems="center">
        {/* <Logo /> */}
        <Typography variant="h4" sx={{ color: "#fff", cursor:userType === 'AD'?'pointer':'default' }} onClick = {userType === 'AD' ? handleNavigate : undefined}>MUSICINDUSTREE</Typography>
      </Stack>
    </DrawerHeaderStyled>
  );
};

DrawerHeader.propTypes = {
  open: PropTypes.bool
};

export default DrawerHeader;
