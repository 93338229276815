// assets
import LyricsIcon from '@mui/icons-material/Lyrics';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PianoIcon from '@mui/icons-material/Piano';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';

// icons
const icons = {
    LyricsIcon,
    QueueMusicIcon,
    PersonAddAltIcon,
    GraphicEqIcon,
    PianoIcon,
    BarChartIcon,
    PeopleIcon,
    LibraryMusicIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //
const userType = localStorage.getItem("userType");

const AD = userType === 'AD' && {
    id: 'utilities',
    title: 'Profile',
    type: 'group',
    children: [
        userType === 'AD' && {
            id: 'adminDashboard',
            title: 'Admin Dashboard',
            type: 'item',
            url: '/dashboard/admin',
            icon: icons.BarChartIcon
        },
        userType === 'AD' && {
            id: 'adminuser',
            title: 'User',
            type: 'item',
            url: '/dashboard/user',
            icon: icons.PeopleIcon
        },
        userType === 'AD' && {
            id: 'listCollapse',
            title: 'List',
            type: 'listCollapse',
            icon: icons.QueueMusicIcon,
            children: [
                {
                    id: 'listEvents',
                    title: 'Events',
                    type: 'item2',
                    url: '/dashboard/eventlist',
                    icon: icons.QueueMusicIcon
                },
                {
                    id: 'listArtist',
                    title: 'Artist',
                    type: 'item2',
                    url: '/dashboard/listartist',
                    icon: icons.LyricsIcon
                },
                {
                    id: 'listAmps',
                    title: 'Amps',
                    type: 'item2',
                    url: '/dashboard/amplist',
                    icon: icons.GraphicEqIcon
                },
                {
                    id: 'sessionMusicians',
                    title: 'Session Musicians',
                    type: 'item2',
                    url: '/dashboard/session-musicians',
                    icon: icons.PianoIcon
                },
                {
                    id: 'artistRepertoire',
                    title: 'Artist & Repertoire',
                    type: 'item2',
                    url: '/dashboard/artist-repertoire',
                    icon: icons.LibraryMusicIcon
                }
            ]
        },
        userType === 'AD' && {
            id: 'createCollapse',
            title: 'Create',
            type: 'createCollapse',
            icon: icons.QueueMusicIcon,
            children: [
                {
                    id: 'createEvents',
                    title: 'Events',
                    type: 'item2',
                    url: '/dashboard/eventcreate',
                    icon: icons.QueueMusicIcon
                },
                {
                    id: 'util-artistprofile',
                    title: 'Artist Profile',
                    type: 'item2',
                    url: '/dashboard/artistprofile',
                    icon: icons.PersonAddAltIcon
                },
                {
                    id: 'util-amp',
                    title: 'AMP Profile',
                    type: 'item2',
                    url: '/dashboard/amps',
                    icon: icons.GraphicEqIcon
                },
                {
                    id: 'util-atr',
                    title: 'Artist & Repertoire',
                    type: 'item2',
                    url: '/dashboard/create-artists-repertoire',
                    icon: icons.PersonAddAltIcon
                },
            ]
        }
    ].filter(Boolean)
};

export default AD;
