import React, { useState, useEffect } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl
  // Checkbox,
} from '@mui/material';
import { Formik, Field, FieldArray } from 'formik';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Country } from 'data/Country';
import { states } from 'data/State';
import * as Yup from 'yup';
import makeApiCall from 'common/makeApiCall';
// Date Picker MUI----------------------->
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { serviceDetailList } from 'data/Common';
import AdminAuthRegister from 'pages/admin/AdminAuthRegister';

const steps = ['Basic Details *', 'Service Details *', 'Payment Details', 'Availability *', 'Open for Work *'];
const validationSchemaStep1 = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required').max(25, 'Maximum 25 characters allowed'),
  website: Yup.string()
    .required('Website URL is required')
    .matches(/^https:\/\/([a-zA-Z0-9-]{1,63}\.)+[a-zA-Z]{2,6}(\/[^\s]*)?$/, 'Enter a valid website URL starting with https://'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  phone: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required')

  // paymentStatus: Yup.string().required('Payment Status is required'),
  // currencyType: Yup.string().required('Currency Type is required'),
  // amount: Yup.number().positive().required('Amount is required'),
  // rates: Yup.string().required('Rates are required'),
});

const validationSchemaStep2 = Yup.object().shape({
  serviceDescription: Yup.string().required('Service Description is required'),
  options: Yup.array().min(1, 'Please select one of the service details').required('Please choose an option')
});
const validationSchemaStep3 = Yup.object({
  paidStatus: Yup.string().test('all-field-filled', 'Please provide values for all fields', function() {
    const {currencyType, amount, rates, paymentStatus} = this.parent;

    if (paymentStatus==='P') {
      return currencyType && amount && rates;
    }
return true;

  }),
});
const validationSchemaStep4 = Yup.object({
  eventStartTime: Yup.date().nullable().required('StartTime is required'),
  eventEndTime: Yup.date().nullable().required('EndTime is required')
});
const validationSchemaStep5 = Yup.object({
  openForWork: Yup.boolean().required('Availability status is required')
});

const PaymentType = [
  {
    id: 'PH',
    name: 'Per Hour'
  },
  {
    id: 'PD',
    name: 'Per Day'
  },
  {
    id: 'PJ',
    name: 'Per Project'
  }
];

const currencies = [
  {
    value: 'USD',
    label: '$'
  },
  {
    value: 'INR',
    label: '₹'
  }
];

export default function Amps() {
  const classes = useStyles();
  const userType = localStorage.getItem('userType');
  const [activeStep, setActiveStep] = React.useState(0);
  const [userIdFromAdmin, setUserIdFromAdmin] = useState('');
  const [openProfileCreate, setOpenProfileCreate] = useState(false);
  const [ampDetails, setAMPDetails] = useState(false);
  // const { validateForm } = useFormikContext();
  let navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [ampUpdateData, setAmpUpdateData] = useState('');

  const [filteredStates, setFilteredStates] = useState([]);

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    Country.find((c) => c.id === countryId)?.name || '';
    setSelectedCountry(countryId);
    //   setSelectedCountryName(countryName);
    setFilteredStates(states[countryId] || []);
  };

  const {
    id,
    EndTime,
    StartTime,
    city,
    country,
    email,
    fullName,
    openForWork,
    phone,
    amount,
    currencyType,
    rates,
    paymentStatus,
    serviceDescription,
    services,
    state,
    website
  } = (ampUpdateData && ampUpdateData) || [];

  // ------/ set created id or existing id /-------
  const ampId = id || ampDetails.id;

  const fetchArtist = async () => {
    try {
      const artistData = await makeApiCall('get', `/amps/myprofile`);
      setAmpUpdateData(artistData);
    } catch (error) {
      // setError(error);
    }
  };

  useEffect(() => {
    fetchArtist();
    if (ampUpdateData) {
      setSelectedCountry(country);
      setFilteredStates(states[country] || []);
    }
  }, [country]);

  const validationSchema =
    activeStep === 0
      ? validationSchemaStep1
      : activeStep === 1
        ? validationSchemaStep2
      : activeStep === 2
        ? validationSchemaStep3
        : activeStep === 3
          ? validationSchemaStep4
          : activeStep === 4
            ? validationSchemaStep5
            : null;

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const formattedTime = (time) => {
    return dayjs(`2022-04-17 ${time}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm');
  };

  const handleRegisterSuccess = (userId) => {
    setUserIdFromAdmin(userId);
    setOpenProfileCreate(true); // Set state to true once registration is successful
  };

  return (
    <>
      <Formik
        initialValues={{
          fullName: fullName ? fullName : '',
          email: email ? email : '',
          website: website ? website : '',
          country: country ? country : '',
          state: state ? state : '',
          city: city ? city : '',
          phone: phone ? phone : '',
          options: services ? services.split(',') : '',
          serviceDescription: serviceDescription ? serviceDescription : '',
          paymentStatus: paymentStatus ? paymentStatus : 'P',
          currencyType: currencyType ? currencyType : '',
          amount: amount ? amount : '',
          rates: rates ? rates : 'PH',
          eventStartTime: StartTime ? dayjs(formattedTime(StartTime)) : null,
          eventEndTime: EndTime ? dayjs(formattedTime(EndTime)) : null,
          openForWork: openForWork === true ? 'true' : openForWork === false ? 'false' : ''
        }}
        enableReinitialize={activeStep === 0 ? true : false}
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // if (activeStep === 0) {
          //   const errors = await validateForm();
          //   if (Object.keys(errors).length > 0) {
          //     setErrors(errors);
          //     setSubmitting(false);
          //     return;
          //   }
          // }
          try {
            const formattedStartTime = values.eventStartTime ? dayjs(values.eventStartTime).format('hh:mm A') : '';
            const formattedEndTime = values.eventEndTime ? dayjs(values.eventEndTime).format('hh:mm A') : '';
            const formattedServices = values?.options?values?.options?.join(','):"";
            const requestData = {
              ...values,
              services: formattedServices,
              StartTime: formattedStartTime,
              EndTime: formattedEndTime,
              basicDetailsCompleted: activeStep && activeStep>0?'Y':'N',
          serviceDetailsCompleted: activeStep && activeStep>1?'Y':'N',
          paymentDetailsCompleted: activeStep && activeStep>2?'Y':'N',
          availabilityCompleted: activeStep && activeStep>3?'Y':'N',
          openForWorkCompleted: values.submitFlag==="submitAMPS"?'Y':'N',
          ampApplicationStatus: values.submitFlag==="submitAMPS"?'CP':'IP',
              ...(userType === 'AD' && { userId: userIdFromAdmin })
            };
            const ampEndPoint = ampId ? '/amps/ampupdate' : '/amps/ampcreate';
            const ampMethod = ampId ? 'put' : 'post';
            const responseData = await makeApiCall(ampMethod, ampEndPoint, requestData, 
              values.submitFlag==="submitAMPS"?'AMP Profile create successful':null, 'AMP Profile create failed');
            setStatus({ success: true });
            setAMPDetails(responseData?.data)
            if (activeStep === 4 && userType != 'AD' && values.submitFlag==="submitAMPS") {
              setSubmitting(false);
              window.location.href = `/dashboard/amp-my-profile`;
            } else 
            if (userType == 'AD'&& values.submitFlag==="submitAMPS")  {
              
              navigate('/dashboard/eventlist');
            } else {
              console.log("Not completed");
              
            }
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          // isSubmitting,
          validateForm,
          handleSubmit,
          setTouched
          // isValid
        }) => {
          
          const handleNext = async () => {
            const errors = await validateForm(); // Validate the current form step
            if (Object.keys(errors).length === 0) {
              setActiveStep((prevStep) => prevStep + 1); // Move to the next step
              handleSubmit();
            } else {
              setTouched(errors); // Mark fields as touched if there are errors
            }
          };

          return (
            <>
              {userType === 'AD' && !openProfileCreate ? (
                <AdminAuthRegister adminUserType={'AMPAdmin'} onSuccess={handleRegisterSuccess} />
              ) : (
                <Box sx={{ width: '100%' }}>
                  <Stepper className={classes.stepper} activeStep={activeStep}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    {activeStep === steps.length ? (
                      <Button>Reset</Button>
                    ) : (
                      <>
                        <Box sx={{ mt: 2, mb: 1 }}>
                          {activeStep === 0 && (
                            <Grid container justifyContent="center" item xs={12} sm={12} md={12} alignItems="center">
                              <Box className="outerBorderBox">
                                <Box className="innerBorderBox">
                                  <Grid container justifyContent="center">
                                    <Grid item xs={11} sm={8} md={6}>
                                      <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        sx={{
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                          width: '100%'
                                        }}
                                      >
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="fullName"
                                          label="Name*"
                                          value={values.fullName}
                                          onChange={(e) => {
                                            const trimmedValue = e.target.value.trimStart();
                                            // Remove special characters, numbers, and HTML tags
                                            let sanitizedValue = trimmedValue
                                              .replace(/<[^>]*>/g, '') // Remove HTML tags
                                              .replace(/[^a-zA-Z\s]/g, '');
                                            // if (sanitizedValue.length > 30) {
                                            //     sanitizedValue = sanitizedValue.substring(0, 30);
                                            // }
                                            handleChange({ target: { name: 'fullName', value: sanitizedValue } });
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{ maxWidth: 600,
                                            '& .MuiFormLabel-root': {
                                              height: '20px'
                                            }
                                           }}
                                        />
                                        {touched.fullName && errors.fullName && <Typography color="error">{errors.fullName}</Typography>}
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="website"
                                          label="website*"
                                          value={values.website}
                                          onChange={(e) => {
                                            const trimmedValue = e.target.value.trimStart();
                                            // Remove special characters, numbers, and HTML tags
                                            const sanitizedValue = trimmedValue.replace(/<[^>]*>/g, ''); // Remove HTML tags
                                            handleChange({ target: { name: 'website', value: sanitizedValue } });
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{ maxWidth: 600, '& .MuiFormLabel-root': {
                                            height: '20px'
                                          } }}
                                        />
                                        {touched.website && errors.website && <Typography color="error">{errors.website}</Typography>}
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="email"
                                          label="Email*"
                                          value={values.email}
                                          onChange={(e) => {
                                            const trimmedValue = e.target.value.trimStart();
                                            // Remove special characters, numbers, and HTML tags
                                            const sanitizedValue = trimmedValue
                                              .replace(/<[^>]*>/g, '') // Remove HTML tags
                                              .replace(/[^a-zA-Z\s@.]/g, '');
                                            handleChange({ target: { name: 'email', value: sanitizedValue } });
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{ maxWidth: 600, '& .MuiFormLabel-root': {
                                            height: '20px'
                                          } }}
                                        />
                                        {touched.email && errors.email && <Typography color="error">{errors.email}</Typography>}
                                        <TextField
                                          margin="dense"
                                          id="country"
                                          label="Country*"
                                          select
                                          fullWidth
                                          name="country"
                                          onBlur={handleBlur}
                                          value={selectedCountry}
                                          onChange={(event) => {
                                            handleCountryChange(event);
                                            handleChange(event);
                                          }}
                                          sx={{
                                            maxWidth: 600,
                                            '& .MuiFormLabel-root': {
                                              height: '20px'
                                            }
                                          }}
                                        >
                                          {Country.map((option) => (
                                            <MenuItem
                                              sx={{ display: 'flex', flexWrap: 'wrap' }}
                                              key={option.id}
                                              value={option.id}
                                              disabled={option.disabled}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                        {touched.country && errors.country && <Typography color="error">{errors.country}</Typography>}

                                        <TextField
                                          margin="dense"
                                          id="state"
                                          label="State or Provinces*"
                                          select
                                          fullWidth
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.state}
                                          name="state"
                                          sx={{ maxWidth: 600,'& .MuiFormLabel-root': {
                                            height: '20px'
                                          } }}
                                          disabled={!selectedCountry || selectedCountry === '0'}
                                        >
                                          {filteredStates.map((option) => (
                                            <MenuItem
                                              sx={{ display: 'flex', flexWrap: 'wrap' }}
                                              key={option.id}
                                              value={option.id}
                                              disabled={option.disabled}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                        {touched.state && errors.state && <Typography color="error">{errors.state}</Typography>}
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="city"
                                          label="City*"
                                          value={values.city}
                                          sx={{
                                            maxWidth: 600,
                                            '& .MuiFormLabel-root': {
                                              height: '20px'
                                            }
                                          }}
                                          onChange={(e) => {
                                            const trimmedValue = e.target.value.trimStart();
                                            // Remove special characters, numbers, and HTML tags
                                            let sanitizedValue = trimmedValue
                                              .replace(/<[^>]*>/g, '') // Remove HTML tags
                                              .replace(/[^a-zA-Z\s]/g, '');
                                            if (sanitizedValue.length > 50) {
                                              sanitizedValue = sanitizedValue.substring(0, 50);
                                            }
                                            handleChange({ target: { name: 'city', value: sanitizedValue } });
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                        />
                                        {touched.city && errors.city && <Typography color="error">{errors.city}</Typography>}
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="phone"
                                          label="Contact Number*"
                                          value={values.phone}
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            const value = e.target.value.replace(/\D/g, ''); // Use regex to allow only numbers
                                            handleChange({ target: { name: 'phone', value: value.slice(0, 10) } }); // Limit input to 10 digits
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{ maxWidth: 600,'& .MuiFormLabel-root': {
                                            height: '20px'
                                          } }}
                                          inputProps={{
                                            maxLength: 10 // Limit input length to 10 characters
                                          }}
                                        />
                                        {touched.phone && errors.phone && <Typography color="error">{errors.phone}</Typography>}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                          {activeStep === 1 && (
                            <Grid container rowSpacing={3}>
                              <Grid container justifyContent="center" item xs={12} sm={12} md={12} alignItems="center">
                                <Box className="outerBorderBox">
                                  <Box className="innerBorderBox">
                                    <Grid container justifyContent="center">
                                      <Grid item xs={11} sm={8} md={6}>
                                        <Box
                                          sx={{
                                            display: 'flex', // Flexbox layout
                                            width: '100%',
                                            justifyContent: 'center', // Horizontal center
                                            alignItems: 'center', // Vertical center
                                            flexDirection: 'column', // Align children in a column
                                            padding: '16px' // Optional: Adds padding inside the box
                                          }}
                                        >
                                          <FieldArray name="options" alignItems="center">
                                            {({ push, remove }) => (
                                              <div>
                                                {serviceDetailList.map((option) => (
                                                  <div key={option.value} style={{ display: 'flex', alignItems: 'center', padding: '3px' }}>
                                                    <Field
                                                      type="checkbox"
                                                      style={{ width: '17px', height: '17px' }}
                                                      id={`options-${option.value}`} // Unique id for each checkbox
                                                      name="options"
                                                      value={option.value}
                                                      onBlur={handleBlur}
                                                      checked={values.options && values.options.includes(option.value)}
                                                      onChange={(e) => {
                                                        if (e.target.checked) {
                                                          push(option.value);
                                                        } else {
                                                          const index = values.options.indexOf(option.value);
                                                          remove(index);
                                                        }
                                                      }}
                                                    />
                                                    <label
                                                      htmlFor={`options-${option.value}`}
                                                      style={{ paddingLeft: '7px', cursor: 'pointer', userSelect: 'none' }}
                                                    >
                                                      {option.label}
                                                    </label>
                                                  </div>
                                                ))}
                                              </div>
                                            )}
                                          </FieldArray>
                                          {touched.options && errors.options && <Typography color="error">{errors.options}</Typography>}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid container justifyContent="center" item xs={12} sm={12} md={12} alignItems="center">
                                <Box className="outerBorderBox">
                                  <Box className="innerBorderBox">
                                    <Grid container justifyContent="center" alignItems="center">
                                      <Grid item xs={10} sm={8} md={6}>
                                        <Box component="form" noValidate autoComplete="off">
                                          <TextField
                                            className={classes.textfield}
                                            id="outlined-multiline-flexible"
                                            multiline
                                            maxRows={8}
                                            fullWidth
                                            name="serviceDescription"
                                            label="Service Description*"
                                            value={values.serviceDescription}
                                            onChange={(e) => {
                                              const trimmedValue = e.target.value.trimStart();
                                              const sanitizedValue = trimmedValue
                                                .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
                                                .replace(/<\s*\/?\s*>/g, ''); // Remove empty tags
                                              handleChange({ target: { name: 'serviceDescription', value: sanitizedValue } });
                                            }}
                                            onBlur={handleBlur}
                                            margin="normal"
                                            sx={{
                                              maxWidth: 600,
                                              height: 200,
                                              '& .MuiFormLabel-root': {
                                                height: '20px'
                                              }
                                            }}
                                            inputProps={{
                                              maxLength: 1000 // Setting maximum character limit
                                            }}
                                          />

                                          {touched.serviceDescription && errors.serviceDescription && (
                                            <Typography color="error">{errors.serviceDescription}</Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {activeStep === 2 && (
                            <Grid container justifyContent="center" item xs={12} sm={12} md={12} alignItems="center">
                              <Box className="outerBorderBox">
                                <Box className="innerBorderBox">
                                  <Grid item xs={12} sm={12} md={12}>
                                    <Grid container justifyContent="center">
                                      <Grid item xs={11} sm={12} md={12} justifyContent="center" alignItems="center">
                                        <Box
                                          sx={{
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%'
                                          }}
                                        >
                                          <FormControlLabel
                                            sx={{ display: 'flex', justifyContent: 'center' }}
                                            control={
                                              <Switch
                                                checked={values?.paymentStatus === 'P'} // Directly using Formik value
                                                onChange={(event) => {
                                                  setFieldValue('paymentStatus', event.target.checked ? 'P' : 'UP');
                                                  if (!event.target.checked && !fullName) {
                                                    setFieldValue('amount', '');
                                                    setFieldValue('rate', '');
                                                    setFieldValue('currencyType', '');
                                                  }
                                                }}
                                                value={values?.paymentStatus}
                                                color="primary"
                                              />
                                            }
                                            label="Paid"
                                          />

                                          {values?.paymentStatus === 'P' && (
                                            <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
                                              <Grid
                                                item
                                                xs={8}
                                                sm={6}
                                                md={12}
                                                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                                              >
                                                <TextField
                                                  sx={{ width: '100%', maxWidth: '200px' }}
                                                  margin="dense"
                                                  id="outlined-select-currency"
                                                  select
                                                  label="CUR"
                                                  name="currencyType"
                                                  value={values.currencyType}
                                                  onChange={handleChange}
                                                >
                                                  {currencies.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                      {option.label}
                                                    </MenuItem>
                                                  ))}
                                                </TextField>
                                                <TextField
                                                  margin="dense"
                                                  id="outlined-basic"
                                                  variant="outlined"
                                                  name="amount"
                                                  label="Amount"
                                                  sx={{ width: '100%', maxWidth: '200px' }}
                                                  value={values.amount}
                                                  onChange={(e) => {
                                                    // const value = e.target.value.replace(/\D/g, ''); // Sanitize the input
                                                    // setFieldValue('amount', value.slice(0, 10)); // Update Formik value directly

                                                    const re = /^[0-9]\d*(\.\d{0,2})?$/; // --------/ Numerics with 2 digits after decimal /-------
                                                    if (re.test(e.target.value) || e.target.value === '') {
                                                        handleChange(e);
                                                    }

                                                  }}
                                                  inputProps={{
                                                    maxLength: 10 // Limit input length to 10 characters
                                                  }}
                                                  fullWidth
                                                />
                                                {touched.amount && errors.amount && <Typography color="error">{errors.amount}</Typography>}

                                                <TextField
                                                  margin="dense"
                                                  id="rates"
                                                  label="Rates"
                                                  select
                                                  fullWidth
                                                  name="rates"
                                                  onBlur={handleBlur}
                                                  value={values.rates}
                                                  sx={{ width: '100%', maxWidth: '200px' }}
                                                  onChange={handleChange}
                                                >
                                                  {PaymentType.map((option) => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                      {option.name}
                                                    </MenuItem>
                                                  ))}
                                                </TextField>
                                              </Grid>
                                              {touched.paidStatus && errors.paidStatus && <Typography color="error">{errors.paidStatus}</Typography>}
                                            </Grid>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                          {activeStep === 3 && (
                            <Grid container justifyContent="center" item xs={12} sm={12} md={12} alignItems="center">
                              <Box className="outerBorderBox">
                                <Box className="innerBorderBox">
                                  <Grid container justifyContent="center">
                                    <Grid item xs={11} sm={8} md={6}>
                                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <Box
                                          sx={{
                                            display: 'flex', // Flexbox layout
                                            width: '100%',
                                            justifyContent: 'center', // Horizontal center
                                            alignItems: 'center', // Vertical center
                                            flexDirection: 'column' // Align children in a column
                                          }}
                                        >
                                          <Typography variant="h5" color="white" sx={{ marginBottom: 1 }}>
                                            Starting Time
                                          </Typography>
                                          <TimePicker
                                            className={classes.date}
                                            label="Starting Time"
                                            value={values.eventStartTime}
                                            onChange={(newValue) => setFieldValue('eventStartTime', newValue)}
                                            renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
                                            sx={{
                                              '& .MuiFormLabel-root': {
                                                height: '20px'
                                              }
                                            }}
                                          />
                                          {touched.eventStartTime && errors.eventStartTime && (
                                            <Typography color="error">{errors.eventStartTime}</Typography>
                                          )}
                                          <Typography variant="h5" color="white" sx={{ marginBottom: 1 }}>
                                            Ending Time
                                          </Typography>
                                          <TimePicker
                                            className={classes.date}
                                            label="Ending Time"
                                            sx={{
                                              '& .MuiFormLabel-root': {
                                                height: '20px'
                                              }
                                            }}
                                            value={values.eventEndTime}
                                            onChange={(newValue) => setFieldValue('eventEndTime', newValue)}
                                            renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
                                          />
                                          {touched.eventEndTime && errors.eventEndTime && (
                                            <Typography color="error">{errors.eventEndTime}</Typography>
                                          )}
                                        </Box>
                                      </LocalizationProvider>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                          {activeStep === 4 && (
                            <Grid container justifyContent="center" item xs={12} sm={12} md={12} alignItems="center">
                              <Box className="outerBorderBox">
                                <Box className="innerBorderBox">
                                  <Grid container justifyContent="center">
                                    <Grid item xs={11} sm={8} md={6}>
                                      <Box
                                        sx={{
                                          display: 'flex', // Flexbox layout
                                          width: '100%',
                                          justifyContent: 'center', // Horizontal center
                                          alignItems: 'center', // Vertical center
                                          flexDirection: 'column' // Align children in a column
                                        }}
                                      >
                                        <FormControl component="fieldset">
                                          <RadioGroup name="openForWork" value={values.openForWork} onChange={handleChange}>
                                            <FormControlLabel value="true" control={<Radio />} label="Available" />
                                            <FormControlLabel value="false" control={<Radio />} label="Unavailable" />
                                          </RadioGroup>
                                          {touched.openForWork && errors.openForWork && (
                                            <Typography color="error">{errors.openForWork}</Typography>
                                          )}
                                        </FormControl>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            Back
                          </Button>
                          <Box sx={{ flex: '1 1 auto' }} />
                          <Button
                            className={classes.button}
                            onClick={async () => {
                              if (activeStep === steps.length - 1) {
                                handleSubmit(); // Submit the form on the last step
                                setFieldValue("submitFlag", "submitAMPS")
                              } else {
                                await handleNext(); // Validate and go to next step
                              }
                            }}
                            type="button"
                            // disabled={isSubmitting || !isValid} // Disable if form is invalid or untouched
                          >
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    color: '#fff'
  },
  date: {
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'none',
    marginBottom: 20
  },
  textfield: {
    backgroundColor: '#fff',
    borderRadius: 5
  },
  stepper: {
    marginBottom: '50px',
    flexWrap: 'wrap',
    gap: 0.5,
    '& .MuiStepLabel-root': {
      color: 'gray', // Default text color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed step color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active step color
      }
    },
    '& .MuiStepLabel-label': {
      color: '#fff', // Default text color
      '&.Mui-completed': {
        color: 'grey' // Completed step color
      },
      '&.Mui-active': {
        color: '#fff' // Active step color
      }
    },
    '& .MuiStepIcon-root': {
      color: 'gray', // Default icon color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed icon color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active icon color
      }
    }
  }
}));
