import React, { useEffect, useState } from 'react';
// import { makeStyles } from '@mui/styles';
import makeApiCall from 'common/makeApiCall';
import { Link } from 'react-router-dom';
import profile from "../../assets/images/users/profile.png"
import { styled } from '@mui/material/styles';
import PaginationItem from '@mui/material/PaginationItem';
import {
    Grid, Card, Box, CardContent, Typography, Chip, CardMedia, Container, LinearProgress, Pagination,
    FormControl, TextField, MenuItem, Button, IconButton
} from '@mui/material';
// import { states } from 'data/State';
import { states } from 'data/State';
import { Country } from 'data/Country';
import noData from '../../assets/icons/nodata.png';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { serviceDetailList } from 'data/Common';


// import AccountCircleIcon from '@mui/icons-material/AccountCircle';
// import { Grid, Card, CardMedia, Box, CardContent, Typography } from '@mui/material';

// const servicesOptions = [
//     { value: 'Recording Studios', label: 'Recording Studios' },
//     { value: 'Sound Engineer', label: 'Sound Engineer' },
//     { value: 'Videography', label: 'Videography' },
//     { value: 'Photography', label: 'Photography' },
//     { value: 'Mixing', label: 'Mixing' },
//     { value: 'Mastering', label: 'Mastering' },
//     { value: 'Editing', label: 'Editing' },
//     { value: 'Producer', label: 'Producer' },
//     { value: 'A & R Professional', label: 'A & R Professional' },
//     { value: 'Talent Search Professional', label: 'Talent Search Professional' },
//     { value: 'Others', label: 'Others' }
// ];
const options1 = [
    { value: 'P', label: 'Paid' },
    { value: 'UP', label: 'Unpaid' },

];

export default function Amplist() {
    const [amps, setAmps] = useState([]);
    const [fullName, setFullName] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState('');
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [filteredStates, setFilteredStates] = useState([]);
    const [error, setError] = useState(null);

    const handleSubmit = async (data) => {
      if (data){
        setPage(1)
      }
        try {
            setLoading(true);
            const response = await makeApiCall("post", `/amps/ampslist?page=${page}`,
                {
                    fullName: fullName,
                    country: selectedCountry,
                    state: selectedState,
                    services: selectedService,
                    paymentStatus: value,
                }
            );
            setAmps(response.data); // Setting the array of events
            setTotalPages(response.pagination?.totalPages || 1); // Setting the total number of pages with a fallback
        } catch (error) {
            setError(error);
            console.error("Error fetching events:", error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        handleSubmit();
    }, [page]);
    useEffect(() => {
        if (selectedCountry) {
            setFilteredStates(states[selectedCountry] || []);
        } else {
            setFilteredStates([]);
        }
    }, [selectedCountry]);


    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const handleCountryChange = (event) => {
        setSelectedCountry(event.target.value);
    };
    const handleStateChange = (event) => {
        setSelectedState(event.target.value);
    };
    const handleServiceChange = (event) => {
        setSelectedService(event.target.value);
    };
    const handlePageChange = (event, newPage) => {
        setPage(newPage);
    };
    const handlenameChange = (event) => {
        setFullName(event.target.value);
    };

    if (loading) {
        return <Box sx={{ width: '100%' }}>
            <LinearProgress />
        </Box>;
    }

    if (error) {
        return <div>Error: {error.message}</div>;
    }

    // Function to capitalize the first letter of a string
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    return (

        <>
            <Box sx={{ background: 'linear-gradient(to right, #ff0093, #7900ff)', padding: 1, borderRadius: 1 }}>
                <form>
                    <Grid container sx={{ alignItems: "center" }} spacing={2}>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <TextField
                                    margin="dense"
                                    id="outlined-basic"
                                    label="Name"
                                    name="fullname"
                                    variant="outlined"
                                    value={fullName}
                                    onChange={handlenameChange}
                                    sx={{
                                        backgroundColor: 'white',
                                        borderRadius: 2
                                      }}
                                    InputProps={
                                        fullName?.length > 0 && {
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                backgroundColor: 'white'
                                              }}
                                            >
                                              <IconButton
                                                onClick={() => setFullName('')} 
                                              >
                                                <CloseIcon fontSize="small" />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }
                                      }
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <TextField
                                    margin="dense"
                                    id="country"
                                    label="Country"
                                    select
                                    fullWidth
                                    name="country"
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                            height: "20px"
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: 2
                                    }}
                                    value={selectedCountry}
                                    onChange={handleCountryChange}
                                    InputProps={
                                        selectedCountry?.length > 0 && {
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                marginRight: '12px'
                                              }}
                                            >
                                              <IconButton
                                                onClick={() => 
                                                  {setSelectedCountry('');
                                                  setSelectedState('');
                                                  }}
                                              >
                                                <CloseIcon fontSize="small" />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }
                                      }
                                >
                                    {Country.map((option) => (
                                        <MenuItem
                                            sx={{ display: "flex", flexWrap: "wrap" }}
                                            key={option.id}
                                            value={option.id}
                                            disabled={option.disabled}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <TextField
                                    margin="dense"
                                    id="state"
                                    label="State"
                                    select
                                    fullWidth
                                    name="state"
                                    onChange={handleStateChange}
                                    value={selectedState}
                                    disabled={!selectedCountry || selectedCountry === "0"}
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                          height: '20px'
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: 2
                                      }}
                                    InputProps={
                                        selectedState?.length > 0 && {
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                marginRight: '12px' 
                                              }}
                                            >
                                              <IconButton
                                                onClick={() => setSelectedState('')} 
                                              >
                                                <CloseIcon fontSize="small" />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }
                                      }
                                // value={filteredStates.find((state) => state.id === selectedCountry)?.id || ''} // Handle cases where no state matches selectedCountry
                                >
                                    {filteredStates.map((option) => (
                                        <MenuItem
                                            sx={{ display: "flex", flexWrap: "wrap" }}
                                            key={option.id}
                                            value={option.id}
                                            disabled={option.disabled}
                                        >
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <TextField
                                    margin="dense"
                                    id="services"
                                    label="Services"
                                    select
                                    fullWidth
                                    name="services"
                                    value={selectedService}
                                    onChange={handleServiceChange}
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                          height: '20px'
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: 2
                                      }}
                                    InputProps={
                                        selectedService?.length > 0 && {
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                marginRight: '12px' 
                                              }}
                                            >
                                              <IconButton
                                                onClick={() => setSelectedService('')} 
                                              >
                                                <CloseIcon fontSize="small" />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }
                                      }
                                >
                                    {serviceDetailList.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <FormControl fullWidth>
                                <TextField
                                    margin="dense"
                                    id="paymentStatus"
                                    label="Payment Type"
                                    select
                                    fullWidth
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                            height: "20px"
                                        },
                                        backgroundColor: 'white',
                                        borderRadius: 2
                                    }}
                                    name="paymentStatus"
                                    value={value}
                                    onChange={handleChange}
                                    InputProps={
                                        value?.length > 0 && {
                                          endAdornment: (
                                            <InputAdornment
                                              position="end"
                                              sx={{
                                                marginRight: '12px' 
                                              }}
                                            >
                                              <IconButton
                                                onClick={() => setValue('')} 
                                              >
                                                <CloseIcon fontSize="small" />
                                              </IconButton>
                                            </InputAdornment>
                                          )
                                        }
                                      }
                                >
                                    {options1.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Button onClick={()=>handleSubmit('filterFromButton')} variant="contained" size="large">
                                Search
                            </Button>
                        </Grid>
                    </Grid>

                </form>
            </Box >
            {amps.length === 0 ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: "column", gap: 2, alignItems: 'center', height: '100vh' }}>
                    <img src={noData} alt="" style={{ width: '60px', height: '60px' }} />
                    <Typography variant="h6">No Data Available</Typography>
                </Box>
            ) : (
                // {/* ========================|AMP CARD LIST |====================== */}
                <Container>
                    <Grid container spacing={2} sx={{ marginTop: 4 }}>
                        {amps.map((amp) => (
                            <Grid key={amp.userId} item xs={12} sm={6} md={4}>
                                <Card sx={{ display: 'flex', maxWidth: 350, p: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <CardContent sx={{ flex: 'auto' }}>
                                            <Typography component="div" variant="h5" gutterBottom>
                                                {capitalizeFirstLetter(amp.fullName)}
                                            </Typography>
                                            <Link target="_blank" to={`/dashboard/ampview/${amp.userId}`} style={{ textDecoration: 'none' }}>
                                                <Chip label="View Profile" color="primary" />
                                            </Link>
                                        </CardContent>
                                    </Box>
                                    <CardMedia
                                        component="img"
                                        sx={{ width: 120, height: 120, objectFit: 'cover' }}
                                        image={profile || "default_profile_picture_url"} // Use event.profilePictureUrl if available
                                        alt="profile"
                                    />
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                        variant="outlined"
                        renderItem={(item) => <CustomPaginationItem {...item} />}
                        sx={{ marginTop: '5rem', display: 'flex', justifyContent: 'center', }}
                    />


                </Container>
            )}
        </>
    )
}
// const useStyles = makeStyles(() => ({
//     card: {
//         display: 'flex',
//         flexDirection: 'column',
//         borderRadius: 15,
//         position: 'relative',
//         border: '0.01px solid #fff',
//     },
//     avatar: {
//         size: 12,
//     },
// }))

const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
    '&.Mui-selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
    },
    '&.MuiPaginationItem-page': {
        color: '#fff', // Customize page numbers color
    },
    '&.MuiPaginationItem-previousNext': {
        color: 'red', // Customize next/previous buttons color
    },
}));