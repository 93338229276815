import React, { useState, useEffect } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  // Checkbox,
} from '@mui/material';
import { Formik, Field, FieldArray } from 'formik';
import { makeStyles } from '@mui/styles';
import { Country } from 'data/Country';
import { states } from 'data/State';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import makeApiCall from 'common/makeApiCall';
import { genreList, PerformanceTypeList } from 'data/Common';
import YouTubeIcon from '../../assets/icons/youtube.png';
import InstagramIcon from '../../assets/icons/instagram.png';
import FacebookIcon from '../../assets/icons/facebook.png';
import TwitterIcon from '../../assets/icons/twitter.png';
import LinkedInIcon from '../../assets/icons/linkedin.png';
import SpotifyIcon from '../../assets/icons/spotify.png';
import SoundCloudIcon from '../../assets/icons/soundcloud.png';
import AdminAuthRegister from 'pages/admin/AdminAuthRegister';
const validationSchemaStep0 = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required').max(25, 'Maximum 25 characters allowed'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  phone: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be 10 digits')
    .required('Phone number is required')
});
const validationSchemaStep1 = Yup.object().shape({
  genres: Yup.array()
    .default([]) // Ensure genres is always an array
    .min(1, 'Genre is required')
    .test('custom-instrument-check', 'Custom genre is required when selecting Others', function (genres = []) {
      const { otherGenre } = this.parent;
      // Ensure the array has at least one item and handle the "Others" option correctly
      if (genres.length === 0) {
        return false;
      }
      // If "Others" is selected, ensure otherGenre is provided
      if (genres.includes('OT') && !otherGenre?.trim()) {
        return false;
      }
      return true;
    }),
  otherGenre: Yup.string().test(
    'custom-instrument-required',
    'Custom instrument is required when selecting Others',
    (otherGenre, { parent }) => {
      if (parent.genres === 'OT') {
        return !!otherGenre?.trim();
      }
      return true;
    }
  )
});
const validationSchemaStep2 = Yup.object({
  youtubeProfile: Yup.string()
    .matches(
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:user\/|channel\/|c\/)?(?:@[a-zA-Z0-9\-_]+|[a-zA-Z0-9_]+@[a-zA-Z0-9_]+)(?:\/|$|\?)/,
      'Enter a valid YouTube URL, username, or channel ID (including new "@username" format)'
    )
    .nullable(), // Optional field
  instagramProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-zA-Z0-9\-_]+$/, 'Enter a valid Instagram URL or username/ID')
    .nullable(),
  fbProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9._-]+$/, 'Enter a valid Facebook URL or username/ID')
    .nullable(),
  xProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/[a-zA-Z0-9_]+$/, 'Enter a valid X (Twitter) URL or username/ID')
    .nullable(),
  linkedinProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9\-_]+$/, 'Enter a valid LinkedIn URL or username/ID')
    .nullable(),
  spotifyProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?open\.spotify\.com\/artist\/[a-zA-Z0-9\-_]+$/, 'Enter a valid Spotify URL or artist ID')
    .nullable(),
  soundcloudProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?soundcloud\.com\/[a-zA-Z0-9\-_]+$/, 'Enter a valid SoundCloud URL or username/ID')
    .nullable(),
  socialProfiles: Yup.string().test('at-least-one-profile', 'At least one social media profile is required', function () {
    // Access sibling fields via `this.parent`
    const { youtubeProfile, instagramProfile, fbProfile, xProfile, linkedinProfile, spotifyProfile, soundcloudProfile } = this.parent;
    // Check if at least one profile is filled
    return youtubeProfile || instagramProfile || fbProfile || xProfile || linkedinProfile || spotifyProfile || soundcloudProfile;
  })
});
const validationSchemaStep3 = Yup.object({
  company: Yup.string().required('Company is required').max(100, 'Maximum 100 characters allowed'),
  biography: Yup.string().required('Biography is required').max(1000, 'Maximum 1000 characters allowed')
});
const validationSchemaStep4 = Yup.object({
  performanceType: Yup.array().min(1, 'At least one option must be selected')
});
const validationSchemaStep5 = Yup.object({
  youtubeUrl: Yup.string()
    .required('Portfolio is required')
    .matches(/^https:\/\/www\.youtube\.com\/embed\/.+$/, 'Invalid YouTube embed URL')
});

const steps = ['Basic Details *', 'Genre *', 'Social Links *', 'Professional BIO *', 'Performance Type *', 'Add Portfolio *'];

export default function Artistrepertoire() {
  const userType = localStorage.getItem('userType');
  const classes = useStyles();
  let navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [filteredStates, setFilteredStates] = useState([]);
  const [getArtistRepertoire, setGetArtistRepertoire] = useState([]);
  const { city, country, email, fullName, phone, state, genres,  otherGenre, youtubeProfile, instagramProfile, fbProfile,  xProfile, linkedinProfile, spotifyProfile, soundcloudProfile, company, biography, performanceType, youtubeUrl } = (getArtistRepertoire && getArtistRepertoire) || [];
  const [selectedCountry, setSelectedCountry] = useState('');
  const [userIdFromAdmin, setUserIdFromAdmin] = useState('');
  const [openProfileCreate, setOpenProfileCreate] = useState(false);

  // const handleCountryChange = (event) => {
  //     const countryId = event.target.value;
  //     Country.find((c) => c.id === countryId)?.name || '';
  //     setSelectedCountry(countryId);
  //     //   setSelectedCountryName(countryName);
  //     setFilteredStates(states[countryId] || []);
  // };

  const handleCountryChange = (countryId) => {
    setSelectedCountry(countryId);
    setFilteredStates(states[countryId] || []);
  };
  const fetchArtist = async () => {
    try {
      const artistData = await makeApiCall('get', `/artistrepertoire/myprofile`);
      setGetArtistRepertoire(artistData);
    } catch (error) {
      // setError(error);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // useEffect(() => {
  //     fetchArtist();
  //         setFilteredStates(states[country] || []);
  // }, [selectedCountry]);

  useEffect(() => {
    fetchArtist();
    if (getArtistRepertoire) {
      setSelectedCountry(country);
      setFilteredStates(states[country] || []);
    }
  }, [country]);
  
// ============================== || EMBEDED YOUTUBE URL FUNCTION START || ================================ 
const [embedUrl, setEmbedUrl] = useState('');
const [errorMessage, setErrorMessage] = useState('');

useEffect(() => {
  if (youtubeUrl) {
    setEmbedUrl(youtubeUrl); // Set the embedUrl when youtubeUrl is available
  }
}, [youtubeUrl]); // Re-run effect when youtubeUrl changes
const handleUrlChange = (event, setFieldValue) => {
  const url = event.target.value;
  setFieldValue('youtubeUrl', url);
  setEmbedUrl('');

  // Regular expression to match YouTube video URLs
  const youtubeRegex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = youtubeRegex.exec(url);

  if (match) {
    const videoId = match[1];
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    setEmbedUrl(embedUrl);
    setErrorMessage('');
  } else {
    setEmbedUrl('');
    setErrorMessage('Please enter a valid YouTube URL');
  }
};
// ============================== || EMBEDED YOUTUBE URL FUNCTION END || ================================ 
  const validationSchema =
    activeStep === 0
      ? validationSchemaStep0
      : activeStep === 1
        ? validationSchemaStep1
        : activeStep === 2
          ? validationSchemaStep2
          : activeStep === 3
            ? validationSchemaStep3
            : activeStep === 4
              ? validationSchemaStep4
              : activeStep === 5
                ? validationSchemaStep5
        //         : activeStep === 6
        //           ? validationSchemaStep6
                  : null;

                  const handleRegisterSuccess = (userId) => {
                    setUserIdFromAdmin(userId);
                    setOpenProfileCreate(true); // Set state to true once registration is successful
                  };
  return (
    <>
      <Formik
        initialValues={{
          fullName: fullName ? fullName : '',
          email: email ? email : '',
          country: country ? country : '',
          state: state ? state : '',
          city: city ? city : '',
          phone: phone ? phone : '',
          genres: genres ? genres.split(',') : '',
          otherGenre: otherGenre ? otherGenre : '',
          youtubeProfile: youtubeProfile ? youtubeProfile : '',
          instagramProfile: instagramProfile ? instagramProfile : '',
          fbProfile: fbProfile ? fbProfile : '',
          xProfile: xProfile ? xProfile : '',
          linkedinProfile: linkedinProfile ? linkedinProfile : '',
          spotifyProfile: spotifyProfile ? spotifyProfile : '',
          soundcloudProfile: soundcloudProfile ? soundcloudProfile : '',
          company: company ? company : '',
          biography: biography ? biography : '',
          performanceType: performanceType ? performanceType.split(',') : '', // Assuming performanceType don't come from artistId
          youtubeUrl: youtubeUrl ? youtubeUrl : ''
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            const genreString = values?.genres?.join(','); // "GU,PI"
            const performanceTypeString = values?.performanceType?.join(','); // "GU,PI"

            const requestData = {
              ...values,
              genres: genreString,
              performanceType: performanceTypeString,
              ...(userType==='AD' && {userId:userIdFromAdmin})
            };

            const ampEndPoint = fullName ? '/artistrepertoire/artistrepertoireupdate' : '/artistrepertoire/create';
            const ampMethod = fullName ? 'put' : 'post';

            await makeApiCall(ampMethod, ampEndPoint, requestData, 'Artist repertoire profile create successful', 'Artist repertoire profile create failed');
            setStatus({ success: true });
            if (userType != 'AD'){
            setSubmitting(false);
            window.location.href = `/dashboard/atr-my-profile`;}
            else {
              navigate('/dashboard/artist-repertoire')
            }
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, validateForm, handleSubmit, setTouched, setFieldValue, }) => {
          const handleNext = async () => {
            const errors = await validateForm(); // Validate the current form step
            if (Object.keys(errors).length === 0) {
              setActiveStep((prevStep) => prevStep + 1); // Move to the next step
            } else {
              setTouched(errors); // Mark fields as touched if there are errors
            }
          };

          return (
<>
{userType === 'AD' && !openProfileCreate ?
          <AdminAuthRegister adminUserType={'ATRAdmin'} onSuccess={handleRegisterSuccess} />:
            <Box sx={{ width: '100%' }}>
              <Stepper className={classes.stepper} activeStep={activeStep}>
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Box sx={{ mt: 2, mb: 1 }}>
                {activeStep === steps.length ? (
                  <Button>Reset</Button>
                ) : (
                  <>
                    <Box sx={{ mt: 2, mb: 1 }}>
                      {activeStep === 0 && (
                        <Box className="outerBorderBox">
                          <Box className="innerBorderBox">
                            <Grid container justifyContent="center">
                              <Grid item xs={10} sm={8} md={6}>
                                <Box component="form" noValidate autoComplete="off">
                                  <TextField
                                    className={classes.textfield}
                                    fullWidth
                                    name="fullName"
                                    label="Name*"
                                    value={values.fullName}
                                    onChange={(e) => {
                                      const trimmedValue = e.target.value.trimStart();
                                      // Remove special characters, numbers, and HTML tags
                                      let sanitizedValue = trimmedValue
                                        .replace(/<[^>]*>/g, '') // Remove HTML tags
                                        .replace(/[^a-zA-Z\s]/g, '');
                                      // if (sanitizedValue.length > 30) {
                                      //     sanitizedValue = sanitizedValue.substring(0, 30);
                                      // }
                                      handleChange({ target: { name: 'fullName', value: sanitizedValue } });
                                    }}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    sx={{ maxWidth: 600, '& .MuiFormLabel-root': {
                                      height: '20px'
                                    } }}
                                  />
                                  {touched.fullName && errors.fullName && <Typography color="error">{errors.fullName}</Typography>}
                                  <TextField
                                    className={classes.textfield}
                                    fullWidth
                                    name="email"
                                    label="Email*"
                                    value={values.email}
                                    onChange={(e) => {
                                      const trimmedValue = e.target.value.trimStart();
                                      // Remove special characters, numbers, and HTML tags
                                      const sanitizedValue = trimmedValue
                                        .replace(/<[^>]*>/g, '') // Remove HTML tags
                                        .replace(/[^a-zA-Z\s@.]/g, '');
                                      handleChange({ target: { name: 'email', value: sanitizedValue } });
                                    }}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    sx={{ maxWidth: 600, '& .MuiFormLabel-root': {
                                      height: '20px'
                                    } }}
                                  />
                                  {touched.email && errors.email && <Typography color="error">{errors.email}</Typography>}
                                  <TextField
                                    margin="dense"
                                    id="country"
                                    label="Country*"
                                    select
                                    fullWidth
                                    name="country"
                                    onBlur={handleBlur}
                                    value={selectedCountry}
                                    onChange={(event) => {
                                      handleCountryChange(event.target.value);
                                      handleChange(event);
                                    }}
                                    sx={{
                                      maxWidth: 600,
                                      '& .MuiFormLabel-root': {
                                        height: '20px'
                                      }
                                    }}
                                  >
                                    {Country.map((option) => (
                                      <MenuItem
                                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                                        key={option.id}
                                        value={option.id}
                                        disabled={option.disabled}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  {touched.country && errors.country && <Typography color="error">{errors.country}</Typography>}

                                  <TextField
                                    margin="dense"
                                    id="state"
                                    label="State or Provinces"
                                    select
                                    fullWidth
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.state}
                                    name="state"
                                    sx={{ maxWidth: 600, '& .MuiFormLabel-root': {
                                      height: '20px'
                                    } }}
                                    disabled={!selectedCountry || selectedCountry === '0'}
                                  >
                                    {filteredStates.map((option) => (
                                      <MenuItem
                                        sx={{ display: 'flex', flexWrap: 'wrap' }}
                                        key={option.id}
                                        value={option.id}
                                        disabled={option.disabled}
                                      >
                                        {option.name}
                                      </MenuItem>
                                    ))}
                                  </TextField>
                                  {touched.state && errors.state && <Typography color="error">{errors.state}</Typography>}
                                  <TextField
                                    className={classes.textfield}
                                    fullWidth
                                    name="city"
                                    label="City*"
                                    value={values.city}
                                    sx={{
                                      maxWidth: 600,
                                      '& .MuiFormLabel-root': {
                                        height: '20px'
                                      }
                                    }}
                                    onChange={(e) => {
                                      const trimmedValue = e.target.value.trimStart();
                                      // Remove special characters, numbers, and HTML tags
                                      let sanitizedValue = trimmedValue
                                        .replace(/<[^>]*>/g, '') // Remove HTML tags
                                        .replace(/[^a-zA-Z\s]/g, '');
                                      if (sanitizedValue.length > 50) {
                                        sanitizedValue = sanitizedValue.substring(0, 50);
                                      }
                                      handleChange({ target: { name: 'city', value: sanitizedValue } });
                                    }}
                                    onBlur={handleBlur}
                                    margin="normal"
                                  />
                                  {touched.city && errors.city && <Typography color="error">{errors.city}</Typography>}
                                  <TextField
                                    className={classes.textfield}
                                    fullWidth
                                    name="phone"
                                    label="Contact Number*"
                                    value={values.phone}
                                    // onChange={handleChange}
                                    onChange={(e) => {
                                      const value = e.target.value.replace(/\D/g, ''); // Use regex to allow only numbers
                                      handleChange({ target: { name: 'phone', value: value.slice(0, 10) } }); // Limit input to 10 digits
                                    }}
                                    onBlur={handleBlur}
                                    margin="normal"
                                    sx={{ maxWidth: 600, '& .MuiFormLabel-root': {
                                      height: '20px'
                                    } }}
                                    inputProps={{
                                      maxLength: 10 // Limit input length to 10 characters
                                    }}
                                  />
                                  {touched.phone && errors.phone && <Typography color="error">{errors.phone}</Typography>}
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Box>
                      )}
                       {activeStep === 1 && (
                        <Grid container spacing={2} textAlign="center" rowSpacing={5}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="outerBorderBox">
                              <Box className="innerBorderBox">
                                <FormControl component="fieldset" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                  <Grid container spacing={2} flexDirection="column" alignItems="center" justifyContent="center">
                                    <Grid item xs={6} sm={4} md={3} style={{ paddingTop: 20 }}>
                                      <Box>
                                        <FieldArray name="genres">
                                          {({ push, remove }) => (
                                            <div>
                                              {genreList.map((option) => (
                                                <div
                                                  key={option.value}
                                                  style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    marginBottom: '8px',
                                                    cursor: 'pointer'
                                                  }}
                                                >
                                                  <Field
                                                    type="checkbox"
                                                    name="genres"
                                                    value={option.value}
                                                    id={`instrument-${option.value}`} // Unique id for each checkbox
                                                    onBlur={handleBlur}
                                                    checked={values.genres && values.genres.includes(option.value)}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        push(option.value);
                                                        console.log('option.value', option.value);
                                                      } else {
                                                        const index = values.genres.indexOf(option.value);
                                                        remove(index);
                                                      }
                                                    }}
                                                    style={{ width: '17px', height: '17px' }}
                                                  />
                                                  <label
                                                    style={{ fontSize: '1rem', marginLeft: '8px', cursor: 'pointer' }}
                                                    htmlFor={`instrument-${option.value}`} // Link label to checkbox
                                                  >
                                                    {option.label}
                                                  </label>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                        </FieldArray>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  {values.genres.includes('OT') && (
                                    <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                      <TextField
                                        name="otherGenre"
                                        value={values.otherGenre}
                                        onChange={(e) => {
                                          const trimmedValue = e.target.value.trimStart();
                                          let sanitizedValue = trimmedValue
                                            .replace(/<[^>]*>/g, '') // Remove HTML tags
                                            .replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetic characters

                                          // Update the field value with the sanitized input
                                          handleChange({ target: { name: 'otherGenre', value: sanitizedValue } });
                                        }}
                                        onBlur={handleBlur} // Trigger validation on blur
                                        fullWidth
                                        margin="normal"
                                        label="Enter the instrument"
                                        sx={{ textAlign: 'center', width: '300px' }}
                                      />
                                    </Grid>
                                  )}
                                  {touched.otherGenre && errors.otherGenre && (
                                    <Typography color="error" sx={{ textAlign: 'center', mt: 1 }}>
                                      {errors.otherGenre}
                                    </Typography>
                                  )}

                                  {/* Displaying error message for genres */}
                                  {touched.genres && errors.genres && <Typography color="error">{errors.genres}</Typography>}
                                </FormControl>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {activeStep === 2 && (
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <Box className="outerBorderBox">
                              <Box className="innerBorderBox">
                                <Box component="form" sx={{ p: 2 }}>
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 140,
                                            marginRight: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            justifyContent: 'flex-start',
                                            '&:hover': {
                                              backgroundColor: 'white', // Keep the background color white
                                              color: 'black', // Keep the text color black
                                              pointerEvents: 'none' // Disable hover interaction
                                            }
                                          }}
                                          startIcon={<img src={YouTubeIcon} style={{ width: '28px', height: '18px' }} alt="YouTube" />}
                                        >
                                          Youtube
                                        </Button>
                                        <TextField
                                          name="youtubeProfile"
                                          onChange={handleChange}
                                          value={values.youtubeProfile}
                                          onBlur={handleBlur}
                                          fullWidth
                                          placeholder="https://youtube.com/@username"
                                          variant="outlined"
                                        />
                                      </Box>
                                      {touched.youtubeProfile && errors.youtubeProfile && (
                                        <Typography color="error">{errors.youtubeProfile}</Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 140,
                                            marginRight: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            justifyContent: 'flex-start',
                                            '&:hover': {
                                              backgroundColor: 'white', // Keep the background color white
                                              color: 'black', // Keep the text color black
                                              pointerEvents: 'none' // Disable hover interaction
                                            }
                                          }}
                                          startIcon={<img src={InstagramIcon} style={{ width: '22px', height: '22px' }} alt="Instagram" />}
                                        >
                                          Instagram
                                        </Button>
                                        <TextField
                                          name="instagramProfile"
                                          onChange={handleChange}
                                          value={values.instagramProfile}
                                          onBlur={handleBlur}
                                          fullWidth
                                          placeholder="https://instagram.com/@username"
                                          variant="outlined"
                                        />
                                      </Box>
                                      {touched.instagramProfile && errors.instagramProfile && (
                                        <Typography color="error">{errors.instagramProfile}</Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 140,
                                            marginRight: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            justifyContent: 'flex-start',
                                            '&:hover': {
                                              backgroundColor: 'white', // Keep the background color white
                                              color: 'black', // Keep the text color black
                                              pointerEvents: 'none' // Disable hover interaction
                                            }
                                          }}
                                          startIcon={<img src={FacebookIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                        >
                                          Facebook
                                        </Button>
                                        <TextField
                                          name="fbProfile"
                                          onChange={handleChange}
                                          value={values.fbProfile}
                                          onBlur={handleBlur}
                                          fullWidth
                                          placeholder="https://facebook.com/@username"
                                          variant="outlined"
                                        />
                                      </Box>
                                      {touched.fbProfile && errors.fbProfile && <Typography color="error">{errors.fbProfile}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 140,
                                            marginRight: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            justifyContent: 'flex-start',
                                            '&:hover': {
                                              backgroundColor: 'white', // Keep the background color white
                                              color: 'black', // Keep the text color black
                                              pointerEvents: 'none' // Disable hover interaction
                                            }
                                          }}
                                          startIcon={<img src={TwitterIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                        >
                                          X
                                        </Button>
                                        <TextField
                                          name="xProfile"
                                          onChange={handleChange}
                                          value={values.xProfile}
                                          onBlur={handleBlur}
                                          fullWidth
                                          placeholder="https://x.com/@username"
                                          variant="outlined"
                                        />
                                      </Box>
                                      {touched.xProfile && errors.xProfile && <Typography color="error">{errors.xProfile}</Typography>}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 140,
                                            marginRight: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            justifyContent: 'flex-start',
                                            '&:hover': {
                                              backgroundColor: 'white', // Keep the background color white
                                              color: 'black', // Keep the text color black
                                              pointerEvents: 'none' // Disable hover interaction
                                            }
                                          }}
                                          startIcon={<img src={LinkedInIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                        >
                                          Linkedin
                                        </Button>
                                        <TextField
                                          name="linkedinProfile"
                                          onChange={handleChange}
                                          value={values.linkedinProfile}
                                          onBlur={handleBlur}
                                          fullWidth
                                          placeholder="https://linkedin.com/@username"
                                          variant="outlined"
                                        />
                                      </Box>
                                      {touched.linkedinProfile && errors.linkedinProfile && (
                                        <Typography color="error">{errors.linkedinProfile}</Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 140,
                                            marginRight: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            justifyContent: 'flex-start',
                                            '&:hover': {
                                              backgroundColor: 'white', // Keep the background color white
                                              color: 'black', // Keep the text color black
                                              pointerEvents: 'none' // Disable hover interaction
                                            }
                                          }}
                                          startIcon={<img src={SpotifyIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                        >
                                          Spotify
                                        </Button>
                                        <TextField
                                          name="spotifyProfile"
                                          onChange={handleChange}
                                          value={values.spotifyProfile}
                                          onBlur={handleBlur}
                                          fullWidth
                                          placeholder="https://spotify.com/@username"
                                          variant="outlined"
                                        />
                                      </Box>
                                      {touched.spotifyProfile && errors.spotifyProfile && (
                                        <Typography color="error">{errors.spotifyProfile}</Typography>
                                      )}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                        <Button
                                          variant="contained"
                                          sx={{
                                            minWidth: 140,
                                            marginRight: '10px',
                                            backgroundColor: 'white',
                                            color: 'black',
                                            justifyContent: 'flex-start',
                                            '&:hover': {
                                              backgroundColor: 'white', // Keep the background color white
                                              color: 'black', // Keep the text color black
                                              pointerEvents: 'none' // Disable hover interaction
                                            }
                                          }}
                                          startIcon={<img src={SoundCloudIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                        >
                                          Soundcloud
                                        </Button>
                                        <TextField
                                          name="soundcloudProfile"
                                          onChange={handleChange}
                                          value={values.soundcloudProfile}
                                          onBlur={handleBlur}
                                          fullWidth
                                          placeholder="https://soundcloud.com/@username"
                                          variant="outlined"
                                        />
                                      </Box>
                                      {touched.soundcloudProfile && errors.soundcloudProfile && (
                                        <Typography color="error">{errors.soundcloudProfile}</Typography>
                                      )}
                                    </Grid>
                                  </Grid>
                                </Box>
                                {/* Display the general error message for all social profiles */}
                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                  {/* Check if the custom error message exists */}
                                  {errors.socialProfiles && (
                                    <Box display="flex" justifyContent="center" alignItems="center">
                                      <Typography color="error">{errors.socialProfiles}</Typography>
                                    </Box>
                                  )}
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {activeStep === 3 && (
                        <Grid container justifyContent="center" spacing={2}>
                        <Grid item xs={12} sm={12} md={12}>
                          <Box component="form" noValidate autoComplete="off">
                            <Grid>
                                 <Box className="outerBorderBox" 
                                  sx={{ mb: 2 }} // Add margin-bottom to create a gap
                                >
                                 <Box className="innerBorderBox">
                                  <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                      width: '100%',
                                      display: 'flex'
                                    }}
                                  >
                                    <Box
                                      component="form"
                                      noValidate
                                      autoComplete="off"
                                      sx={{
                                        width: {
                                          xs: '90%', // Width for extra-small screens
                                          sm: '50%' // Width for small and larger screens
                                        }
                                      }}
                                    >
                                      <TextField
                                        className={classes.textfield}
                                        fullWidth
                                        name="company"
                                        label="Company"
                                        value={values.company}
                                        onChange={(e) => {
                                          // Trim the leading spaces
                                          const trimmedValue = e.target.value.trimStart();
                                          // Remove HTML tags, special characters, and numbers
                                          let sanitizedValue = trimmedValue
                                            .replace(/<[^>]*>/g, '') // Remove HTML tags
                                            .replace(/[^a-zA-Z\s]/g, '');
                                          // Limit the input to a maximum of 30 characters
                                          // if (sanitizedValue.length > 30) {
                                          //   sanitizedValue = sanitizedValue.substring(0, 100);
                                          // }
                                          handleChange({ target: { name: 'company', value: sanitizedValue } });
                                        }}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        sx={{ maxWidth: 600,'& .MuiFormLabel-root': {
                      height: '25px'
                    } }}

                                      />
                                      {touched.company && errors.company && <Typography color="error">{errors.company}</Typography>}
                                    </Box>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid>
                            <Box className="outerBorderBox">
                            <Box className="innerBorderBox">
                                  <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                      width: '100%',
                                      display: 'flex'
                                    }}
                                  >
                                    <Box
                                      component="form"
                                      noValidate
                                      autoComplete="off"
                                      sx={{
                                        width: {
                                          xs: '90%', // Width for extra-small screens
                                          sm: '50%' // Width for small and larger screens
                                        }
                                      }}
                                    >
                                        <TextField
                                      className={classes.textfield}
                                      id="outlined-multiline-static"
                                      multiline
                                      maxRows={8}
                                      fullWidth
                                      name="biography"
                                      label="Biography*"
                                      value={values.biography}
                                      onChange={(e) => {
                                        const trimmedValue = e.target.value.trimStart();
                                        const sanitizedValue = trimmedValue
                                          .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
                                          .replace(/<\s*\/?\s*>/g, ''); // Remove empty tags
                                        handleChange({ target: { name: 'biography', value: sanitizedValue } });
                                      }}
                                      onBlur={handleBlur}
                                      margin="normal"
                                      sx={{ maxWidth: 600, height: 200,'& .MuiFormLabel-root': {
                      height: '25px'
                    } }}
                                      inputProps={{
                                        maxLength: 1000 // Setting maximum character limit
                                      }}
                                    />

                                    {touched.biography && errors.biography && <Typography color="error">{errors.biography}</Typography>}
                                    </Box>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                      )}
                     {activeStep === 4 && (
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs={12} sm={12} md={12}>
                          <Box className="outerBorderBox">
                          <Box className="innerBorderBox">
                                <FormControl component="fieldset" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                  <Grid container spacing={2} flexDirection="column" alignItems="center" justifyContent="center">
                                    <Grid item xs={6} sm={4} md={3} style={{ paddingTop: 20 }}>
                                      <Box>
                                        <FieldArray name="performanceType">
                                          {({ push, remove }) => (
                                            <div>
                                              {PerformanceTypeList.map((option) => (
                                                <div key={option.value}>
                                                  <Field
                                                    type="checkbox"
                                                    name="performanceType"
                                                    id={`performanceType-${option.value}`} // Unique id for each checkbox
                                                    value={option.value}
                                                    onBlur={handleBlur}
                                                    checked={values.performanceType && values.performanceType.includes(option.value)}
                                                    onChange={(e) => {
                                                      if (e.target.checked) {
                                                        push(option.value);
                                                      } else {
                                                        const index = values.performanceType.indexOf(option.value);
                                                        remove(index);
                                                      }
                                                    }}
                                                    style={{ width: '16px', height: '16px' }}
                                                  />
                                                  <label
                                                    style={{ fontSize: '1rem', marginLeft: '8px' }}
                                                    htmlFor={`performanceType-${option.value}`}
                                                  >
                                                    {option.label}
                                                  </label>
                                                </div>
                                              ))}
                                            </div>
                                          )}
                                        </FieldArray>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                  {touched.performanceType && errors.performanceType && (
                                    <Typography color="error">{errors.performanceType}</Typography>
                                  )}
                                </FormControl>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                      {activeStep === 5 && (
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                          <Box className="outerBorderBox">
                          <Box className="innerBorderBox">
                                <Grid
                                  container
                                  justifyContent="center"
                                  alignItems="center"
                                  sx={{
                                    width: '100%',
                                    display: 'flex'
                                  }}
                                >
                                  <Box
                                    // sx={{ mt: 1 }}
                                    component="form"
                                    noValidate
                                    autoComplete="off"
                                    sx={{
                                      width: {
                                        xs: '90%', // Width for extra-small screens
                                        sm: '90%' // Width for small and larger screens
                                      },
                                      padding: '10px'
                                    }}
                                  >
                                    <TextField
                                      id="youtube-url"
                                      name="youtubeUrl"
                                      label="YouTube Video  Embed URL*"
                                      variant="outlined"
                                      fullWidth
                                      value={values.youtubeUrl}
                                      onChange={(event) => handleUrlChange(event, setFieldValue)}
                                      error={!!errorMessage}
                                      helperText={errorMessage}
                                      onBlur={handleBlur}
                                    />
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      {/* Check if the custom error message exists */}
                                      <Box display="flex" justifyContent="center" alignItems="center">
                                        {touched.youtubeUrl && errors.youtubeUrl && (
                                          <Typography color="error">{errors.youtubeUrl}</Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                    {/* {touched.youtubeUrl && errors.youtubeUrl && <Typography color="error">{errors.youtubeUrl}</Typography>} */}
                                    {embedUrl && (
                                      <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                                        <iframe
                                          width="800"
                                          height="350"
                                          src={embedUrl}
                                          frameBorder="0"
                                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                          allowFullScreen
                                          title="Embedded YouTube Video"
                                        ></iframe>
                                      </Box>
                                    )}
                                  </Box>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                        Back
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button
                        className={classes.button}
                        onClick={async () => {
                          if (activeStep === steps.length - 1) {
                            handleSubmit(); // Submit the form on the last step
                          } else {
                            await handleNext(); // Validate and go to next step
                          }
                        }}
                        type="button"
                        disabled={isSubmitting} // Disable if form is invalid or untouched
                      >
                        {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
        }
</>
          );
        }}
      </Formik>
    </>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    color: '#fff'
  },
  date: {
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'none',
    marginBottom: 20
  },
  textfield: {
    backgroundColor: '#fff',
    borderRadius: 5
  },
  stepper: {
    flexWrap: 'wrap',
    gap: 0.5,
    '& .MuiStepLabel-root': {
      color: 'gray', // Default text color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed step color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active step color
      }
    },
    '& .MuiStepLabel-label': {
      color: '#fff', // Default text color
      '&.Mui-completed': {
        color: 'grey' // Completed step color
      },
      '&.Mui-active': {
        color: '#fff' // Active step color
      }
    },
    '& .MuiStepIcon-root': {
      color: 'gray', // Default icon color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed icon color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active icon color
      }
    }
  }
}));