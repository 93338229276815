import * as React from 'react';
import { Grid, CardMedia, Typography, Chip, Stack, Box, Link, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import makeApiCall from 'common/makeApiCall';
import profile from '../../assets/images/users/profile.png';
import { useNavigate } from 'react-router-dom';

export default function AmpMyProfile() {
  const [amp, setAmp] = useState(null);
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  // ===================|EVENT DETAILS API CALL WITH SINGLE ID|========================//
  useEffect(() => {
    const fetchArtist = async () => {
      try {
        const ampData = await makeApiCall('get', `/amps/myprofile`);
        setAmp(ampData);
        console.log('res', ampData);
      } catch (error) {
        // setError(error);
        navigate('/dashboard/amps')
        setError('')
      }
    };
    fetchArtist();
  }, []);

  if (error) {
    return <Typography color="error">Failed to load amp details. Please try again later.</Typography>;
  }
  if (!amp) {
    return <Typography>Loading...</Typography>;
  }

  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 'P':
        return 'PAID';
      case 'UP':
        return 'UNPAID';
      default:
        return 'UNPAID';
    }
  };

  const getRatesLabel = (ratevalue) => {
    switch (ratevalue) {
      case 'PD':
        return 'Per Day';
      case 'PH':
        return 'Per Hour';
      case 'PJ':
        return 'Per Project';
      default:
        return 'Per Hour';
    }
  };



  // Function to map boolean work availability to display text
  const getActiveStatusLabel = (status) => {
    if (status === true) {
      return 'AVAILABLE';
    } else if (status === false) {
      return 'UNAVAILABLE';
    } else {
      return 'Unknown';
    }
  };

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleEditClick = () => {
    console.log('Edit Artist');

    // Convert the amp object to a string before storing it
    localStorage.setItem('ampUpdateData', JSON.stringify(amp));

    navigate('/dashboard/amps');
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Artist Image and Basic Info */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: { xs: 'center', md: 'left' } }}
      >
        {/* Profile Image and Basic Info */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { md: '180%', xs: '100%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 151, height: 151, objectFit: 'cover', borderRadius: 3, mb: 2 }}
            image={profile}
            alt="profile"
          />
          <Box sx={{ ml: { xs: 2, md: 2 }, textAlign: { xs: 'left', md: 'left' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography variant="h3">{capitalizeFirstLetter(amp.fullName)}</Typography>
              <IconButton
                sx={{
                  ml: 1, // Add some margin to the left of the name
                  bottom: 5, // Adjust positioning as needed
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '50%',
                  mt: 1
                }}
                onClick={handleEditClick}
                backgroundColor="red"
              >
                <EditIcon />
              </IconButton>
            </Box>
            <Typography variant="subtitle1">
              {amp.city}, {amp.stateLabel}, {amp.countryLabel}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {amp.email}
            </Typography>
            <Link href={amp.website} target="_blank" sx={{ color: '#fff' }}>
              {amp.website}
            </Link>
            <Typography variant="body1" gutterBottom>
              {amp.openForWork}
            </Typography>
            <Stack direction="row" spacing={1}>
              
              <Chip
                sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                label={getActiveStatusLabel(amp.openForWork)}
                color="primary"
                variant="outlined"
              />


              <Chip
                sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                label={getPaymentStatusLabel(amp.paymentStatus)}
                color="primary"
                variant="outlined"
              />
{amp.paymentStatus === 'P' && (
                <>
                  <Chip
                    sx={{
                      borderRadius: 4,
                      borderColor: '#fff',
                      color: '#fff',
                      minWidth: '80px'
                    }}
                    label={amp.currencyType}
                    color="primary"
                    variant="outlined"
                  />

                  <Chip
                    sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                    label={amp.amount}
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                    label={getRatesLabel(amp.rates)}
                    color="primary"
                    variant="outlined"
                  />
                </>
              )}

            </Stack>
          </Box>
        </Box>

        {/* YouTube Video */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Services
          </Typography>

          <Stack direction="row" sx={{ mb: 2, cursor: 'pointer', flexWrap: 'wrap', gap: 1 }}>
            {amp.serviceLabels.map((value, index) => (
              <Chip
                sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                key={index}
                color="primary"
                label={value}
                variant="outlined"
              />
            ))}
          </Stack>
          </Box>
          <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >

<Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Service Description:
          </Typography>
          <Grid item xs={12} sx={{ textAlign: { xs: 'start', md: 'start' } }}>
          <Box sx={{  textAlign: 'justify' }}>
          <Typography variant="body1" sx={{textIndent: '190px'}}>{amp.serviceDescription}</Typography>
          </Box>
          </Grid>
          </Box>

          <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
<Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Timing
          </Typography>
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, flex: 'start' }}>
            <Typography variant="h6" size="small">
              Timing {amp.StartTime} to {amp.EndTime}
            </Typography>
          </Stack>
        </Box>
      </Grid>
    </Grid>
  );
}
