import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import makeApiCall from 'common/makeApiCall';
import { Link } from 'react-router-dom';
import { TextField, LinearProgress, IconButton, Stack, Divider } from '@mui/material';
import { states } from 'data/State';
import { Country } from 'data/Country';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import DateRangeIcon from '@mui/icons-material/DateRange';
import Pagination from '@mui/material/Pagination';
import { styled } from '@mui/material/styles';
import PaginationItem from '@mui/material/PaginationItem';
import noData from '../../../assets/icons/nodata.png';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

const options = [
  { value: 'OM', label: 'Open Mic' },
  { value: 'LG', label: 'Live Gig' },
  { value: 'MF', label: 'Festivals' }
];

// Create a styled Button component
const StyledButton = styled(Button)(({ theme, isActive }) => ({
  backgroundColor: !isActive ? 'rgba(255, 255, 255, 0.30)' : 'transparent',
  backgroundImage: isActive
    ? 'linear-gradient(to right, #ff0093, #7900ff)' // Apply gradient if active
    : 'none', // No gradient if inactive

  ...theme.typography.h5,
  padding: theme.spacing(2),
  textAlign: 'center',
  '&:hover': {
    cursor: isActive ? 'default' : 'pointer',
    color: 'white'
  },
  color: 'black'
}));


export default function Eventlist() {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [events, setEvents] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);
  const [eventStartDate, setEventStartDate] = useState(null);
  const [eventEndDate, setEventEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);
  const [activeButton, setActiveButton] = useState('F');

  // ==============|EVENT LIST API CALL|====================//
  const handleSubmit = async (data) => {
    if (data) {
          setPage(1);
    }
    const formattedStartDate = eventStartDate ? dayjs(eventStartDate).format('YYYY-MM-DD') : undefined;
    const formattedEndDate = eventEndDate ? dayjs(eventEndDate).format('YYYY-MM-DD') : undefined;
    setLoading(true);
    try {
      const response = await makeApiCall('post', `/event/list?page=${page}`, {
        eventType: value,
        country: selectedCountry,
        state: selectedState,
        eventStartDate: formattedStartDate,
        eventEndDate: formattedEndDate,
        eventTimeline: activeButton, // Use activeButton as eventTimeline
      });

      setEvents(response.data); // Setting the array of events
      setTotalPages(response.pagination?.totalPages || 1);
    } catch (error) {
      setError(error);
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleSubmit();
  }, [page, activeButton]);

  useEffect(() => {
    if (selectedCountry) {
      setFilteredStates(states[selectedCountry] || []);
    } else {
      setFilteredStates([]);
    }
  }, [selectedCountry]);
  
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  //===================|COUNTRY & STATE SELECT FUNCTION|=========================//
  // const [selectedCountry, setSelectedCountry] = useState('');
  // const [filteredStates, setFilteredStates] = useState([]);
  // const handleCountryChange = (event) => {
  //     const countryId = event.target.value;
  //     setSelectedCountry(countryId);
  //     setFilteredStates(states[countryId] || []);
  // };
  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
          <Box fullWidth sx={{ '& button': { m: 1 }, marginBottom: 2 }}>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <StyledButton
            onClick={() => {
              setActiveButton('P');
              setValue('');
              handleSubmit('initialPageNumberOnSearch');
            }}
            isActive={activeButton === 'P'}
          >
            Past Events
          </StyledButton>
          <StyledButton
            onClick={() => {
              setActiveButton('F');
              setValue('');
              handleSubmit('initialPageNumberOnSearch');
            }}
            isActive={activeButton === 'F'}
          >
            Future Events
          </StyledButton>
        </Stack>
      </Box>



      <Box sx={{ background: 'linear-gradient(to right, #ff0093, #7900ff)', padding: 1, borderRadius: 1 }}>
        <form>
          <Grid container sx={{ alignItems: 'center' }} spacing={2}>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="eventType"
                  label="Event Type"
                  select
                  fullWidth
                  name="eventType"
                  value={value}
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  onChange={handleChange}
                  InputProps={
                    value?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px'
                          }}
                        >
                          <IconButton
                            onClick={() => setValue('')} 
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="country"
                  label="Country"
                  select
                  fullWidth
                  name="country"
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  InputProps={
                    selectedCountry?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px'
                          }}
                        >
                          <IconButton
                            onClick={() => 
                              {setSelectedCountry('');
                              setSelectedState('');
                              }}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                >
                  {Country.map((option) => (
                    <MenuItem sx={{ display: 'flex', flexWrap: 'wrap' }} key={option.id} value={option.id} disabled={option.disabled}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="state"
                  label="State"
                  select
                  fullWidth
                  name="state"
                  onChange={handleStateChange}
                  value={selectedState}
                  disabled={!selectedCountry || selectedCountry === '0'}
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  InputProps={
                    selectedState?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px' 
                          }}
                        >
                          <IconButton
                            onClick={() => setSelectedState('')} 
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                  // value={filteredStates.find((state) => state.id === selectedCountry)?.id || ''} // Handle cases where no state matches selectedCountry
                >
                  {filteredStates.map((option) => (
                    <MenuItem sx={{ display: 'flex', flexWrap: 'wrap' }} key={option.id} value={option.id} disabled={option.disabled}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="From date"
                    inputVariant="outlined"
                    fullWidth
                    format="YYYY-MM-DD"
                    value={eventStartDate}
                    onChange={(newValue) => {
                      setEventStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{
                      field: { clearable: true, onClear: () => setEventStartDate('') },
                    }}
                    sx={{
                      '& .MuiFormLabel-root': {
                        height: '20px'
                      },
                      backgroundColor: 'white',
                      borderRadius: 2
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label="To date"
                    inputVariant="outlined"
                    format="YYYY-MM-DD"
                    fullWidth
                    value={eventEndDate}
                    onChange={(newValue) => {
                      setEventEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    slotProps={{
                      field: { clearable: true, onClear: () => setEventEndDate('') },
                    }}
                    sx={{
                      '& .MuiFormLabel-root': {
                        height: '20px'
                      },
                      backgroundColor: 'white',
                      borderRadius: 2
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => handleSubmit('initialPageNumberOnSearch')} variant="contained" size="large">
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {events.length === 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <img src={noData} alt="" style={{ width: '60px', height: '60px' }} />
          <Typography variant="h6">No Data Available</Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            {events.map((event) => (
              <Grid item xs={12} sm={6} md={4} key={event.id} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link target="_blank" to={`/dashboard/eventdetails/${event.id}`} className={classes.link}>
                  <Card sx={{ maxWidth: 345, borderRadius: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <CardMedia
                      component="img"
                      image="https://img.freepik.com/free-photo/group-workers-organizing-protest_23-2151546471.jpg?t=st=1723638883~exp=1723642483~hmac=e4245ac7ea77da58d80f09ec7085905271e3ec0ef620011a7a2a8bcd7b379fd8&w=1060"
                      sx={{ height: 180, objectFit: 'cover' }}
                    />
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        backgroundColor: '#fff',
                        color: 'black',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        minHeight: 100
                      }}
                    >
                      <Typography variant="h5" sx={{ mb: 0.5 }}>
                        {capitalizeFirstLetter(event.title)}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          backgroundColor: '#1677ff',
                          color: '#fff',
                          borderRadius: 6,
                          p: 0.5,
                          mr: 1,
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        <DateRangeIcon sx={{ mr: 1, ml: 1 }} />
                        {event.eventStartDate} | Starts at {event.eventStartTime}
                        {<KeyboardArrowRightIcon />}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          backgroundColor: '#ff0093',
                          color: '#fff',
                          borderRadius: 6,
                          p: 0.5,
                          mr: 1,
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 'bold',
                          justifyContent: 'center'
                        }}
                      >
                        {event.eventType == 'OM'
                          ? 'Open Mic'
                          : event.eventType == 'LG'
                            ? 'Live Gig'
                            : event.eventType == 'MF'
                              ? 'Music Festival'
                              : ''}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
          {/* ====================|EVENT CARD LIST|====================== */}

          {/* Pagination Controls */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => <CustomPaginationItem {...item} />}
              color="primary"
            />
          </Box>
        </>
      )}
    </>
  );
}
const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: 8
  },
  link: {
    textDecoration: 'none', // Remove underline
    color: 'inherit', // Use inherit color
    '&:hover': {
      textDecoration: 'none' // Remove underline on hover as well
    }
  }
});

const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  '&.MuiPaginationItem-page': {
    color: '#fff' // Customize page numbers color
  },
  '&.MuiPaginationItem-previousNext': {
    color: 'red' // Customize next/previous buttons color
  }
}));
