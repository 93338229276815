import { lazy } from 'react';
import Loadable from 'components/Loadable';
import Homepage from 'pages/home/Homepage';
import MainLayout from 'layout/MainLayout/index';
import PrivateRoute from './PrivateRoute';
import GoogleCallback from 'pages/authentication/auth-forms/GoogleCallback';
import Artistprofile from 'pages/artist/Artistprofile';
import Listartist from 'pages/artist/Listartist';
import Artistdetails from 'pages/artist/Artistdetails';
import { Navigate } from 'react-router-dom';
import Amps from 'pages/amp/Amps';
import Amplist from 'pages/amp/Amplist';
import Ampview from 'pages/amp/Ampview';
import Adminpage from 'pages/admin/Adminpage';
import Artistrepertoire from 'pages/artistrepertoire/Artistrepertoire';
import Sessionmusicians from 'pages/artist/Sessionmusicians';
import Artistrepertoirelist from 'pages/artistrepertoire/Artistrepertoirelist';
import Eventcreate from 'pages/event/eventcreate/Eventcreate';
import Eventlist from 'pages/event/eventlist/Eventlist';
import Myevent from 'pages/event/myevent/Myevent';
import Eventdetails from 'pages/event/eventdetails/Eventdetails';
import ArtistMyProfile from 'pages/artist/ArtistMyProfile';
import AmpMyProfile from 'pages/amp/AmpMyProfile';
import ArtistrepertoireMyProfile from 'pages/artistrepertoire/ArtistrepertoireMyProfile';
import Artistrepertoireview from 'pages/artistrepertoire/Artistrepertoireview';

const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));
// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));

// ==============================|| MAIN ROUTING ||============================== //
const token = localStorage.getItem("token");
const userType = localStorage.getItem("userType");

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: <Homepage />
    },
    {
      path: '/auth/google/callback',
      element: <GoogleCallback />
    },
     // Make the event details route accessible without authentication
     {
      // path: 'dashboard/eventdetails/:id',
      element:  <MainLayout />,

      children: [
        {
          path: '/dashboard/eventdetails/:id',
          element: <Eventdetails />,
        },
        {
          path: '/dashboard/artistview/:id',
          element: <Artistdetails />,
        },
      ]
      // // element: <Eventdetails /> // Use MainLayout if needed
      // path: '/dashboard/eventdetails/:id', element: <Eventdetails />
    },

    // { path: 'eventdetails/:id', element: <Eventdetails /> },
    
    // ===================| Dashboard Routes |=================== //
    {
      path: 'dashboard',
      element: (
        token ? (
          <PrivateRoute>
            <MainLayout />
          </PrivateRoute>
        ) : (
          <Navigate to="/" replace />
        )
      ),
      children: (() => {
        switch (userType) {
          case 'ORG':
            return [
              { path: 'default', element: <DashboardDefault /> },
              { path: 'color', element: <Color /> },
              { path: 'eventcreate', element: <Eventcreate /> },
              { path: 'eventupdate/:id', element: <Eventcreate /> },
              { path: 'eventlist', element: <Eventlist /> },
              { path: 'myevent', element: <Myevent /> },
              { path: 'eventdetails/:id', element: <Eventdetails /> },
              { path: 'listartist', element: <Listartist /> },
              { path: 'artistview/:id', element: <Artistdetails /> },
              // Add other ORG routes if needed
            ];
          case 'AR':
            return [
              { path: 'artistprofile', element: <Artistprofile /> },
              { path: 'eventlist', element: <Eventlist /> },
              { path: 'amplist', element: <Amplist /> },
              { path: 'session-musicians', element: <Sessionmusicians /> },
              { path: 'artistview/:id', element: <Artistdetails /> },
              { path: 'artist-repertoire', element: <Artistrepertoirelist /> },
              { path: 'eventdetails/:id', element: <Eventdetails /> },
              { path: 'ampview/:id', element: <Ampview /> },
              { path: 'artist-my-profile', element: <ArtistMyProfile /> },
              { path: 'atrview/:id', element: <Artistrepertoireview /> },
              // Add other AR routes if needed
            ];
          case 'AMP':
            return [
              // Add AMP routes here
              { path: 'amps', element: <Amps /> },
              { path: 'amp-my-profile', element: <AmpMyProfile /> },
              // Add other AMP routes if needed
            ];
          case 'ATR':
            return [
              // Add AMP routes here
              { path: 'create-artists-repertoire', element: <Artistrepertoire /> },
              { path: 'atr-my-profile', element: <ArtistrepertoireMyProfile /> },
              // Add other AMP routes if needed
            ];
            case null: // handles the case when userType is null
    return [
      { path: 'eventdetails', element: <Eventdetails /> },
    ];
          case 'AD':
            return [
              { path: 'user', element: <Adminpage /> },
              { path: 'admin', element: <DashboardDefault /> },
              { path: 'eventlist', element: <Eventlist /> },
              { path: 'eventdetails/:id', element: <Eventdetails /> },
              { path: 'listartist', element: <Listartist /> },
              { path: 'session-musicians', element: <Sessionmusicians /> },
              { path: 'artistview/:id', element: <Artistdetails /> },
              { path: 'artist-repertoire', element: <Artistrepertoirelist /> },
              { path: 'amplist', element: <Amplist /> },
              { path: 'ampview/:id', element: <Ampview /> },
              { path: 'eventcreate', element: <Eventcreate /> },
              { path: 'atrview/:id', element: <Artistrepertoireview /> },
              { path: 'artistprofile', element: <Artistprofile /> },
              { path: 'amps', element: <Amps /> },
              { path: 'create-artists-repertoire', element: <Artistrepertoire /> },
              // Add other AD routes if needed
            ];
          default:
            return [];
        }
      })()
    }
  ]
};

export default MainRoutes;
