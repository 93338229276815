import React, { useState } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  OutlinedInput,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Grid,
  FormHelperText,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { Formik } from 'formik';
import makeApiCall from 'common/makeApiCall';

const steps = ['Choose User Type*', 'Enter Mobile Number*'];

export default function Updateprofile() {
  const [activeStep, setActiveStep] = useState(0);
  const [userType, setUserType] = useState('');
  const classes = useStyles();

  // const handleNext = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const validationSchemaStep0 = Yup.object().shape({
    userType: Yup.string().oneOf(['AR', 'ORG', 'AMP', 'ATR'], 'User Type is required').required('User Type is required')
  });
  const validationSchemaStep1 = Yup.object().shape({
    mobileNumber: Yup.string()
      .matches(/^\d{10}$/, 'Mobile Number must be 10 digits')
      .required('Mobile Number is required')
  });

  const stepperValidation = activeStep === 0 ? validationSchemaStep0 : activeStep === 1 ? validationSchemaStep1 : null;

  return (
    <Formik
      initialValues={{
        userType: '0',
        mobileNumber: ''
      }}
      validationSchema={stepperValidation}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        console.log('values', values);
        try {
          const responseData = await makeApiCall('put', '/auth/update-profile', values);
          setStatus({ success: true });
          setSubmitting(false);
          console.log('Profile update successful', responseData);
          // Set token and usertype in localStorage
          localStorage.setItem('token', responseData.token);
          localStorage.setItem('userType', responseData.user.userType);

          const userType = localStorage.getItem('userType');
          if (userType === 'ORG') {
            window.location.href = '/dashboard/eventcreate';
          } else if (userType === 'AR') {
            window.location.href = '/dashboard/artistprofile';
          } else if (userType === 'AMP') {
            window.location.href = '/dashboard/amp';
          } else if (userType === 'ATR') {
            window.location.href = '/dashboard/create-artists-repertoire';
          } else if (userType === 'AD') {
            window.location.href = '/dashboard/admin';
          }
        } catch (err) {
          console.error('Profile update failed', err);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, validateForm, setTouched }) => {
        const handleNext = async () => {
          const errors = await validateForm();
          if (Object.keys(errors).length === 0) {
            setActiveStep((prevStep) => prevStep + 1);
          } else {
            setTouched(errors);
          }
        };
        return (
          <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
            <Grid item xs={12} sm={8} md={6}>
              <Typography variant="h3" textAlign="center" sx={{ marginBottom: 6 }} gutterBottom>
                Profile Update
              </Typography>
              <Box sx={{ width: '100%', p: 2 }}>
                <Stepper className={classes.stepper} activeStep={activeStep}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    {/* <Typography sx={{ mt: 2, mb: 1 }}>
                                            All steps completed youre finished
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                            <Box sx={{ flex: '1 1 auto' }} />
                                            <Button onClick={handleReset}>Reset</Button>
                                        </Box> */}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box sx={{ mt: 2, mb: 1 }}>
                      {activeStep === 0 && (
                        <Grid container justifyContent="center" textAlign="center" spacing={2}>
                          <Grid item xs={12} sm={12} md={12}>
                            <FormControl component="fieldset">
                              <RadioGroup
                                value={userType}
                                onChange={(e) => {
                                  setUserType(e.target.value);
                                  handleChange({ target: { name: 'userType', value: e.target.value } });
                                }}
                              >
                                <FormControlLabel value="AR" control={<Radio />} label="Artist" />
                                <FormControlLabel value="ORG" control={<Radio />} label="Organization" />
                                <FormControlLabel value="AMP" control={<Radio />} label="Allied Music Professionals " />
                                <FormControlLabel value="ATR" control={<Radio />} label="Artists & Repertoire" />
                              </RadioGroup>
                              {touched.userType && errors.userType && <Typography color="error">{errors.userType}</Typography>}
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {activeStep === 1 && (
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                          <Grid item xs={8} sm={6} md={6}>
                            <OutlinedInput
                              fullWidth
                              error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                              type="text"
                              value={values.mobileNumber}
                              name="mobileNumber"
                              id="outlined-number"
                              onBlur={handleBlur}
                              onChange={(e) => {
                                const value = e.target.value.replace(/\D/g, ''); // Use regex to allow only numbers
                                handleChange({ target: { name: 'mobileNumber', value: value.slice(0, 10) } }); // Limit input to 10 digits
                              }}
                              placeholder="Mobile number"
                              inputProps={{
                                maxLength: 10 // Limit input length to 10 characters
                              }}
                            />
                            {touched.mobileNumber && errors.mobileNumber && (
                              <FormHelperText error id="helper-text-mobileNumber-signup">
                                {errors.mobileNumber}
                              </FormHelperText>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                        Back
                      </Button>

                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button
                        className={classes.button}
                        onClick={() => (activeStep === steps.length - 1 ? handleSubmit() : handleNext(values))}
                        type="button"
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </Grid>
          </Grid>
        );
      }}
    </Formik>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    color: '#fff'
  },
  date: {
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'none',
    marginBottom: 20
  },
  textfield: {
    backgroundColor: '#fff',
    borderRadius: 5
  },
  stepper: {
    '& .MuiStepLabel-root': {
      color: 'gray', // Default text color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed step color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active step color
      }
    },
    '& .MuiStepLabel-label': {
      color: '#fff', // Default text color
      '&.Mui-completed': {
        color: 'grey' // Completed step color
      },
      '&.Mui-active': {
        color: '#fff' // Active step color
      }
    },
    '& .MuiStepIcon-root': {
      color: 'gray', // Default icon color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed icon color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active icon color
      }
    }
  }
}));
