import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
// material-ui
import { Box, List, Typography, Collapse, ListItemButton, ListItemText } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SegmentIcon from '@mui/icons-material/Segment';
import EditNoteIcon from '@mui/icons-material/EditNote';
// project import
import NavItem from './NavItem';

// ==============================|| NAVIGATION - LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
  const menu = useSelector((state) => state.menu);
  const { drawerOpen } = menu;

  const [listOpen, setListOpen] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);

  const handleClickList = () => {
    setListOpen(!listOpen);
    // if (createOpen) setCreateOpen(false); // Close create menu if open
  };

  const handleClickCreate = () => {
    setCreateOpen(!createOpen);
    // if (listOpen) setListOpen(false); // Close list menu if open
  };

  const renderNavCollapse = (children) => {
    return children.map((menuItem) => {
      switch (menuItem.type) {
        case 'listCollapse':
          return (
            <React.Fragment key={menuItem.id}>
              <ListItemButton onClick={handleClickList}>
              <SegmentIcon sx={{ fontSize: 27 }} style={{ color: 'white', paddingLeft: '10px' }} />
                <ListItemText primary={menuItem.title}  style={{ color: 'white', paddingLeft: '15px'}}/>
                {listOpen ? <ExpandLess style={{ color: 'white'}} /> : <ExpandMore style={{ color: 'white'}} />}
              </ListItemButton>
              <Collapse in={listOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderNavCollapse(menuItem.children)}
                </List>
              </Collapse>
            </React.Fragment>
          );
        case 'createCollapse':
          return (
            <React.Fragment key={menuItem.id}>
              <ListItemButton onClick={handleClickCreate}>
              <EditNoteIcon sx={{ fontSize: 27 }} style={{ color: 'white', paddingLeft: '10px' }} />
                <ListItemText primary={menuItem.title} style={{ color: 'white', paddingLeft: '15px'}}/>
                {createOpen ? <ExpandLess style={{ color: 'white'}} /> : <ExpandMore style={{ color: 'white'}} />}
              </ListItemButton>
              <Collapse in={createOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {renderNavCollapse(menuItem.children)}
                </List>
              </Collapse>
            </React.Fragment>
          );
        case 'item':
          return <NavItem key={menuItem.id} item={menuItem} level={1} />;
          case 'item2':
          return <NavItem key={menuItem.id} item={menuItem} level={2} />;
        default:
          return (
            <Typography key={menuItem.id} variant="h6" color="error" align="center">
              Fix - Group Collapse or Items
            </Typography>
          );
      }
    });
  };

  return (
    <List
      subheader={
        item.title &&
        drawerOpen && (
          <Box sx={{ pl: 3, mb: 1.5 }}>
            <Typography variant="subtitle2" sx={{ color: "#ff00a9" }}>
              {item.title}
            </Typography>
            {/* only available in paid version */}
          </Box>
        )
      }
      sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
    >
      {renderNavCollapse(item.children)}
    </List>
  );
};

NavGroup.propTypes = {
  item: PropTypes.object
};

export default NavGroup;
