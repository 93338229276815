// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  OrderedListOutlined,
  PlusOutlined,
  ReadOutlined
} from '@ant-design/icons';

// icons
const icons = {
  FontSizeOutlined,
  OrderedListOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  PlusOutlined,
  ReadOutlined
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //
const userType = localStorage.getItem("userType");

const utilities = userType === 'ORG' && {
  id: 'utilities',
  title: 'Event',
  type: 'group',
  children: [
    userType === 'ORG' && {
      id: 'util-eventcreate',
      title: 'Create Event',
      type: 'item',
      url: '/dashboard/eventcreate',
      icon: icons.PlusOutlined
    },

    // userType === 'ORG' && {
    //   id: 'util-eventlist',
    //   title: 'List Events',
    //   type: 'item',
    //   url: '/dashboard/eventlist',
    //   icon: icons.OrderedListOutlined
    // },
    userType === 'ORG' && {
      id: 'util-myevent',
      title: 'My Event',
      type: 'item',
      url: '/dashboard/myevent',
      icon: icons.ReadOutlined
    },


    // Conditional rendering based on userType


    // {
    //   id: 'util-eventdetails',
    //   // title: 'Event List',
    //   type: 'item',
    //   url: '/eventlist/eventdetails',
    //   // icon: icons.OrderedListOutlined
    // },

    // {
    //   id: 'util-typography',
    //   title: 'Typography',
    //   type: 'item',
    //   url: '/typography',
    //   icon: icons.FontSizeOutlined
    // },
    // {
    //   id: 'util-color',
    //   title: 'Color',
    //   type: 'item',
    //   url: '/color',
    //   icon: icons.BgColorsOutlined
    // },
    // {
    //   id: 'util-shadow',
    //   title: 'Shadow',
    //   type: 'item',
    //   url: '/shadow',
    //   icon: icons.BarcodeOutlined
    // },
    // {
    //   id: 'ant-icons',
    //   title: 'Ant Icons',
    //   type: 'item',
    //   url: '/icons/ant',
    //   icon: icons.AntDesignOutlined,
    //   breadcrumbs: false
    // }
  ].filter(Boolean)
};

export default utilities;
