import React, { useState, useEffect } from 'react';
import {
  Typography,
  Box,
  Tabs,
  Tab,
  Grid,
  // Stack,
  Container,
  Paper,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Button,
  Divider
} from '@mui/material';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useParams } from 'react-router-dom';
import makeApiCall from 'common/makeApiCall';

export default function Eventdetails() {
  const { id } = useParams();
  const [value, setValue] = useState(0);
  const [events, setEvent] = useState(null);

  const [error, setError] = useState(null);

  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabs = [{ label: 'Event Details' }, { label: 'Organizer' }, { label: 'Artist' }];

  // ===================|EVENT DETAILS API CALL WITH SINGLE ID|========================//
  useEffect(() => {
    const fetchEvent = async () => {
      try {
        const eventData = await makeApiCall('get', `/event/detail/${id}`);
        setEvent(eventData);
      } catch (error) {
        setError(error); // Set error state on failure
      }
    };
    fetchEvent();
  }, [id]);
  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 'P':
        return 'PAID';
      case 'UP':
        return 'UNPAID';
      default:
        return 'UNPAID';
    }
  };
  const getRateLabel = (label) => {
    switch (label) {
      case 'PD':
        return 'Per Day';
      case 'PH':
        return 'Per Hour';
      case 'PS':
        return 'Per Song';
      default:
        return 'Per Hour';
    }
  };
  return (
    <div>
      <Box className={classes.root}>
        <Container maxWidth="sm">
          {/* <Stack direction="row" spacing={2} className={classes.userInfo}>
            <Typography variant="h6">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</Typography>
          </Stack> */}
          <Avatar className={classes.avatar} src="https://smcdn.auctusdigital.co.uk/images/2023/11/16/20231116112306.webp" />
        </Container>
      </Box>

      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <Box>
            <Tabs className={classes.tabs} value={value} onChange={handleChange} variant="fullWidth">
              {tabs.map((tab, index) => (
                <Tab key={index} label={tab.label} />
              ))}
            </Tabs>
          </Box>
          {/* Replace with content for each tab */}
          {value === 0 && (
            <>
              {events && (
                <Card sx={{ margin: '10px auto' }}>
                  <Box sx={{ position: 'relative' }}>
                    <CardMedia
                      sx={{ height: 250 }}
                      image="https://img.freepik.com/free-photo/group-workers-organizing-protest_23-2151546471.jpg?t=st=1723638883~exp=1723642483~hmac=e4245ac7ea77da58d80f09ec7085905271e3ec0ef620011a7a2a8bcd7b379fd8&w=1060"
                      title="event image"
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        bottom: 0,
                        width: '100%',
                        bgcolor: 'rgba(37, 37, 37, 0.8)', // Semi-transparent background color
                        backdropFilter: 'blur(8px)', // Blur and saturation for glass effect
                        color: 'white',
                        padding: '10px',
                        textAlign: 'center'
                      }}
                    >
                      <Typography gutterBottom variant="h4" component="div">
                        {events.title}
                      </Typography>
                    </Box>
                  </Box>
                  <CardContent>
                    <Typography variant="body1">{events.description}</Typography>
                  </CardContent>

                  <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">Start Date</Typography>
                        <Button size="small">{events.eventStartDate}</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">Start Time</Typography>
                        <Button size="small">{events.eventStartTime}</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">End Date</Typography>
                        <Button size="small">{events.eventEndDate}</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">End Time</Typography>
                        <Button size="small">{events.eventEndTime}</Button>
                      </Box>
                    </Grid>
                  </Grid>

                  <Divider />

                  <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">Country</Typography>
                        <Button size="small">{events.country}</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">State or Province</Typography>
                        <Button size="small">{events.state}</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">City</Typography>
                        <Button size="small">{events.city}</Button>
                      </Box>
                    </Grid>
                  </Grid>

                  <Divider />
                  <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">Payment Status</Typography>
                        <Button size="small">{getPaymentStatusLabel(events.paymentStatus)}</Button>
                      </Box>
                    </Grid>
                    {events.paymentStatus === 'P' &&
                      events.currencyType &&
                      (events.currencyType === 'USD' || events.currencyType === 'INR') && (
                        <>
                          <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography variant="subtitle1">Currency Type</Typography>
                              <Button size="small">{events.currencyType}</Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography variant="subtitle1">Amount</Typography>
                              <Button size="small">{events.amount}</Button>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <Typography variant="subtitle1">Amount Basis</Typography>
                              <Button size="small">{getRateLabel(events.amountBasis)}</Button>
                            </Box>
                          </Grid>
                        </>
                      )}
                  </Grid>
                  <Divider />

                  <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Typography variant="subtitle1">Contact Number</Typography>
                        <Button size="small">{events.contactNumber}</Button>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Typography variant="subtitle1">Google Plus code</Typography>
                      <Link target="_blank" 
      rel="noopener noreferrer" 
      style={{ textDecoration: 'none' }}
       to={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(events.location)}`} >
                        <Button size="small">{events.location}</Button>
                        </Link>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>

                // <CardContent>
                //     <Typography variant="h5" component="div">
                //         Event
                //     </Typography>
                //     <Card variant="subtitle1" color="text.secondary" sx={{ backgroundColor: 'red' }}>
                //         {events.title}
                //     </Card>
                //     <Typography variant="h5" component="div">
                //         Start Date
                //     </Typography>
                //     <Typography variant="subtitle1" color="text.secondary">
                //         {events.eventStartDate}
                //     </Typography>
                //     <Typography variant="h5" component="div">
                //         End Date
                //     </Typography>
                //     <Typography variant="subtitle1" color="text.secondary">
                //         {events.eventEndDate}
                //     </Typography>
                //     <Typography variant="h5" component="div">
                //         State
                //     </Typography>
                //     <Typography variant="subtitle1" color="text.secondary">
                //         {events.state}
                //     </Typography>
                //     <Typography variant="h5" component="div">
                //         City
                //     </Typography>
                //     <Typography variant="subtitle1" color="text.secondary">
                //         {events.city}
                //     </Typography>
                //     <Typography variant="h5" component="div">
                //         Country
                //     </Typography>
                //     <Typography variant="subtitle1" color="text.secondary">
                //         {events.country}
                //     </Typography>
                //     <Typography variant="h5" component="div">
                //         Contact Number
                //     </Typography>
                //     <Typography variant="subtitle1" color="text.secondary">
                //         {events.contactNumber}
                //     </Typography>
                // </CardContent>
              )}
              {!events && !error && <Typography>Loading event details...</Typography>} {/* Display loading message while fetching data */}
              {error && <Typography>Error fetching event details: {error.message}</Typography>}{' '}
              {/* Display error message if fetching fails */}
            </>
          )}
          {value === 1 && (
            <Card sx={{ margin: '10px auto' }}>
              <Grid container spacing={2} sx={{ padding: 2 }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="subtitle1">Name</Typography>
                    <Button size="small">{events.user.username}</Button>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Typography variant="subtitle1">Email</Typography>
                    <Button size="small">{events.user.email}</Button>
                  </Box>
                </Grid>
                {/* <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">Start Time</Typography>
                                        <Button size="small">{events.eventStartTime}</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">End Time</Typography>
                                        <Button size="small">{events.eventEndTime}</Button>
                                    </Box>
                                </Grid> */}
              </Grid>
              {/* <Divider />
                            <Grid container spacing={2} sx={{ padding: 2 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">Country</Typography>
                                        <Button size="small">{events.country}</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">State or Province</Typography>
                                        <Button size="small">{events.state}</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">City</Typography>
                                        <Button size="small">{events.city}</Button>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Divider />
                            <Grid container spacing={2} sx={{ padding: 2 }}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">Contact Number</Typography>
                                        <Button size="small">{events.contactNumber}</Button>
                                    </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                        <Typography variant="subtitle1">Google Plus code</Typography>
                                        <Button size="small">{events.location}</Button>
                                    </Box>
                                </Grid>
                            </Grid> */}
            </Card>
          )}
          {value === 2 && (
            <Card sx={{ backgroundColor: '#e0f7fa', margin: '10px auto', boxShadow: 3, padding: '16px' }}>
              <Paper elevation={10} sx={{ backgroundColor: '#fafafa', padding: '16px', borderRadius: '20px' }}>
                <CardContent>
                  {/* Artist Description */}
                  <Typography variant="h5" component="div" sx={{ marginBottom: 1, fontWeight: 'bold' }}>
                    Artist Description
                  </Typography>
                  <Typography variant="body1" color="text.secondary" sx={{ marginBottom: 4 }}>
                    {events.artistDescription}
                  </Typography>

                  {/* Artists Section */}
                  <Grid container spacing={3}>
                    {events.artists && (
                      <>
                        {events.artists.map((artist) => (
                          <Grid item xs={12} sm={6} md={4} key={artist.userId}>
                            {/* Each Artist Card */}
                            <Link target="_blank" to={`/dashboard/artistview/${artist.userId}`} style={{ textDecoration: 'none' }}>
                              <Box
                                sx={{
                                  backgroundColor: '#e0f7fa',
                                  padding: '16px',
                                  borderRadius: '8px',
                                  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                  textAlign: 'center',
                                  maxWidth: '200px',
                                  height: '100%'
                                }}
                              >
                                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', marginBottom: 1 }}>
                                  {artist.fullName}
                                </Typography>
                                <Typography variant="subtitle2" color="text.secondary" sx={{ fontStyle: 'italic' }}>
                                  ({artist.id})
                                </Typography>
                              </Box>
                            </Link>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                </CardContent>
              </Paper>
            </Card>
          )}
          {value === 3 && <Typography variant="body1">This is the About tab. Share more about your channel here.</Typography>}
        </Grid>
      </Grid>
    </div>
  );
}
const useStyles = makeStyles(() => ({
  root: {
    backgroundImage: 'url(https://cdn.pixabay.com/photo/2016/10/29/01/22/abstract-1779560_1280.png)',
    position: 'relative',
    height: '280px',
    width: '100%',
    backgroundSize: 'cover',
    borderRadius: 15
    // backgroundRepeat: 'no-repeat',
  },
  avatar: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 200,
    height: 200,
    backgroundSize: 'cover'
  },
  userInfo: {
    position: 'absolute',
    bottom: '10px',
    left: '10px',
    color: '#FFF'
  },
  tabs: {
    borderBottom: 'none',
    marginTop: 5,
    borderRadius: 8,
    '& .MuiTabs-indicator': {
      display: 'none' // Remove the default indicator
    },
    '& .MuiTab-root': {
      backdropFilter: 'blur(10px) saturate(150%)',
      backgroundColor: 'rgba(255, 255, 255, 0.30)',

      color: '#fff',
      '&:hover': {
        color: 'black'
      }
    },
    '& .Mui-selected': {
      color: 'white',
      borderBottom: 'none',
      background: 'linear-gradient(to right, #ff0093, #7900ff)'
    }
  }
}));
