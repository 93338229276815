// assets

import LyricsIcon from '@mui/icons-material/Lyrics';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';

// icons
const icons = {
  LyricsIcon,
  QueueMusicIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //
const userType = localStorage.getItem("userType");

const Organizerlist = userType === 'ORG' && {
  id: 'utilities',
  title: 'Event List',
  type: 'group',

  children: [
    userType === 'ORG' && {
      id: 'util-eventlist',
      title: 'List Events',
      type: 'item',
      url: '/dashboard/eventlist',
      icon: icons.QueueMusicIcon
    },
    userType === 'ORG' && {
      id: 'util-listartist',
      title: 'List Artist',
      type: 'item',
      url: '/dashboard/listartist',
      icon: icons.LyricsIcon
    },

    // userType === 'ORG' && {
    //   id: 'util-artistprofile',
    //   // title: 'Event List',
    //   // type: 'item',
    //   url: '/listartist/artistprofile',
    //   // icon: icons.OrderedListOutlined
    // },

    // userType === 'ORG' && {
    //   id: 'util-eventlist',
    //   title: 'Events List',
    //   type: 'item',
    //   url: '/dashboard/eventlist',
    //   icon: icons.OrderedListOutlined
    // },
    // userType === 'ORG' && {
    //   id: 'util-myevent',
    //   title: 'My Event',
    //   type: 'item',
    //   url: '/dashboard/myevent',
    //   icon: icons.ReadOutlined
    // },


    // Conditional rendering based on userType
    // userType === 'AR' && {
    //   id: 'util-artistprofile',
    //   title: 'Artist Profile',
    //   type: 'item',
    //   url: '/dashboard/artistprofile',
    //   icon: icons.ReadOutlined
    // },

    // {
    //   id: 'util-eventdetails',
    //   // title: 'Event List',
    //   type: 'item',
    //   url: '/eventlist/eventdetails',
    //   // icon: icons.OrderedListOutlined
    // },

    // {
    //   id: 'util-typography',
    //   title: 'Typography',
    //   type: 'item',
    //   url: '/typography',
    //   icon: icons.FontSizeOutlined
    // },
    // {
    //   id: 'util-color',
    //   title: 'Color',
    //   type: 'item',
    //   url: '/color',
    //   icon: icons.BgColorsOutlined
    // },
    // {
    //   id: 'util-shadow',
    //   title: 'Shadow',
    //   type: 'item',
    //   url: '/shadow',
    //   icon: icons.BarcodeOutlined
    // },
    // {
    //   id: 'ant-icons',
    //   title: 'Ant Icons',
    //   type: 'item',
    //   url: '/icons/ant',
    //   icon: icons.AntDesignOutlined,
    //   breadcrumbs: false
    // }
  ].filter(Boolean)
};

export default Organizerlist;
