import React, { useState, useEffect } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { Formik, Field, FieldArray } from 'formik';
import { makeStyles } from '@mui/styles';
import { Country } from 'data/Country';
import { states } from 'data/State';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import YouTubeIcon from '../../assets/icons/youtube.png';
import InstagramIcon from '../../assets/icons/instagram.png';
import FacebookIcon from '../../assets/icons/facebook.png';
import TwitterIcon from '../../assets/icons/twitter.png';
import LinkedInIcon from '../../assets/icons/linkedin.png';
import SpotifyIcon from '../../assets/icons/spotify.png';
import SoundCloudIcon from '../../assets/icons/soundcloud.png';
import makeApiCall from 'common/makeApiCall';
import { PerformanceTypeList, instrumentList, genreList } from 'data/Common';
import AdminAuthRegister from 'pages/admin/AdminAuthRegister';
// const socialMediaOptions = [
//     { value: 'youtube', label: 'YouTube', icon: <img src={YouTubeIcon} width="22" height="18" alt="YouTubeIcon" /> },
//     { value: 'instagram', label: 'Instagram', icon: <img src={InstagramIcon} width="21" height="20" alt="InstagramIcon" /> },
//     { value: 'facebook', label: 'Facebook', icon: <img src={FacebookIcon} width="20" height="20" alt="FacebookIcon" /> },
//     { value: 'x', label: 'X', icon: <img src={TwitterIcon} width="20" height="18" alt="TwitterIcon" /> },
//     { value: 'linkedin', label: 'LinkedIn', icon: <img src={LinkedInIcon} width="22" height="20" alt="LinkedInIcon" /> },
//     { value: 'spotify', label: 'Spotify', icon: <img src={SpotifyIcon} width="21" height="21" alt="SpotifyIcon" /> },
//     { value: 'soundcloud', label: 'SoundCloud', icon: <img src={SoundCloudIcon} width="22" height="20" alt="SoundCloudIcon" /> },
// ];
const steps = [
  'Artist Types *',
  'Genre *',
  'Basic Details *',
  'Social Links*',
  'Professional BIO *',
  'Performance Type *',
  'Add Portfolio *'
];
const validationSchemaStep0 = Yup.object().shape({
  artistType: Yup.string().required('Artist Type is required'),
  instruments: Yup.array()
    .default([]) // Ensure instruments is always an array
    .min(1, 'Instrument is required')
    .test('custom-instrument-check', 'Custom instrument is required when selecting Others', function (instruments = []) {
      const { otherInstrument } = this.parent;
      // Ensure the array has at least one item and handle the "Others" option correctly
      if (instruments.length === 0) {
        return false;
      }
      // If "Others" is selected, ensure otherInstrument is provided
      if (instruments.includes('OT') && !otherInstrument?.trim()) {
        return false;
      }
      return true;
    }),
  otherInstrument: Yup.string().test(
    'custom-instrument-required',
    'Custom instrument is required when selecting Others',
    (otherInstrument, { parent }) => {
      if (parent.instruments === 'OT') {
        return !!otherInstrument?.trim();
      }
      return true;
    }
  )
});
const validationSchemaStep1 = Yup.object().shape({
  genres: Yup.array()
    .default([]) // Ensure genres is always an array
    .min(1, 'Genre is required')
    .test('custom-instrument-check', 'Custom genre is required when selecting Others', function (genres = []) {
      const { otherGenre } = this.parent;
      // Ensure the array has at least one item and handle the "Others" option correctly
      if (genres.length === 0) {
        return false;
      }
      // If "Others" is selected, ensure otherGenre is provided
      if (genres.includes('OT') && !otherGenre?.trim()) {
        return false;
      }
      return true;
    }),
  otherGenre: Yup.string().test(
    'custom-instrument-required',
    'Custom instrument is required when selecting Others',
    (otherGenre, { parent }) => {
      if (parent.genres === 'OT') {
        return !!otherGenre?.trim();
      }
      return true;
    }
  )
});

const validationSchemaStep2 = Yup.object().shape({
  fullName: Yup.string().required('Name is required').max(25, 'Maximum 25 characters allowed'),
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  contactNumber: Yup.string().required('Contact Number is required')
});
// Define the validation schema
const validationSchemaStep3 = Yup.object({
  youtubeProfile: Yup.string()
    .matches(
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:user\/|channel\/|c\/)?(?:@[a-zA-Z0-9\-_]+|[a-zA-Z0-9_]+@[a-zA-Z0-9_]+)(?:\/|$|\?)/,
      'Enter a valid YouTube URL, username, or channel ID (including new "@username" format)'
    )
    .nullable(), // Optional field
  instagramProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?instagram\.com\/[a-zA-Z0-9\-_]+$/, 'Enter a valid Instagram URL or username/ID')
    .nullable(),
  fbProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?facebook\.com\/[a-zA-Z0-9._-]+$/, 'Enter a valid Facebook URL or username/ID')
    .nullable(),
  xProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?(?:twitter\.com|x\.com)\/[a-zA-Z0-9_]+$/, 'Enter a valid X (Twitter) URL or username/ID')
    .nullable(),
  linkedinProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?linkedin\.com\/in\/[a-zA-Z0-9\-_]+$/, 'Enter a valid LinkedIn URL or username/ID')
    .nullable(),
  spotifyProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?open\.spotify\.com\/artist\/[a-zA-Z0-9\-_]+$/, 'Enter a valid Spotify URL or artist ID')
    .nullable(),
  soundcloudProfile: Yup.string()
    .matches(/^(?:https?:\/\/)?(?:www\.)?soundcloud\.com\/[a-zA-Z0-9\-_]+$/, 'Enter a valid SoundCloud URL or username/ID')
    .nullable(),
  socialProfiles: Yup.string().test('at-least-one-profile', 'At least one social media profile is required', function () {
    // Access sibling fields via `this.parent`
    const { youtubeProfile, instagramProfile, fbProfile, xProfile, linkedinProfile, spotifyProfile, soundcloudProfile } = this.parent;
    // Check if at least one profile is filled
    return youtubeProfile || instagramProfile || fbProfile || xProfile || linkedinProfile || spotifyProfile || soundcloudProfile;
  })
});

const validationSchemaStep4 = Yup.object({
  biography: Yup.string().required('Biography is required').max(1000, 'Maximum 1000 characters allowed')
});
const validationSchemaStep5 = Yup.object({
  performanceType: Yup.array().min(1, 'At least one option must be selected')
});
const validationSchemaStep6 = Yup.object({
  youtubeUrl: Yup.string()
    .required('Portfolio is required')
    .matches(/^https:\/\/www\.youtube\.com\/embed\/.+$/, 'Invalid YouTube embed URL')
});

export default function Artistprofile() {
  const userType = localStorage.getItem('userType');
  const [getArtist, setGetArtist] = useState([]);
  const [openProfileCreate, setOpenProfileCreate] = useState(false);
  const [userIdFromAdmin, setUserIdFromAdmin] = useState('');
  const [artistDetails, setArtistDetails] = useState(false);
  let navigate = useNavigate();
  const fetchArtist = async () => {
    try {
      const artistData = await makeApiCall('get', `/artist/myprofile`);
      setGetArtist(artistData);
    } catch (error) {
      // setError(error);
    }
  };

  const {
    id,
    artistType,
    biography,
    city,
    contactNumber,
    country,
    fbProfile,
    fullName,
    genres,
    instagramProfile,
    instruments,
    otherInstrument,
    otherGenre,
    linkedinProfile,
    soundcloudProfile,
    spotifyProfile,
    performanceType,
    state,
    xProfile,
    youtubeProfile,
    youtubeUrl
  } = (getArtist && getArtist) || [];

    // ------/ set created id or existing id /-------
    const artistId = (id && id) || artistDetails.id;
  useEffect(() => {
    fetchArtist();
    if (getArtist) {
      setSelectedCountry(country);
      setFilteredStates(states[country] || []);
    }
  }, [country]);

  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const [selectedCountry, setSelectedCountry] = useState('');
  // const [selectedCountryName, setSelectedCountryName] = useState('');
  const [filteredStates, setFilteredStates] = useState([]);

  const handleCountryChange = (event) => {
    const countryId = event.target.value;
    Country.find((c) => c.id === countryId)?.name || '';
    setSelectedCountry(countryId);
    //   setSelectedCountryName(countryName);
    setFilteredStates(states[countryId] || []);
  };

  const [embedUrl, setEmbedUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (youtubeUrl) {
      setEmbedUrl(youtubeUrl); // Set the embedUrl when youtubeUrl is available
    }
  }, [youtubeUrl]); // Re-run effect when youtubeUrl changes

  const handleUrlChange = (event, setFieldValue) => {
    const url = event.target.value;
    setFieldValue('youtubeUrl', url);
    setEmbedUrl('');

    // Regular expression to match YouTube video URLs
    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = youtubeRegex.exec(url);

    if (match) {
      const videoId = match[1];
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      setEmbedUrl(embedUrl);
      setErrorMessage('');
    } else {
      setEmbedUrl('');
      setErrorMessage('Please enter a valid YouTube URL');
    }
  };
  // Handle checkbox change
  // Initial values
  // const initialValues = {
  //     performanceType: []
  // };

  // Options array
  // const PerformanceTypeList = [
  //   { value: 'LP', label: 'Live Performance' },
  //   { value: 'SM', label: 'Session Musicians' },
  //   { value: 'CO', label: 'Collaboration' },
  //   { value: 'HI', label: 'Hire' },
  //   { value: 'OM', label: 'Open Mic' },
  //   { value: 'MF', label: 'Music Festivals' }
  // ];
  // const instrumentList = [
  //   { value: 'GU', label: 'Guitar' },
  //   { value: 'PI', label: 'Piano' },
  //   { value: 'UK', label: 'Ukulele' },
  //   { value: 'VI', label: 'Violin' },
  //   { value: 'DR', label: 'Drums' },
  //   { value: 'CE', label: 'Cello' },
  //   { value: 'FL', label: 'Flute' },
  //   { value: 'OT', label: 'Others' }
  // ];
  // const genreList = [
  //   { value: 'JA', label: 'Jazz' },
  //   { value: 'PO', label: 'Pop' },
  //   { value: 'RO', label: 'Rock' },
  //   { value: 'CL', label: 'Classical' },
  //   { value: 'HH', label: 'Hip-Hop' },
  //   { value: 'RA', label: 'Rap' },
  //   { value: 'FM', label: 'Folk Music' },
  //   { value: 'CM', label: 'Country Music' },
  //   { value: 'BL', label: 'Blues' },
  //   { value: 'OT', label: 'Others' }
  // ];

  const validationSchema =
    activeStep === 0
      ? validationSchemaStep0
      : activeStep === 1
        ? validationSchemaStep1
        : activeStep === 2
          ? validationSchemaStep2
          : activeStep === 3
            ? validationSchemaStep3
            : activeStep === 4
              ? validationSchemaStep4
              : activeStep === 5
                ? validationSchemaStep5
                : activeStep === 6
                  ? validationSchemaStep6
                  : null;

  const handleRegisterSuccess = (userId) => {
    setUserIdFromAdmin(userId);
    setOpenProfileCreate(true); // Set state to true once registration is successful
  };
  return (
    <>
      <Formik
        initialValues={{
          // artistType: '',
          // instruments: '',
          // otherInstrument: '',
          // genres: '',
          // customGenre: '',
          // fullName: '',
          // country: '',
          // state: '',
          // city: '',
          // contactNumber: '',
          // biography: '',
          // youtubeProfile: '',
          // instagramProfile: '',
          // fbProfile: '',
          // xProfile: '',
          // linkedinProfile: '',
          // spotifyProfile: '',
          // soundcloudProfile: '',
          // performanceType: [],
          // youtubeUrl: ''
          artistType: artistType ? artistType : '',
          // instruments: instruments ? instruments : '',
          instruments: instruments ? instruments?.split(',') : '', // ["GU", "PI"] : [],
          otherInstrument: otherInstrument ? otherInstrument : '',
          // genres: genres ? genres : '',
          genres: genres ? genres?.split(',') : '',
          otherGenre: otherGenre ? otherGenre : '',
          fullName: fullName ? fullName : '',
          country: country ? country : '',
          state: state ? state : '',
          city: city ? city : '',
          contactNumber: contactNumber ? contactNumber : '',
          biography: biography ? biography : '',
          youtubeProfile: youtubeProfile ? youtubeProfile : '',
          instagramProfile: instagramProfile ? instagramProfile : '',
          fbProfile: fbProfile ? fbProfile : '',
          xProfile: xProfile ? xProfile : '',
          linkedinProfile: linkedinProfile ? linkedinProfile : '',
          spotifyProfile: spotifyProfile ? spotifyProfile : '',
          soundcloudProfile: soundcloudProfile ? soundcloudProfile : '',
          performanceType: performanceType ? performanceType.split(',') : '', // Assuming performanceType don't come from artistId
          youtubeUrl: youtubeUrl ? youtubeUrl : ''
          // Add any other fields that are necessary
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting, resetForm }) => {
          if (activeStep === 0) {
            const errors = await validateForm();
            if (Object.keys(errors).length > 0) {
              setErrors(errors);
              setSubmitting(false);
              return;
            }
          }
          try {
            // const formattedCheckbox = values.performanceType.reduce((acc, item, index) => {
            //   acc[`${index + 1}`] = item;
            //   return acc;
            // }, {});
            // const formattedInstruments = values.instruments.reduce((acc, item, index) => {
            //   acc[`${index + 1}`] = item;
            //   return acc;
            // }, {});
            // const formattedGenres = values.genres.reduce((acc, item, index) => {
            //   acc[`${index + 1}`] = item;
            //   return acc;
            // }, {});
            // console.log('Performance type:', formattedCheckbox);
            // console.log('Instruments:', formattedInstruments);
            // console.log('Gerres:', formattedGenres);
            const instrumentString = values?.instruments?values?.instruments?.join(',') : null; // "GU,PI"
            const genreString = values?.genres?values?.genres?.join(',') : null; // "GU,PI"
            const performanceTypeString = values?.performanceType?values?.performanceType?.join(',') : null; // "GU,PI"
            // const instrument = values.instruments === 'Others' && values.otherInstrument ? values.otherInstrument : values.instruments;
            // const genreToSubmit = values.genres === 'Others' ? values.customGenre : values.genres;
            const requestData = {
              ...values,

              instruments: instrumentString,
              genres: genreString,
              performanceType: performanceTypeString,
              
              artistTypeAdded: activeStep && activeStep>0?'Y':'N',
              genreAdded: activeStep && activeStep>1?'Y':'N',
              basicDetailsAdded: activeStep && activeStep>2?'Y':'N',
              socialLinksAdded: activeStep && activeStep>3?'Y':'N',
              professionalBioAdded: activeStep && activeStep>4?'Y':'N',
              performanceTypeAdded: activeStep && activeStep>5?'Y':'N',
              portfolioAdded: values.submitFlag==="submitArtist"?'Y':'N',
              artistApplicationStatus: values.submitFlag==="submitArtist"?'CP':'IP',
              ...(userType === 'AD' && { userId: userIdFromAdmin })
            };

            const artistEndPoint = artistId ? '/artist/artistupdate' : '/artist/artistcreate';
            const artistmethod = artistId ? 'put' : 'post';
            const responseData = await makeApiCall(
              artistmethod,
              artistEndPoint,
              requestData,
              // values,
              values.submitFlag==="submitArtist"?'Artist Profile create successful':null,
              'Artist Profile create failed'
            );
            setArtistDetails(responseData?.artist);
            setStatus({ success: true });
              if (userType != 'AD' && values.submitFlag==="submitArtist") {
                resetForm();
                setSubmitting(false);
                window.location.href = `/dashboard/artist-my-profile`;
            } else if (values.submitFlag==="submitArtist"){
              navigate('/dashboard/listartist')
            } else {
              console.log("Next steps");
            }
          } catch (err) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          // isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
          handleSubmit,
          validateForm,
          setTouched
          // isValid
        }) => {
          const handleNext = async () => {
            const errors = await validateForm(); // Validate the current form step
            if (Object.keys(errors).length === 0) {
              setActiveStep((prevStep) => prevStep + 1); // Move to the next step
              handleSubmit();
            } else {
              setTouched(errors); // Mark fields as touched if there are errors
            }
          };

          return (
            <>
              {userType === 'AD' && !openProfileCreate ? (
                <AdminAuthRegister adminUserType={'ARAdmin'} onSuccess={handleRegisterSuccess} />
              ) : (
                <Box>
                  <Stepper sx={{ flexWrap: 'wrap', gap: 0.5 }} className={classes.stepper} activeStep={activeStep}>
                    {steps.map((label) => (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                  <Box sx={{ mt: 2, mb: 1 }}>
                    {activeStep === steps.length ? (
                      <Button>Reset</Button>
                    ) : (
                      <>
                        <Box sx={{ mt: 2, mb: 1 }}>
                          {activeStep === 0 && (
                            <Grid container spacing={2} textAlign="center" rowSpacing={5}>
                              {/* Radio Group */}

                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <FormControl component="fieldset">
                                      <FormControl component="fieldset">
                                        <RadioGroup name="artistType" value={values.artistType} onChange={handleChange}>
                                          <FormControlLabel value="Vocal" control={<Radio />} label="Vocal" />
                                          <FormControlLabel value="Instruments" control={<Radio />} label="Instruments" />
                                        </RadioGroup>
                                      </FormControl>

                                      {touched.artistType && errors.artistType && (
                                        <Typography color="error">{errors.artistType}</Typography>
                                      )}
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <FormControl component="fieldset" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                      <Grid container spacing={1} direction="column" alignItems="center" justifyContent="center">
                                        <Grid item xs={12} sm={8} md={6} style={{ paddingTop: 10 }}>
                                          <Box>
                                            <FieldArray name="instruments">
                                              {({ push, remove }) => (
                                                <div>
                                                  {instrumentList.map((option) => (
                                                    <div
                                                      key={option.value}
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginBottom: '8px', // Reduced margin for better spacing
                                                        cursor: 'pointer'
                                                        // justifyContent: 'center' // Centers each option
                                                      }}
                                                    >
                                                      <Field
                                                        type="checkbox"
                                                        name="instruments"
                                                        value={option.value}
                                                        id={`instrument-${option.value}`} // Unique id for each checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.instruments && values.instruments.includes(option.value)}
                                                        onChange={(e) => {
                                                          if (e.target.checked) {
                                                            console.log('e.target.checked');

                                                            push(option.value);
                                                          } else {
                                                            console.log('e.target.checked');
                                                            const index = values.instruments.indexOf(option.value);
                                                            remove(index);
                                                          }
                                                        }}
                                                        style={{ width: '17px', height: '17px' }}
                                                      />
                                                      <label
                                                        style={{ fontSize: '1rem', marginLeft: '8px', cursor: 'pointer' }}
                                                        htmlFor={`instrument-${option.value}`} // Link label to checkbox
                                                      >
                                                        {option.label}
                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                            </FieldArray>
                                          </Box>
                                        </Grid>

                                        {values.instruments.includes('OT') && (
                                          <Grid item xs={12} sm={8} md={6} sx={{ display: 'flex', justifyContent: 'center', mt: 1 }}>
                                            <TextField
                                              name="otherInstrument"
                                              value={values.otherInstrument}
                                              onChange={(e) => {
                                                const trimmedValue = e.target.value.trimStart();
                                                let sanitizedValue = trimmedValue
                                                  .replace(/<[^>]*>/g, '') // Remove HTML tags
                                                  .replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetic characters

                                                // Update the field value with the sanitized input
                                                handleChange({ target: { name: 'otherInstrument', value: sanitizedValue } });
                                              }}
                                              onBlur={handleBlur} // Trigger validation on blur
                                              fullWidth
                                              margin="normal"
                                              label="Enter the instrument"
                                              sx={{ width: '300px', textAlign: 'center', mt: 1 }} // Reduced margin-top for compactness
                                            />
                                          </Grid>
                                        )}

                                        {touched.otherInstrument && errors.otherInstrument && (
                                          <Typography color="error" sx={{ textAlign: 'center', mt: 1 }}>
                                            {errors.otherInstrument}
                                          </Typography>
                                        )}

                                        {touched.instruments && errors.instruments && (
                                          <Typography color="error" sx={{ textAlign: 'center', mt: 1 }}>
                                            {errors.instruments}
                                          </Typography>
                                        )}
                                      </Grid>
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {activeStep === 1 && (
                            <Grid container spacing={2} textAlign="center" rowSpacing={5}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <FormControl component="fieldset" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                      <Grid container spacing={2} flexDirection="column" alignItems="center" justifyContent="center">
                                        <Grid item xs={6} sm={4} md={3} style={{ paddingTop: 20 }}>
                                          <Box>
                                            <FieldArray name="genres">
                                              {({ push, remove }) => (
                                                <div>
                                                  {genreList.map((option) => (
                                                    <div
                                                      key={option.value}
                                                      style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                        marginBottom: '8px',
                                                        cursor: 'pointer'
                                                      }}
                                                    >
                                                      <Field
                                                        type="checkbox"
                                                        name="genres"
                                                        value={option.value}
                                                        id={`instrument-${option.value}`} // Unique id for each checkbox
                                                        onBlur={handleBlur}
                                                        checked={values.genres && values.genres.includes(option.value)}
                                                        onChange={(e) => {
                                                          if (e.target.checked) {
                                                            push(option.value);
                                                            console.log('option.value', option.value);
                                                          } else {
                                                            const index = values.genres.indexOf(option.value);
                                                            remove(index);
                                                          }
                                                        }}
                                                        style={{ width: '17px', height: '17px' }}
                                                      />
                                                      <label
                                                        style={{ fontSize: '1rem', marginLeft: '8px', cursor: 'pointer' }}
                                                        htmlFor={`instrument-${option.value}`} // Link label to checkbox
                                                      >
                                                        {option.label}
                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                            </FieldArray>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      {values.genres.includes('OT') && (
                                        <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                                          <TextField
                                            name="otherGenre"
                                            value={values.otherGenre}
                                            onChange={(e) => {
                                              const trimmedValue = e.target.value.trimStart();
                                              let sanitizedValue = trimmedValue
                                                .replace(/<[^>]*>/g, '') // Remove HTML tags
                                                .replace(/[^a-zA-Z\s]/g, ''); // Remove non-alphabetic characters

                                              // Update the field value with the sanitized input
                                              handleChange({ target: { name: 'otherGenre', value: sanitizedValue } });
                                            }}
                                            onBlur={handleBlur} // Trigger validation on blur
                                            fullWidth
                                            margin="normal"
                                            label="Enter the instrument"
                                            sx={{ textAlign: 'center', width: '300px' }}
                                          />
                                        </Grid>
                                      )}
                                      {touched.otherGenre && errors.otherGenre && (
                                        <Typography color="error" sx={{ textAlign: 'center', mt: 1 }}>
                                          {errors.otherGenre}
                                        </Typography>
                                      )}

                                      {/* Displaying error message for genres */}
                                      {touched.genres && errors.genres && <Typography color="error">{errors.genres}</Typography>}
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {activeStep === 2 && (
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                        width: '100%',
                                        display: 'flex'
                                      }}
                                    >
                                      <Box
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        sx={{
                                          width: {
                                            xs: '90%', // Width for extra-small screens
                                            sm: '50%' // Width for small and larger screens
                                          }
                                        }}
                                      >
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="fullName"
                                          label="Name*"
                                          value={values.fullName}
                                          onChange={(e) => {
                                            // Trim the leading spaces
                                            const trimmedValue = e.target.value.trimStart();
                                            // Remove HTML tags, special characters, and numbers
                                            let sanitizedValue = trimmedValue
                                              .replace(/<[^>]*>/g, '') // Remove HTML tags
                                              .replace(/[^a-zA-Z\s]/g, '');

                                            handleChange({ target: { name: 'fullName', value: sanitizedValue } });
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{ maxWidth: 600,'& .MuiFormLabel-root': {
                      height: '25px'} }}
                                        />
                                        {touched.fullName && errors.fullName && <Typography color="error">{errors.fullName}</Typography>}
                                        <TextField
                                          margin="dense"
                                          id="country"
                                          label="Country*"
                                          select
                                          fullWidth
                                          name="country"
                                          onBlur={handleBlur}
                                          value={selectedCountry}
                                          onChange={(event) => {
                                            handleCountryChange(event);
                                            handleChange(event);
                                          }}
                                          sx={{
                                            '& .MuiFormLabel-root': {
                                              height: '30px'
                                            },
                                            maxWidth: 600,
                                            
                                          }}
                                        >
                                          {Country.map((option) => (
                                            <MenuItem
                                              sx={{ display: 'flex', flexWrap: 'wrap' }}
                                              key={option.id}
                                              value={option.id}
                                              disabled={option.disabled}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                        {touched.country && errors.country && <Typography color="error">{errors.country}</Typography>}

                                        <TextField
                                          margin="dense"
                                          id="state"
                                          label="State or Provinces"
                                          select
                                          fullWidth
                                          name="state"
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values?.state}
                                          disabled={!selectedCountry || selectedCountry === '0'}
                                          sx={{ maxWidth: 600,'& .MuiFormLabel-root': {
                                                height: '20px'
                                              } }}
                                        >
                                          {filteredStates.map((option) => (
                                            <MenuItem
                                              sx={{ display: 'flex', flexWrap: 'wrap' }}
                                              key={option.id}
                                              value={option.id}
                                              disabled={option.disabled}
                                            >
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                        {touched.state && errors.state && <Typography color="error">{errors.state}</Typography>}
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="city"
                                          label="City*"
                                          value={values.city}
                                          onChange={(e) => {
                                            const trimmedValue = e.target.value.trimStart();
                                            // Remove special characters, numbers, and HTML tags
                                            let sanitizedValue = trimmedValue
                                              .replace(/<[^>]*>/g, '') // Remove HTML tags
                                              .replace(/[^a-zA-Z\s]/g, '');
                                            if (sanitizedValue.length > 50) {
                                              sanitizedValue = sanitizedValue.substring(0, 50);
                                            }
                                            handleChange({ target: { name: 'city', value: sanitizedValue } });
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{
                                            maxWidth: 600,
                                            '& .MuiFormLabel-root': {
                                              height: '10px'
                                            }
                                          }}
                                        />
                                        {touched.city && errors.city && <Typography color="error">{errors.city}</Typography>}
                                        <TextField
                                          className={classes.textfield}
                                          fullWidth
                                          name="contactNumber"
                                          label="Contact Number*"
                                          value={values.contactNumber}
                                          // onChange={handleChange}
                                          onChange={(e) => {
                                            const value = e.target.value.replace(/\D/g, ''); // Use regex to allow only numbers
                                            handleChange({ target: { name: 'contactNumber', value: value.slice(0, 10) } }); // Limit input to 10 digits
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{ maxWidth: 600,'& .MuiFormLabel-root': {
                                                height: '20px'
                                              } }}
                                          inputProps={{
                                            maxLength: 10 // Limit input length to 10 characters
                                          }}
                                        />
                                        {touched.contactNumber && errors.contactNumber && (
                                          <Typography color="error">{errors.contactNumber}</Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {activeStep === 3 && (
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <Box component="form" sx={{ p: 2 }}>
                                      <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <Box
                                            sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
                                          >
                                            <Button
                                              variant="contained"
                                              sx={{
                                                minWidth: 140,
                                                marginRight: '10px',
                                                backgroundColor: 'white',
                                                color: 'black',
                                                justifyContent: 'flex-start',
                                                '&:hover': {
                                                  backgroundColor: 'white', // Keep the background color white
                                                  color: 'black', // Keep the text color black
                                                  pointerEvents: 'none' // Disable hover interaction
                                                }
                                              }}
                                              startIcon={<img src={YouTubeIcon} style={{ width: '28px', height: '18px' }} alt="YouTube" />}
                                            >
                                              Youtube
                                            </Button>
                                            <TextField
                                              name="youtubeProfile"
                                              onChange={handleChange}
                                              value={values.youtubeProfile}
                                              onBlur={handleBlur}
                                              fullWidth
                                              placeholder="https://youtube.com/@username"
                                              variant="outlined"
                                            />
                                          </Box>
                                          {touched.youtubeProfile && errors.youtubeProfile && (
                                            <Typography color="error">{errors.youtubeProfile}</Typography>
                                          )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                minWidth: 140,
                                                marginRight: '10px',
                                                backgroundColor: 'white',
                                                color: 'black',
                                                justifyContent: 'flex-start',
                                                '&:hover': {
                                                  backgroundColor: 'white', // Keep the background color white
                                                  color: 'black', // Keep the text color black
                                                  pointerEvents: 'none' // Disable hover interaction
                                                }
                                              }}
                                              startIcon={
                                                <img src={InstagramIcon} style={{ width: '22px', height: '22px' }} alt="Instagram" />
                                              }
                                            >
                                              Instagram
                                            </Button>
                                            <TextField
                                              name="instagramProfile"
                                              onChange={handleChange}
                                              value={values.instagramProfile}
                                              onBlur={handleBlur}
                                              fullWidth
                                              placeholder="https://instagram.com/@username"
                                              variant="outlined"
                                            />
                                          </Box>
                                          {touched.instagramProfile && errors.instagramProfile && (
                                            <Typography color="error">{errors.instagramProfile}</Typography>
                                          )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                minWidth: 140,
                                                marginRight: '10px',
                                                backgroundColor: 'white',
                                                color: 'black',
                                                justifyContent: 'flex-start',
                                                '&:hover': {
                                                  backgroundColor: 'white', // Keep the background color white
                                                  color: 'black', // Keep the text color black
                                                  pointerEvents: 'none' // Disable hover interaction
                                                }
                                              }}
                                              startIcon={<img src={FacebookIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                            >
                                              Facebook
                                            </Button>
                                            <TextField
                                              name="fbProfile"
                                              onChange={handleChange}
                                              value={values.fbProfile}
                                              onBlur={handleBlur}
                                              fullWidth
                                              placeholder="https://facebook.com/@username"
                                              variant="outlined"
                                            />
                                          </Box>
                                          {touched.fbProfile && errors.fbProfile && (
                                            <Typography color="error">{errors.fbProfile}</Typography>
                                          )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                minWidth: 140,
                                                marginRight: '10px',
                                                backgroundColor: 'white',
                                                color: 'black',
                                                justifyContent: 'flex-start',
                                                '&:hover': {
                                                  backgroundColor: 'white', // Keep the background color white
                                                  color: 'black', // Keep the text color black
                                                  pointerEvents: 'none' // Disable hover interaction
                                                }
                                              }}
                                              startIcon={<img src={TwitterIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                            >
                                              X
                                            </Button>
                                            <TextField
                                              name="xProfile"
                                              onChange={handleChange}
                                              value={values.xProfile}
                                              onBlur={handleBlur}
                                              fullWidth
                                              placeholder="https://x.com/@username"
                                              variant="outlined"
                                            />
                                          </Box>
                                          {touched.xProfile && errors.xProfile && <Typography color="error">{errors.xProfile}</Typography>}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                minWidth: 140,
                                                marginRight: '10px',
                                                backgroundColor: 'white',
                                                color: 'black',
                                                justifyContent: 'flex-start',
                                                '&:hover': {
                                                  backgroundColor: 'white', // Keep the background color white
                                                  color: 'black', // Keep the text color black
                                                  pointerEvents: 'none' // Disable hover interaction
                                                }
                                              }}
                                              startIcon={<img src={LinkedInIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                            >
                                              Linkedin
                                            </Button>
                                            <TextField
                                              name="linkedinProfile"
                                              onChange={handleChange}
                                              value={values.linkedinProfile}
                                              onBlur={handleBlur}
                                              fullWidth
                                              placeholder="https://linkedin.com/@username"
                                              variant="outlined"
                                            />
                                          </Box>
                                          {touched.linkedinProfile && errors.linkedinProfile && (
                                            <Typography color="error">{errors.linkedinProfile}</Typography>
                                          )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                minWidth: 140,
                                                marginRight: '10px',
                                                backgroundColor: 'white',
                                                color: 'black',
                                                justifyContent: 'flex-start',
                                                '&:hover': {
                                                  backgroundColor: 'white', // Keep the background color white
                                                  color: 'black', // Keep the text color black
                                                  pointerEvents: 'none' // Disable hover interaction
                                                }
                                              }}
                                              startIcon={<img src={SpotifyIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />}
                                            >
                                              Spotify
                                            </Button>
                                            <TextField
                                              name="spotifyProfile"
                                              onChange={handleChange}
                                              value={values.spotifyProfile}
                                              onBlur={handleBlur}
                                              fullWidth
                                              placeholder="https://spotify.com/@username"
                                              variant="outlined"
                                            />
                                          </Box>
                                          {touched.spotifyProfile && errors.spotifyProfile && (
                                            <Typography color="error">{errors.spotifyProfile}</Typography>
                                          )}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                                            <Button
                                              variant="contained"
                                              sx={{
                                                minWidth: 140,
                                                marginRight: '10px',
                                                backgroundColor: 'white',
                                                color: 'black',
                                                justifyContent: 'flex-start',
                                                '&:hover': {
                                                  backgroundColor: 'white', // Keep the background color white
                                                  color: 'black', // Keep the text color black
                                                  pointerEvents: 'none' // Disable hover interaction
                                                }
                                              }}
                                              startIcon={
                                                <img src={SoundCloudIcon} style={{ width: '22px', height: '22px' }} alt="YouTube" />
                                              }
                                            >
                                              Soundcloud
                                            </Button>
                                            <TextField
                                              name="soundcloudProfile"
                                              onChange={handleChange}
                                              value={values.soundcloudProfile}
                                              onBlur={handleBlur}
                                              fullWidth
                                              placeholder="https://soundcloud.com/@username"
                                              variant="outlined"
                                            />
                                          </Box>
                                          {touched.soundcloudProfile && errors.soundcloudProfile && (
                                            <Typography color="error">{errors.soundcloudProfile}</Typography>
                                          )}
                                        </Grid>
                                      </Grid>
                                    </Box>
                                    {/* Display the general error message for all social profiles */}
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                      {/* Check if the custom error message exists */}
                                      {errors.socialProfiles && (
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                          <Typography color="error">{errors.socialProfiles}</Typography>
                                        </Box>
                                      )}
                                    </Grid>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {activeStep === 4 && (
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                        width: '100%',
                                        display: 'flex'
                                      }}
                                    >
                                      <Box component="form" noValidate autoComplete="off" sx={{ width: '50%' }}>
                                        <TextField
                                          className={classes.textfield}
                                          id="outlined-multiline-static"
                                          multiline
                                          maxRows={8}
                                          fullWidth
                                          name="biography"
                                          label="Biography*"
                                          value={values.biography}
                                          onChange={(e) => {
                                            const trimmedValue = e.target.value.trimStart();
                                            const sanitizedValue = trimmedValue
                                              .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
                                              .replace(/<\s*\/?\s*>/g, ''); // Remove empty tags
                                            handleChange({ target: { name: 'biography', value: sanitizedValue } });
                                          }}
                                          onBlur={handleBlur}
                                          margin="normal"
                                          sx={{ maxWidth: 600, height: 200,'& .MuiFormLabel-root': {
                      height: '20px',
                      } }}
                                          inputProps={{
                                            maxLength: 1000 // Setting maximum character limit
                                          }}
                                        />

                                        {touched.biography && errors.biography && <Typography color="error">{errors.biography}</Typography>}
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {activeStep === 5 && (
                            <Grid container spacing={2} justifyContent="center" alignItems="center">
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <FormControl component="fieldset" sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                                      <Grid container spacing={2} flexDirection="column" alignItems="center" justifyContent="center">
                                        <Grid item xs={6} sm={4} md={3} style={{ paddingTop: 20 }}>
                                          <Box>
                                            <FieldArray name="performanceType">
                                              {({ push, remove }) => (
                                                <div>
                                                  {PerformanceTypeList.map((option) => (
                                                    <div key={option.value}>
                                                      <Field
                                                        type="checkbox"
                                                        name="performanceType"
                                                        id={`performanceType-${option.value}`} // Unique id for each checkbox
                                                        value={option.value}
                                                        onBlur={handleBlur}
                                                        checked={values.performanceType && values.performanceType.includes(option.value)}
                                                        onChange={(e) => {
                                                          if (e.target.checked) {
                                                            push(option.value);
                                                          } else {
                                                            const index = values.performanceType.indexOf(option.value);
                                                            remove(index);
                                                          }
                                                        }}
                                                        style={{ width: '16px', height: '16px' }}
                                                      />
                                                      <label
                                                        style={{ fontSize: '1rem', marginLeft: '8px' }}
                                                        htmlFor={`performanceType-${option.value}`}
                                                      >
                                                        {option.label}
                                                      </label>
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                            </FieldArray>
                                          </Box>
                                        </Grid>
                                      </Grid>
                                      {touched.performanceType && errors.performanceType && (
                                        <Typography color="error">{errors.performanceType}</Typography>
                                      )}
                                    </FormControl>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                          {activeStep === 6 && (
                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                              <Grid item xs={12} sm={12} md={12}>
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                        width: '100%',
                                        display: 'flex'
                                      }}
                                    >
                                      <Box
                                        // sx={{ mt: 1 }}
                                        component="form"
                                        noValidate
                                        autoComplete="off"
                                        sx={{
                                          width: {
                                            xs: '90%', // Width for extra-small screens
                                            sm: '90%' // Width for small and larger screens
                                          },
                                          padding: '10px'
                                        }}
                                      >
                                        <TextField
                                          id="youtube-url"
                                          name="youtubeUrl"
                                          label="YouTube Video  Embed URL*"
                                          variant="outlined"
                                          fullWidth
                                          value={values.youtubeUrl}
                                          onChange={(event) => handleUrlChange(event, setFieldValue)}
                                          error={!!errorMessage}
                                          helperText={errorMessage}
                                          onBlur={handleBlur}
                                          sx={{
                                            
                                            '& .MuiFormLabel-root': {
                                              height: '20px'
                                            }
                                            }}
                                        />
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                          {/* Check if the custom error message exists */}
                                          <Box display="flex" justifyContent="center" alignItems="center">
                                            {touched.youtubeUrl && errors.youtubeUrl && (
                                              <Typography color="error">{errors.youtubeUrl}</Typography>
                                            )}
                                          </Box>
                                        </Grid>
                                        {/* {touched.youtubeUrl && errors.youtubeUrl && <Typography color="error">{errors.youtubeUrl}</Typography>} */}
                                        {embedUrl && (
                                          <Box sx={{ mt: 1, display: 'flex', justifyContent: 'center' }}>
                                            <iframe
                                              width="800"
                                              height="350"
                                              src={embedUrl}
                                              frameBorder="0"
                                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                              allowFullScreen
                                              title="Embedded YouTube Video"
                                            ></iframe>
                                          </Box>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                          )}
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                          <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                            {/* <Button
                        className={activeStep === 0 ? classes.disabledButton : classes.backButton}
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      > */}
                            Back
                          </Button>
                          <Box sx={{ flex: '1 1 auto' }} />
                          <Button
                            // className={Object.keys(errors).length > 0 ? classes.disabledButton : classes.button}\
                            className={classes.button}
                            onClick={async () => {
                              if (activeStep === steps.length - 1) {
                                handleSubmit(); // Submit the form on the last step
                                setFieldValue("submitFlag", "submitArtist") // For final submit key
                              } else {
                                await handleNext(); // Validate and go to next step
                              }
                            }}
                            type="button"
                            // disabled={Object.keys(errors).length > 0}
                          >
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
}

const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    // borderRadius: 70,
    // border: 'solid 2px #7900ff', // Solid border with a specific color
    // minWidth: '80px',
    color: '#fff'
  },
  // disabledButton: {
  //   background: '#00101b', // A dark grey background to indicate disabled state
  //   color: '#fff', // Text color white
  //   borderRadius: 70,
  //   minWidth: '80px',
  //   border: 'solid 2px #7900ff', // Solid border with a specific color
  //   opacity: 0.6, // Make it semi-transparent to further indicate it's disabled
  //   cursor: 'not-allowed', // Show a 'not-allowed' cursor when hovering
  //   pointerEvents: 'none' // Disable interactions with the button
  // },
  // backButton: {
  //   borderRadius: 70,
  //   minWidth: '80px'
  // },
  innerBorderBox: {
    backgroundColor: '#00101b', // Inner box background color (usually white)
    borderRadius: '10px', // Match the border radius
    width: '100%' // Ensures full-width responsiveness
  },
  outerBorderBox: {
    padding: '2px', // Small padding for the gradient border
    background: 'linear-gradient(to right, #ff0093, #7900ff)', // Gradient background
    borderRadius: '10px', // Rounded corners
    width: '100%', // Ensures full-width responsiveness
    display: 'inline-block'
  },
  date: {
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'none',
    marginBottom: 20
  },
  textfield: {
    backgroundColor: '#fff',
    borderRadius: 5,
    '& .MuiFormLabel-root': {
      height: '20px'
    }
  },
  stepper: {
    marginBottom: '50px',
    fontSize: {
      xs: '0.8rem', // Extra-small devices
      sm: '1rem', // Small devices
      md: '1.2rem', // Medium devices
      lg: '1.5rem' // Large devices
    },
    '& .MuiStepLabel-root': {
      color: 'gray', // Default text color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed step color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active step color
      }
    },
    '& .MuiStepLabel-label': {
      color: '#fff', // Default text color
      '&.Mui-completed': {
        color: 'grey' // Completed step color
      },
      '&.Mui-active': {
        color: '#fff' // Active step color
      }
    },
    '& .MuiStepIcon-root': {
      color: 'gray', // Default icon color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed icon color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active icon color
      }
    }
  }
}));
