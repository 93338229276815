import * as React from 'react';
import { Grid, CardMedia, Typography, Box, Link, IconButton, Chip, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useState, useEffect } from 'react';
import makeApiCall from 'common/makeApiCall';
import profile from '../../assets/images/users/profile.png';
import { useNavigate } from 'react-router-dom';
import YouTubeIcon from '../../assets/icons/youtube.png';
import InstagramIcon from '../../assets/icons/instagram.png';
import FacebookIcon from '../../assets/icons/facebook.png';
import TwitterIcon from '../../assets/icons/twitter.png';
import LinkedInIcon from '../../assets/icons/linkedin.png';
import SpotifyIcon from '../../assets/icons/spotify.png';
import SoundCloudIcon from '../../assets/icons/soundcloud.png';
import YouTube from 'pages/components-overview/YouTube';

export default function ArtistrepertoireMyProfile() {
  const [artistRepertoire, setArtist] = useState(null);
  const [error, setError] = useState(null);
  let navigate = useNavigate();

  // ===================|EVENT DETAILS API CALL WITH SINGLE ID|========================//
  useEffect(() => {
    const fetchArtist = async () => {
      try {
        const artistData = await makeApiCall('get', `/artistrepertoire/myprofile`);
        setArtist(artistData);
        console.log('res', artistData);
      } catch (error) {
        // setError(error);
        setError('');
        navigate('/dashboard/create-artists-repertoire');
      }
    };
    fetchArtist();
  }, []);

  if (error) {
    return <Typography color="error">Failed to load artist Repertoire details. Please try again later.</Typography>;
  }
  if (!artistRepertoire) {
    return <Typography>Loading...</Typography>;
  }

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const handleEditClick = () => {
    localStorage.setItem('artistRepertoireUpdateData', JSON.stringify(artistRepertoire));
    navigate('/dashboard/create-artists-repertoire');
  };

  // Define social profiles with icons
  const socialProfiles = [
    { name: 'YouTube', icon: <img src={YouTubeIcon} width="22" height="18" alt="YouTubeIcon" />, url: artistRepertoire.youtubeProfile },
    {
      name: 'Instagram',
      icon: <img src={InstagramIcon} width="21" height="20" alt="InstagramIcon" />,
      url: artistRepertoire.instagramProfile
    },
    { name: 'Facebook', icon: <img src={FacebookIcon} width="20" height="20" alt="FacebookIcon" />, url: artistRepertoire.fbProfile },
    { name: 'Twitter', icon: <img src={TwitterIcon} width="20" height="18" alt="TwitterIcon" />, url: artistRepertoire.xProfile },
    { name: 'LinkedIn', icon: <img src={LinkedInIcon} width="22" height="18" alt="LinkedInIcon" />, url: artistRepertoire.linkedinProfile },
    { name: 'Spotify', icon: <img src={SpotifyIcon} width="22" height="22" alt="SpotifyIcon" />, url: artistRepertoire.spotifyProfile },
    {
      name: 'SoundCloud',
      icon: <img src={SoundCloudIcon} width="22" height="18" alt="SoundCloudIcon" />,
      url: artistRepertoire.soundcloudProfile
    }
  ];
  const filteredSocialProfiles = socialProfiles.filter((profile) => profile.url && profile.url.trim() !== '');

  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Artist Image and Basic Info */}
      <Grid
        item
        xs={12}
        md={6}
        sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: { xs: 'center', md: 'left' } }}
      >
        {/* Profile Image and Basic Info */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { md: '180%', xs: '100%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 151, height: 151, objectFit: 'cover', borderRadius: 3, mb: 2 }}
            image={profile}
            alt="profile"
          />
          <Box sx={{ ml: 2, textAlign: { xs: 'left', md: 'left' }, width: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-start' }, mb: 1 }}>
              <Typography variant="h3">{capitalizeFirstLetter(artistRepertoire.fullName)}</Typography>
              <IconButton
                sx={{
                  ml: 1,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '50%'
                }}
                onClick={handleEditClick}
              >
                <EditIcon />
              </IconButton>
            </Box>
            <Typography variant="subtitle1">
              {artistRepertoire.city}, {artistRepertoire.stateLabel}, {artistRepertoire.countryLabel}
            </Typography>
            <Typography variant="body1">{artistRepertoire.email}</Typography>
            <Link href={artistRepertoire.website} target="_blank">
              {artistRepertoire.website}
            </Link>
            <Typography variant="body1">{artistRepertoire.openForWork}</Typography>
            <Typography variant="body1">{artistRepertoire.phone}</Typography>
          </Box>
        </Box>
        {/* Genre section */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Genre
          </Typography>

          <Stack
            sx={{
              ml: 'auto',
              cursor: 'pointer',
              flexWrap: 'wrap',
              gap: 1
            }}
            direction="row"
          >
            {artistRepertoire.genreLabels.map((genre, index) => (
              <Chip
                key={index}
                sx={{
                  borderRadius: 4,
                  borderColor: '#fff',
                  color: '#fff',
                  minWidth: '80px' // optional, adjust as needed
                }}
                label={genre}
                color="success"
                variant="outlined"
              />
            ))}
          </Stack>
        </Box>

        {/* Social Profiles */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Social Links
          </Typography>

          <Grid container spacing={1}>
            {filteredSocialProfiles.map((profile, index) => (
              <Grid item key={index}>
                <Chip
                  icon={profile.icon}
                  label={profile.name}
                  onClick={() => window.open(profile.url, '_blank')}
                  sx={{
                    borderRadius: 4,
                    p: 0.5,
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: 'white',
                      cursor: 'default'
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Social Profiles */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Professional Bio
          </Typography>

          <Grid item xs={12} sx={{ textAlign: { xs: 'start', md: 'start' } }}>
            <Typography variant="h5" gutterBottom>
              Company Name: {artistRepertoire.company}{' '}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: { xs: 'start', md: 'start' } }}>
            <Typography variant="h5">Biography: </Typography>
            <Box sx={{  textAlign: 'justify' }}>
              <Typography variant="body1" sx={{textIndent: '80px'}}>{artistRepertoire.biography}</Typography>
            </Box>
          </Grid>
        </Box>

        {/* Performance Types */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Performance Type
          </Typography>

          <Stack sx={{ ml: 'auto', cursor: 'pointer', flexWrap: 'wrap', gap: 1 }} direction="row">
            {artistRepertoire.performanceTypeLabels.map((value, index) => (
              <Chip
                key={index}
                sx={{
                  borderRadius: 4,
                  borderColor: '#fff',
                  color: '#fff',
                  minWidth: '80px'
                  // optional, adjust as needed
                }}
                label={value}
                variant="outlined"
              />
            ))}
          </Stack>
        </Box>
{/* YouTube Video */}
        {/* Use the youtube component */}
        <YouTube artist={artistRepertoire} />


      </Grid>
    </Grid>
  );
}
