import React from 'react';
import { Typography, Box, styled, Grid } from '@mui/material';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <StyledFooter container spacing={0}>
            <Grid item xs={12}>
                <Box sx={{ display: 'flex', justifyContent: 'center', padding: 3 }}>
                    <Typography variant="h6" color="common.white">
                        © {year} Copyright All Rights Reserved.
                    </Typography>
                </Box>
            </Grid>
        </StyledFooter>
    );
};

export default Footer;

const StyledFooter = styled(Box)({
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    height: '160px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center",
    marginTop: "100px"
});
