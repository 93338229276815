import React from 'react';
import { Box } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

 // Make sure the path is correct

// Functional component to display the artist's profile details
const YouTube = ({ artist}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  return(
    <>
    {isMobile ? (
    // Mobile View
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        paddingTop: '56.25%', // 16:9 aspect ratio
        borderRadius: 8,
      }}
    >
      <iframe
        src={artist.youtubeUrl}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
        style={{
          position: 'absolute',
          borderRadius: 10,
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          border: 0,
        }}
      ></iframe>
    </Box>
  ) : (
    // Web View
    <Box
      sx={{
        borderRadius: 2,
        boxShadow: 3,
        backgroundColor: '#616161',
        color: '#fff',
        width: { xs: '100%', md: '180%' },
        mb: { xs: 1, md: 2 },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          position: 'relative',
          width: '60%',
          paddingTop: '27.6%',
          // overflow: 'hidden',
        }}
      >
        <iframe
          src={artist.youtubeUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            border: 0,
          }}
        ></iframe>
      </Box>
    </Box>
    
  )}</>
)
}
  
export default YouTube;