import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  ButtonBase,
  CardContent,
  ClickAwayListener,
  Button,
  Grid,
  IconButton,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
import { useNavigate } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
// assets
import avatar1 from 'assets/images/users/avatar-1.png';

function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  // let token = localStorage.getItem("token");
  let userName = localStorage.getItem('userName');
  let userType = localStorage.getItem('userType');

  // Mapping of userType codes to their full names
  const userTypeFullNames = {
    AR: 'Artist',
    ORG: 'Organizer',
    AMP: 'Allied Music Professionals',
    ATR: 'Artist & Repertoire',
    AD: 'Admin'
  };

  // Get the full name based on userType code
  const userTypeFullName = userTypeFullNames[userType] || 'Unknown';
  const handleLogout = async () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userName');
    localStorage.removeItem('userType');

    // localStorage.removeItem("userType");

    // Conditional navigation based on token presence:
    if (!localStorage.getItem('token')) {
      navigate('/login'); // Redirect to home if token is removed
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  // const handleProfileView = () => {
  //   setOpen(false);
  //   navigate(userType=='AR'?'/artist-my-profile/1':'');
  // };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <>
    {
      userType && 
    
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
          <Typography variant="subtitle1">{userName ?userName.toUpperCase():null}</Typography>
          <Avatar alt="profile user" src={avatar1} sx={{ width: 35, height: 35 }} />
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="fade" in={open} {...TransitionProps}>
            {open && (
              <Paper
                sx={{
                  borderRadius: 2,
                  boxShadow: theme.customShadows.z1,
                  width: 290,
                  minWidth: 240,
                  maxWidth: 290,
                  [theme.breakpoints.down('md')]: {
                    maxWidth: 250
                  }
                }}
              >
                <ClickAwayListener onClickAway={handleClose}>
                  <MainCard sx={{ borderRadius: 2 }} elevation={0} border={false} content={false}>
                    <CardContent sx={{ px: 2.5, pt: 3 }}>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Stack direction="row" spacing={2} alignItems="center">
                            <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} />
                            <Stack>
                              <Typography variant="h5">{userName}</Typography>
                              <Typography variant="body2" color="textSecondary">
                                {userTypeFullName}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item>
                          <IconButton size="large" color="secondary" onClick={handleLogout}>
                            <LogoutOutlined />
                          </IconButton>
                        </Grid>
                      </Grid>
                      {(userType === 'AR' || userType === 'AMP' || userType === 'ATR') && (
                        // <Link target="" to={`/dashboard/artist-my-profile/${2}`} style={{ textDecoration: 'none' }}>
                        <Button
                          variant="outlined"
                          fullWidth
                          style={{ borderRadius: '50px', maxHeight: '30px' }}
                          onClick={() => {
                            if (userType === 'AR'){
                              window.location.href = `/dashboard/artist-my-profile`;
                            }else if (userType === 'AMP'){
                              window.location.href = `/dashboard/amp-my-profile`;
                            } else if (userType === 'ATR'){
                              window.location.href = `/dashboard/atr-my-profile`;
                            }
                            
                            setOpen(false);
                          }}
                        >
                          View Profile
                        </Button>
                        // </Link>
                      )}
                    </CardContent>

                    {/* {open && (
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                          <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Profile"
                              {...a11yProps(0)}
                            />
                            <Tab
                              sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                textTransform: 'capitalize'
                              }}
                              icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                              label="Setting"
                              {...a11yProps(1)}
                            />
                          </Tabs>
                        </Box>
                        <TabPanel value={value} index={0} dir={theme.direction}>
                          <ProfileTab handleLogout={handleLogout} />
                        </TabPanel>
                        <TabPanel value={value} index={1} dir={theme.direction}>
                          <SettingTab />
                        </TabPanel>
                      </>
                    )} */}
                  </MainCard>
                </ClickAwayListener>
              </Paper>
            )}
          </Transitions>
        )}
      </Popper>
    </Box>
}
    </>
  );
};

export default Profile;
