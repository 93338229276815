import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Grid,
  TextField,
  MenuItem,
  Switch,
  FormGroup,
  Autocomplete,
  Chip
} from '@mui/material';

// Date Picker MUI----------------------->
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { makeStyles } from '@mui/styles';
import { Formik } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import makeApiCall from 'common/makeApiCall';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Country } from 'data/Country';
import { states } from 'data/State';
import AdminAuthRegister from 'pages/admin/AdminAuthRegister';
const steps = ['Event Type *', 'Event Description *', 'Event Date *', 'Event Artists *', 'Event Location *'];
const PaymentType = [
  {
    id: 'PD',
    name: 'Per Day'
  },
  {
    id: 'PH',
    name: 'Per Hour'
  },
  {
    id: 'PS',
    name: 'Per Song'
  }
];
// This regex pattern validates full Codes
const fullPlusCodeRegex = /^[A-Z0-9]{8}\+[A-Z0-9]{2}$/;

const validationSchemaStep1 = Yup.object().shape({
  eventType: Yup.string().required('Event Type is required'),
  paidStatus: Yup.string().test('all-fields-filled', 'Please provide values for all fields.', function () {
    const { currencyType, amount, amountBasis, paymentStatus } = this.parent;
    // If paymentStatus is 'P', check if all required fields have values
    if (paymentStatus === 'P') {
      return currencyType && amount && amountBasis; // Return true if all fields are filled, false otherwise
    }
    return true; // If paymentStatus is not 'P', validation passes without checking the fields
  })
});

const validationSchemaStep2 = Yup.object({
  title: Yup.string().required('Title is required').max(100, 'Maximum 100 characters allowed'),
  description: Yup.string().required('Description is required')
});
const validationSchemaStep3 = Yup.object({
  eventStartDate: Yup.date().nullable().required('Event From Date is required'),
  eventEndDate: Yup.date()
    .nullable()
    .min(Yup.ref('eventStartDate'), 'To date must be later than from date')
    .required('Event To Date is required'),
  eventStartTime: Yup.date().nullable().required('Event Start Time is required'),
  eventEndTime: Yup.date().nullable().required('Event End Time is required')
});
const validationSchemaStep4 = Yup.object({
  // artistsList: Yup.string().required('Artists List is required'),
  artistDescription: Yup.string().required('Artist Description is required')
});
const validationSchemaStep5 = Yup.object({
  country: Yup.string().required('Country is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  contactNumber: Yup.string().required('Contact Number is required'),

  location: Yup.string()
  .required('Google Plus Code with the Area Code is required')
  .matches(fullPlusCodeRegex, 'Invalid Plus Code format. Please enter a Google Plus Code that includes the Area Code.')
});

const currencies = [
  {
    value: 'USD',
    label: '$'
  },
  {
    value: 'INR',
    label: '₹'
  }
];

export default function Eventcreate() {
  const userType = localStorage.getItem('userType');
  const { id } = useParams();
  const formikRef = useRef(null); // Ref for Formik instance

  let navigate = useNavigate();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [eventDetails, setEventDetails] = useState(0);
  const [events, setEvent] = useState(null);
  const [allArtistsList, setAllArtistList] = useState([]);
  const [selectedArtist, setSelectedArtist] = useState();
  const [openProfileCreate, setOpenProfileCreate] = useState(false);
  const [userIdFromAdmin, setUserIdFromAdmin] = useState('');
  // ------/ set created id or existing id /-------
  const eventId = id || eventDetails.id;

  useEffect(() => {
    const artistKeyValue = [];
    const artistIds = Array.isArray(events?.artistIds) ? events?.artistIds : events?.artistIds?.split(',');
    artistIds?.forEach((id) => {
      const artist = allArtistsList.find((artistData) => artistData.key === id);
      if (artist) {
        artistKeyValue.push({ key: artist.key, value: artist.value });
      }
    });
    setSelectedArtist(artistKeyValue);
  }, [events, allArtistsList]);

  const handleAutocompleteChange = (event, newValue) => {
    setSelectedArtist(newValue);
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [selectedCountry, setSelectedCountry] = useState('');
  const [filteredStates, setFilteredStates] = useState([]);

  const handleCountryChange = (countryId) => {
    setSelectedCountry(countryId);
    setFilteredStates(states[countryId] || []);
  };

  const fetchEvent = async () => {
    try {
      const eventData = await makeApiCall('get', `/event/detail/${eventId}`);
      setEvent(eventData);
      handleCountryChange(eventData.country);
    } catch (error) {
      console.error('Error fetching event data:', error);
    }
  };

  const fetchArtistsList = async () => {
    try {
      const artistData = await makeApiCall('post', `/artist/availableartistslist`, {});
      setAllArtistList(artistData?.data);
    } catch (error) {
      // setError(error);
    }
  };

  useEffect(() => {
    fetchArtistsList();
    if (eventId) {
      fetchEvent();
      setActiveStep(0);
    } else {
      setActiveStep(0);
      setSelectedCountry('');
      setFilteredStates(states['0']);
      formikRef.current.resetForm({
        values: {
          eventType: '',
          eventStartDate: null,
          eventEndDate: null,
          eventStartTime: null,
          eventEndTime: null,
          title: '',
          state: '',
          city: '',
          artistDescription: '',
          contactNumber: '',
          description: '',
          location: '',
          paymentStatus: 'P',
          currencyType: '',
          amount: '',
          amountBasis: 'PH'
        },
        touched: {}, // Clear touched fields
        errors: {} // Clear errors
      });
    }
  }, [id]);

  const validationSchema =
    activeStep === 0
      ? validationSchemaStep1
      : activeStep === 1
        ? validationSchemaStep2
        : activeStep === 2
          ? validationSchemaStep3
          : activeStep === 3
            ? validationSchemaStep4
            : activeStep === 4
              ? validationSchemaStep5
              : null;

  const formattedTime = (time) => {
    return dayjs(`2022-04-17 ${time}`, 'YYYY-MM-DD hh:mm A').format('YYYY-MM-DDTHH:mm');
  };

  const formatDate = (date) => {
    return date?.split('-').reverse().join('-');
  };

  const formattedStartDate = formatDate(events?.eventStartDate);
  const formattedEndDate = formatDate(events?.eventEndDate);

  const handleRegisterSuccess = (userId) => {
    setUserIdFromAdmin(userId);
    setOpenProfileCreate(true); // Set state to true once registration is successful
  };

  return (
    <Formik
      initialValues={{
        id: events?.id || '',
        eventType: events?.eventType || '',
        eventStartDate: events?.eventStartDate ? dayjs(formattedStartDate) : null, // Convert to Day.js object
        eventEndDate: events?.eventEndDate ? dayjs(formattedEndDate) : null, // Convert to Day.js object
        eventStartTime: events?.eventStartTime ? dayjs(formattedTime(events?.eventStartTime)) : null, // Convert to Day.js object
        eventEndTime: events?.eventEndTime ? dayjs(formattedTime(events?.eventEndTime)) : null, // Convert to Day.js object
        title: events?.title ? events?.title : '',
        country: events?.country ? events?.country : '',
        state: events?.state ? events?.state : '',
        city: events?.city || '',
        contactNumber: events?.contactNumber || '',
        description: events?.description || '',
        location: events?.location || '',
        paymentStatus: events?.paymentStatus || '',
        currencyType: events?.currencyType || '',
        amount: events?.amount || '',
        artistDescription: events?.artistDescription || '',
        amountBasis: events?.amountBasis || 'PH'
      }}
      enableReinitialize={id && activeStep === 0 ? true : false}
      validationSchema={validationSchema}
      innerRef={formikRef}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        if (activeStep === 0) {
          const errors = await validateForm();
          if (Object.keys(errors).length > 0) {
            setErrors(errors);
            setSubmitting(false);
            return;
          }
        }
        try {
          setSubmitting(true); // Start form submission
          const formattedStartTime = values.eventStartTime ? dayjs(values.eventStartTime).format('hh:mm A') : '';
          const formattedEndTime = values.eventEndTime ? dayjs(values.eventEndTime).format('hh:mm A') : '';
          const stateName = filteredStates.find((state) => state.id === values.state)?.id || '';

          const artistEndPoint = eventId ? `/event/eventupdate/${eventId}` : '/event/create';
          const artistmethod = eventId ? 'put' : 'post';
          const artistIds = selectedArtist
            .map((artistData) => {
              const selectedArtistIds = Array.isArray(artistData.key)
                ? artistData.key.join(',') // Convert array to comma-separated string
                : String(artistData.key); // Convert other data types to string if necessary
              return selectedArtistIds;
            })
            .join(','); // Join all selectedArtistIds into a single string

          const responseData = await makeApiCall(
            artistmethod,
            artistEndPoint,
            {
              userId: userIdFromAdmin,
              eventType: values.eventType,
              eventStartDate: values.eventStartDate,
              eventEndDate: values.eventEndDate,
              eventStartTime: formattedStartTime,
              eventEndTime: formattedEndTime,
              title: values.title,
              state: stateName,
              city: values.city,
              country: values.country,
              contactNumber: values.contactNumber,
              description: values.description,
              location: values.location,
              paymentStatus: values.paymentStatus,
              currencyType: values.currencyType,
              amount: values.amount,
              amountBasis: values.amountBasis,
              artistIds: artistIds,
              artistDescription: values.artistDescription,
              eventTypeAdded:activeStep && activeStep>0?'Y':'N',
              eventDescriptionAdded:activeStep && activeStep>1?'Y':'N',
              eventDateAdded:activeStep && activeStep>2?'Y':'N',
              eventArtistsAdded:activeStep && activeStep>3?'Y':'N',
              eventLocationAdded:values.submitFlag==="submitEvent"?'Y':'N',
              eventApplicationStatus:values.submitFlag==="submitEvent"?'CP':'IP',
            },
            activeStep === 4 && values.submitFlag==="submitEvent"?'Event create successful':null,
            'Event create failed'
          );
          console.log("values123:", values);
          
          setStatus({ success: true });
          setSubmitting(false);
          // resetForm();
          console.log('responseData', responseData);
          setEventDetails(responseData?.event)
          if (activeStep === 4 && values.submitFlag==="submitEvent") {
            navigate('/dashboard/eventlist');
          }
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        // isSubmitting,
        validateForm,
        setTouched
        // isValid,
        // dirty
      }) => {

        const handleNext = async () => {
          const errors = await validateForm(); // Validate the current form step
          if (Object.keys(errors).length === 0) {
            setActiveStep((prevStep) => prevStep + 1); // Move to the next step
            handleSubmit();
          } else {
            setTouched(errors); // Mark fields as touched if there are errors
          }
        };
        return (
          <>
            {userType === 'AD' && !openProfileCreate ? (
              <AdminAuthRegister adminUserType={'ORGAdmin'} onSuccess={handleRegisterSuccess} />
            ) : (
              <Box sx={{ width: '100%' }}>
                <Stepper className={classes.stepper} activeStep={activeStep}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <Box sx={{ mt: 2, mb: 1 }}>
                  {activeStep === steps.length ? (
                    <React.Fragment>
                      <Typography>All steps completed - you are finished</Typography>
                      <Button onClick={handleReset}>Reset</Button>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Box sx={{ mt: 2, mb: 1 }}>
                        {activeStep === 0 && (
                          <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12} textAlign="center">
                              <Box className={classes.outerBorderBox}>
                                <Box className={classes.innerBorderBox}>
                                  <FormControl component="fieldset">
                                    <RadioGroup name="eventType" value={values.eventType} onChange={handleChange}>
                                      <FormControlLabel value="OM" control={<Radio />} label="Open Mic" />
                                      <FormControlLabel value="LG" control={<Radio />} label="Live Gig" />
                                      <FormControlLabel value="MF" control={<Radio />} label="Music Festival" />
                                    </RadioGroup>
                                    {touched.eventType && errors.eventType && <Typography color="error">{errors.eventType}</Typography>}
                                  </FormControl>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                              <Box className={classes.outerBorderBox}>
                                <Box className={classes.innerBorderBox}>
                                  {/* <FormControlLabel control={<Switch checked={isPaid} onChange={handlePaidSwitch}  size="large" />} label="Paid" /> */}
                                  <FormControlLabel
                                    sx={{ display: 'flex', justifyContent: 'center' }}
                                    control={
                                      <Switch
                                        checked={values?.paymentStatus === 'P'}
                                        onChange={(event) => {
                                          setFieldValue('paymentStatus', event.target.checked ? 'P' : 'UP');
                                        }}
                                        color="primary"
                                      />
                                    }
                                    label="Paid"
                                  />

                                  {values?.paymentStatus === 'P' && (
                                    <Grid container spacing={2} justifyContent="center" alignItems="center">
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          // width: '100%',
                                          display: 'flex'
                                        }}
                                      >
                                        <TextField
                                          sx={{ maxWidth: 300 }}
                                          id="outlined-select-currency"
                                          select
                                          label="CUR"
                                          name="currencyType"
                                          value={values.currencyType}
                                          onChange={handleChange}
                                          fullWidth
                                        >
                                          {currencies.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                              {option.label}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          //  width: '100%',
                                          display: 'flex'
                                        }}
                                      >
                                        <TextField
                                          margin="dense"
                                          id="outlined-basic"
                                          variant="outlined"
                                          name="amount"
                                          label="Amount"
                                          sx={{ maxWidth: 300 }}
                                          value={values.amount}
                                          onChange={(e) => {
                                            // const value = e.target.value.replace(/\D/g, ''); // Use regex to allow only numbers
                                            const re = /^[0-9]\d*(\.\d{0,2})?$/;
                                            if (re.test(e.target.value) || e.target.value === '') {
                                              handleChange(e);
                                            }
                                            // handleChange({ target: { name: 'amount', value: value.slice(0, 10) } }); // Limit input to 10 digits
                                          }}
                                          inputProps={{
                                            maxLength: 13 // Limit input length to 10 characters
                                          }}
                                          fullWidth
                                        />
                                        {touched.amount && errors.amount && <Typography color="error">{errors.amount}</Typography>}
                                      </Grid>
                                      <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          // width: '100%',
                                          display: 'flex'
                                        }}
                                      >
                                        <TextField
                                          margin="dense"
                                          id="amountBasis"
                                          label="Amount Basis"
                                          select
                                          fullWidth
                                          name="amountBasis"
                                          sx={{ maxWidth: 300 }}
                                          onBlur={handleBlur}
                                          value={values.amountBasis}
                                          // sx={{ maxWidth: '100%' }}
                                          onChange={handleChange}
                                        >
                                          {PaymentType.map((option) => (
                                            <MenuItem key={option.id} value={option.id}>
                                              {option.name}
                                            </MenuItem>
                                          ))}
                                        </TextField>
                                        {/* Check if the custom error message exists */}
                                      </Grid>
                                      {errors.paidStatus && (
                                        <Box display="flex" justifyContent="center" alignItems="center">
                                          <Typography color="error">{errors.paidStatus}</Typography>
                                        </Box>
                                      )}
                                    </Grid>
                                  )}
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                        {activeStep === 1 && (
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Box component="form" noValidate autoComplete="off">
                                <Grid>
                                  <Box
                                    className={classes.outerBorderBox}
                                    sx={{ mb: 2 }}
                                    // Add margin-bottom to create a gap
                                  >
                                    <Box className={classes.innerBorderBox}>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          width: '100%',
                                          display: 'flex'
                                        }}
                                      >
                                        <Box
                                          component="form"
                                          noValidate
                                          autoComplete="off"
                                          sx={{
                                            width: {
                                              xs: '90%', // Width for extra-small screens
                                              sm: '50%' // Width for small and larger screens
                                            }
                                          }}
                                        >
                                          <TextField
                                            className={classes.textfield}
                                            fullWidth
                                            name="title"
                                            label="Title"
                                            value={values.title}
                                            onChange={(e) => {
                                              const trimmedValue = e.target.value.trimStart();
                                              let sanitizedValue = trimmedValue
                                                .replace(/<[^>]*>/g, '') // Remove HTML tags
                                                .replace(/[^a-zA-Z0-9\s\-.]/g, ''); // Allow letters, numbers, spaces, hyphens, and dots
                                              handleChange({ target: { name: 'title', value: sanitizedValue } });
                                            }}
                                            onBlur={handleBlur}
                                            margin="normal"
                                            sx={{
                                              maxWidth: 600,
                                              '& .MuiFormLabel-root': {
                                                height: '20px'
                                              }
                                            }}
                                          />
                                          {touched.title && errors.title && <Typography color="error">{errors.title}</Typography>}
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Grid>
                                <Grid>
                                  <Box className={classes.outerBorderBox}>
                                    <Box className={classes.innerBorderBox}>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          width: '100%',
                                          display: 'flex'
                                        }}
                                      >
                                        <Box
                                          component="form"
                                          noValidate
                                          autoComplete="off"
                                          sx={{
                                            width: {
                                              xs: '90%', // Width for extra-small screens
                                              sm: '50%' // Width for small and larger screens
                                            }
                                          }}
                                        >
                                          <TextField
                                            className={classes.textfield}
                                            id="outlined-multiline-flexible"
                                            multiline
                                            maxRows={8}
                                            fullWidth
                                            name="description"
                                            label="Description"
                                            value={values.description}
                                            onChange={(e) => {
                                              const trimmedValue = e.target.value.trimStart();
                                              const sanitizedValue = trimmedValue
                                                .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
                                                .replace(/<\s*\/?\s*>/g, ''); // Remove empty tags
                                              handleChange({ target: { name: 'description', value: sanitizedValue } });
                                            }}
                                            onBlur={handleBlur}
                                            margin="normal"
                                            sx={{
                                              maxWidth: 600,
                                              height: 200,
                                              '& .MuiFormLabel-root': {
                                                height: '20px'
                                              }
                                            }}
                                            inputProps={{
                                              maxLength: 1500 // Setting maximum character limit
                                            }}
                                          />
                                          {touched.description && errors.description && (
                                            <Typography color="error">{errors.description}</Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                        {activeStep === 2 && (
                          <Grid container textAlign="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {/* First Box: Date Selection */}
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                        width: '100%',
                                        display: 'flex'
                                      }}
                                    >
                                      <Box sx={{ marginBottom: 1 }}>
                                        <Typography variant="h5" color="white" sx={{ marginBottom: 1 }}>
                                          Select From Date
                                        </Typography>
                                        <DatePicker
                                          disablePast
                                          fullWidth
                                          className={classes.date}
                                          label="From Date"
                                          value={values.eventStartDate}
                                          onChange={(newValue) => setFieldValue('eventStartDate', newValue)}
                                          renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
                                        />
                                        {touched.eventStartDate && errors.eventStartDate && (
                                          <Typography color="error">{errors.eventStartDate}</Typography>
                                        )}

                                        <Typography variant="h5" color="white" sx={{ marginBottom: 1 }}>
                                          Select To Date
                                        </Typography>
                                        <DatePicker
                                          disablePast
                                          className={classes.date}
                                          label="To Date"
                                          value={values.eventEndDate}
                                          onChange={(newValue) => setFieldValue('eventEndDate', newValue)}
                                          renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
                                        />
                                        {touched.eventEndDate && errors.eventEndDate && (
                                          <Typography color="error">{errors.eventEndDate}</Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Box>
                                {/* Gap between the boxes */}
                                <Box sx={{ marginTop: 3 }} /> {/* Adjust the marginTop value as needed */}
                                {/* Second Box: Time Selection */}
                                <Box className={classes.outerBorderBox}>
                                  <Box className={classes.innerBorderBox}>
                                    <Grid
                                      container
                                      justifyContent="center"
                                      alignItems="center"
                                      sx={{
                                        width: '100%',
                                        display: 'flex'
                                      }}
                                    >
                                      <Box sx={{ marginBottom: 1 }}>
                                        <Typography variant="h5" color="white" sx={{ marginBottom: 1 }}>
                                          Starting Time
                                        </Typography>
                                        <TimePicker
                                          className={classes.date}
                                          label="Starting Time"
                                          value={values.eventStartTime}
                                          onChange={(newValue) => setFieldValue('eventStartTime', newValue)}
                                          sx={{
                                            '& .MuiFormLabel-root': {
                                              height: '20px'
                                            }
                                          }}
                                          renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
                                        />
                                        {touched.eventStartTime && errors.eventStartTime && (
                                          <Typography color="error">{errors.eventStartTime}</Typography>
                                        )}
                                        <Typography variant="h5" color="white" sx={{ marginBottom: 1 }}>
                                          Ending Time
                                        </Typography>
                                        <TimePicker
                                          className={classes.date}
                                          label="Ending Time"
                                          value={values.eventEndTime}
                                          onChange={(newValue) => setFieldValue('eventEndTime', newValue)}
                                          sx={{
                                            '& .MuiFormLabel-root': {
                                              height: '20px'
                                            }
                                          }}
                                          renderInput={(params) => <TextField fullWidth margin="normal" {...params} />}
                                        />
                                        {touched.eventEndTime && errors.eventEndTime && (
                                          <Typography color="error">{errors.eventEndTime}</Typography>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Box>
                                </Box>
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        )}
                        {activeStep === 3 && (
                          <Grid container justifyContent="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Box component="form" noValidate autoComplete="off">
                                <Grid>
                                  <Box
                                    className={classes.outerBorderBox}
                                    sx={{ mb: 2 }}
                                    // Add margin-bottom to create a gap
                                  >
                                    <Box className={classes.innerBorderBox}>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          width: '100%',
                                          display: 'flex'
                                        }}
                                      >
                                        <Box
                                          component="form"
                                          noValidate
                                          autoComplete="off"
                                          sx={{
                                            width: {
                                              xs: '90%', // Width for extra-small screens
                                              sm: '50%' // Width for small and larger screens
                                            }
                                          }}
                                        >
                                          <Box>
                                            <FormGroup>
                                              <Autocomplete
                                                sx={{
                                                  '& .MuiAutocomplete-paper': {
                                                    backgroundColor: 'white'
                                                  },
                                                  maxWidth: 600
                                                }}
                                                value={selectedArtist} // Set the value to the selected value from state
                                                onChange={handleAutocompleteChange} // Handle change to update state
                                                multiple={true}
                                                id="size-small-filled-multi"
                                                size="small"
                                                options={allArtistsList ? allArtistsList : []}
                                                disableCloseOnSelect
                                                PopperProps={{
                                                  PaperProps: {
                                                    style: {
                                                      backgroundColor: 'white' // Set background color here
                                                    }
                                                  }
                                                }}
                                                getOptionLabel={(option) => option.value}
                                                renderTags={(value, getTagProps) =>
                                                  value &&
                                                  value.map((option, index) => (
                                                    <Chip
                                                      key={index}
                                                      variant="outlined"
                                                      label={option.value}
                                                      size="small"
                                                      {...getTagProps({ index })}
                                                    />
                                                  ))
                                                }
                                                renderInput={(params) => (
                                                  <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Artist"
                                                    sx={{
                                                      color: 'black',
                                                      backgroundColor: 'white',
                                                      borderRadius: 1,
                                                      mb: 2,
                                                      mt: 2,
                                                      '& .MuiFilledInput-root': {
                                                        backgroundColor: 'White' // Ensures consistency with TextField
                                                      },
                                                      '& .MuiAutocomplete-tag': {
                                                        backgroundColor: 'White' // Styles for autocomplete tags
                                                      },
                                                      '& .MuiFormLabel-root': {
                                                        height: '20px'
                                                      }
                                                    }}
                                                    autoComplete="off" // Use "off" to hint the browser to disable autofill
                                                    placeholder="Select Artist"
                                                  />
                                                )}
                                              />
                                            </FormGroup>
                                          </Box>

                                          {touched.title && errors.title && <Typography color="error">{errors.title}</Typography>}
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Grid>

                                <Grid>
                                  <Box className={classes.outerBorderBox}>
                                    <Box className={classes.innerBorderBox}>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                          width: '100%',
                                          display: 'flex'
                                        }}
                                      >
                                        <Box
                                          component="form"
                                          noValidate
                                          autoComplete="off"
                                          sx={{
                                            width: {
                                              xs: '90%', // Width for extra-small screens
                                              sm: '50%' // Width for small and larger screens
                                            }
                                          }}
                                        >
                                          <TextField
                                            className={classes.textfield}
                                            id="outlined-multiline-flexible"
                                            multiline
                                            maxRows={8}
                                            fullWidth
                                            name="artistDescription"
                                            label="Artist Description*"
                                            value={values.artistDescription}
                                            onChange={(e) => {
                                              const trimmedValue = e.target.value.trimStart();
                                              const sanitizedValue = trimmedValue
                                                .replace(/<\/?[^>]+(>|$)/g, '') // Remove HTML tags
                                                .replace(/<\s*\/?\s*>/g, ''); // Remove empty tags
                                              handleChange({ target: { name: 'artistDescription', value: sanitizedValue } });
                                            }}
                                            onBlur={handleBlur}
                                            margin="normal"
                                            sx={{
                                              maxWidth: 600,
                                              height: 200,
                                              '& .MuiFormLabel-root': {
                                                height: '20px'
                                              }
                                            }}
                                            inputProps={{
                                              maxLength: 1500 // Setting maximum character limit
                                            }}
                                          />
                                          {touched.artistDescription && errors.artistDescription && (
                                            <Typography color="error">{errors.artistDescription}</Typography>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Box>
                                  </Box>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                        {activeStep === 4 && (
                          <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                              <Box className={classes.outerBorderBox}>
                                <Box className={classes.innerBorderBox}>
                                  <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center"
                                    sx={{
                                      width: '100%',
                                      display: 'flex'
                                    }}
                                  >
                                    <Box
                                      component="form"
                                      noValidate
                                      autoComplete="off"
                                      sx={{
                                        width: {
                                          xs: '90%', // Width for extra-small screens
                                          sm: '50%' // Width for small and larger screens
                                        }
                                      }}
                                    >
                                      <TextField
                                        margin="dense"
                                        id="country"
                                        label="Country"
                                        select
                                        fullWidth
                                        name="country"
                                        onBlur={handleBlur}
                                        value={selectedCountry}
                                        onChange={(event) => {
                                          handleCountryChange(event.target.value);
                                          handleChange(event);
                                        }}
                                        sx={{
                                          '& .MuiFormLabel-root': {
                                            height: '20px'
                                          },
                                          maxWidth: 600
                                        }}
                                      >
                                        {Country.map((option) => (
                                          <MenuItem
                                            sx={{ display: 'flex', flexWrap: 'wrap' }}
                                            key={option.id}
                                            value={option.id}
                                            disabled={option.disabled}
                                          >
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                      {touched.country && errors.country && <Typography color="error">{errors.country}</Typography>}

                                      <TextField
                                        margin="dense"
                                        id="state"
                                        label="State or Provinces"
                                        select
                                        fullWidth
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.state}
                                        name="state"
                                        sx={{
                                          '& .MuiFormLabel-root': {
                                            height: '20px'
                                          },
                                          maxWidth: 600
                                        }}
                                        disabled={!selectedCountry || selectedCountry === '0'}
                                      >
                                        {filteredStates.map((option) => (
                                          <MenuItem
                                            sx={{ display: 'flex', flexWrap: 'wrap' }}
                                            key={option.id}
                                            value={option.id}
                                            disabled={option.disabled}
                                          >
                                            {option.name}
                                          </MenuItem>
                                        ))}
                                      </TextField>
                                      {touched.state && errors.state && <Typography color="error">{errors.state}</Typography>}

                                      <TextField
                                        className={classes.textfield}
                                        fullWidth
                                        name="city"
                                        label="City"
                                        value={values.city}
                                        sx={{
                                          maxWidth: 600,
                                          '& .MuiFormLabel-root': {
                                            height: '20px'
                                          }
                                        }}
                                        onChange={(e) => {
                                          const trimmedValue = e.target.value.trimStart();
                                          // Remove special characters, numbers, and HTML tags
                                          let sanitizedValue = trimmedValue
                                            .replace(/<[^>]*>/g, '') // Remove HTML tags
                                            .replace(/[^a-zA-Z\s]/g, '');
                                          if (sanitizedValue.length > 50) {
                                            sanitizedValue = sanitizedValue.substring(0, 50);
                                          }
                                          handleChange({ target: { name: 'city', value: sanitizedValue } });
                                        }}
                                        onBlur={handleBlur}
                                        margin="normal"
                                      />
                                      {touched.city && errors.city && <Typography color="error">{errors.city}</Typography>}

                                      <TextField
                                        className={classes.textfield}
                                        fullWidth
                                        name="contactNumber"
                                        label="Contact Number"
                                        value={values.contactNumber}
                                        // onChange={handleChange}
                                        onChange={(e) => {
                                          const value = e.target.value.replace(/\D/g, ''); // Use regex to allow only numbers
                                          handleChange({ target: { name: 'contactNumber', value: value.slice(0, 10) } }); // Limit input to 10 digits
                                        }}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        sx={{
                                          maxWidth: 600,
                                          '& .MuiFormLabel-root': {
                                            height: '20px'
                                          }
                                        }}
                                        inputProps={{
                                          maxLength: 10 // Limit input length to 10 characters
                                        }}
                                      />
                                      {touched.contactNumber && errors.contactNumber && (
                                        <Typography color="error">{errors.contactNumber}</Typography>
                                      )}
                                      <TextField
                                        className={classes.textfield}
                                        fullWidth
                                        name="location"
                                        label="Google Plus Location"
                                        value={values.location}
                                        onChange={(e) => {
                                          // Trim whitespace from the beginning of the input value
                                          const input = e.target.value.trimStart();
                                          // Regular expression for Google Plus code (alphanumeric characters, hyphens, and underscores)
                                          const googlePlusCodePattern = /^[a-zA-Z0-9_-]{10,}$/;
                                          // Validate input using the regular expression
                                          if (googlePlusCodePattern.test(input) || input === '' || input) {
                                            // Call the handleChange function with the trimmed input value
                                            handleChange({ target: { name: 'location', value: input } });
                                          }
                                        }}
                                        onBlur={handleBlur}
                                        margin="normal"
                                        sx={{
                                          maxWidth: 600,
                                          '& .MuiFormLabel-root': {
                                            height: '20px'
                                          }
                                        }}
                                      />
                                      {touched.location && errors.location && <Typography color="error">{errors.location}</Typography>}
                                    </Box>
                                  </Grid>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                      </Box>
                      <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button color="inherit" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                          Back
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                          className={classes.button}
                          onClick={async () => {
                            if (activeStep === steps.length - 1) {
                              handleSubmit(); // Submit the form on the last step
                              setFieldValue("submitFlag", "submitEvent"); // For Final submit
                            } else {
                              await handleNext(); // Validate and go to next step
                            }
                          }}
                          type="button"
                          // disabled={isSubmitting || !isValid || !dirty} // Disable if form is invalid or untouched
                        >
                          {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                      </Box>
                    </React.Fragment>
                  )}
                </Box>
              </Box>
            )}
          </>
        );
      }}
    </Formik>
  );
}
const useStyles = makeStyles(() => ({
  button: {
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    color: '#fff'
  },
  date: {
    backgroundColor: '#fff',
    borderRadius: 5,
    border: 'none',
    marginBottom: 20
  },
  textfield: {
    backgroundColor: '#fff',
    borderRadius: 5
  },
  innerBorderBox: {
    backgroundColor: '#00101b', // Inner box background color (usually white)
    borderRadius: '10px', // Match the border radius
    width: '100%' // Ensures full-width responsiveness
  },
  outerBorderBox: {
    padding: '2px', // Small padding for the gradient border
    background: 'linear-gradient(to right, #ff0093, #7900ff)', // Gradient background
    borderRadius: '10px', // Rounded corners
    width: '100%', // Ensures full-width responsiveness
    display: 'inline-block'
  },
  stepper: {
    marginBottom: '50px',
    '& .MuiStepLabel-root': {
      color: 'gray', // Default text color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed step color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active step color
      }
    },
    '& .MuiStepLabel-label': {
      color: '#fff', // Default text color
      '&.Mui-completed': {
        color: 'grey' // Completed step color
      },
      '&.Mui-active': {
        color: '#fff' // Active step color
      }
    },
    '& .MuiStepIcon-root': {
      color: 'gray', // Default icon color
      '&.Mui-completed': {
        color: '#0dba4b' // Completed icon color
      },
      '&.Mui-active': {
        color: '#ff0093' // Active icon color
      }
    }
  }
}));