// assets

import LyricsIcon from '@mui/icons-material/Lyrics';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';


// icons
const icons = {
    LyricsIcon,
    QueueMusicIcon,
    PersonAddAltIcon,
    GraphicEqIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //
const userType = localStorage.getItem("userType");

const ArtistsRepertoire = userType === 'ATR' && {
    id: 'utilities',
    title: 'Profile',
    type: 'group',
    children: [
        userType === 'ATR' && {
            id: 'util-atr',
            // title: 'Create ArtistsRepertoire',
            title: 'Artist & Repertoire Profile',
            type: 'item',
            url: '/dashboard/create-artists-repertoire',
            icon: icons.PersonAddAltIcon
        },
    ].filter(Boolean)
};

export default ArtistsRepertoire;
