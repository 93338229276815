// components/GoogleCallback.jsx

import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const token = query.get('token');
    const user = JSON.parse(query.get('user'));

    if (token && user) {
      localStorage.setItem('token', token);
      localStorage.setItem('userName', user.userName);
      localStorage.setItem('userType', user.userType);

      const userType = user.userType;
      if (userType === 'ORG') {
        window.location.href = '/dashboard/eventcreate'; // Reloads the page
      } else if (userType === 'AR') {
        window.location.href = '/dashboard/artistprofile'; // Reloads the page
      } else if (userType === 'AMP') {
        window.location.href = '/dashboard/amps';
      } else if (userType === 'ATR') {
        window.location.href = '/dashboard/create-artists-repertoire';
      }
      else if (userType === 'AD') {
        window.location.href = '/dashboard/admin';
      } else {
        navigate('/profile', { replace: true }); // No reload needed
      }
    } else {
      navigate('/login', { replace: true });
    }
  }, [location, navigate]);

  return <div>Loading...</div>;
};

export default GoogleCallback;

