import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import makeApiCall from 'common/makeApiCall';
import { Link } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DateRangeIcon from '@mui/icons-material/DateRange';
import noData from '../../../assets/icons/nodata.png';
import { Box, PaginationItem, Pagination, LinearProgress, FormControl, TextField, MenuItem, Button, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
// import { useNavigate } from 'react-router-dom';s
// import { useEvent } from 'context/EventContext';
const options = [
  { value: 'OM', label: 'Open Mic' },
  { value: 'LG', label: 'Live Gig' },
  { value: 'MF', label: 'Festivals' }
];

// Create a styled Button component
const StyledButton = styled(Button)(({ theme, isActive }) => ({
  backgroundColor: !isActive ? 'rgba(255, 255, 255, 0.30)' : 'transparent',
  backgroundImage: isActive
    ? 'linear-gradient(to right, #ff0093, #7900ff)' // Apply gradient if active
    : 'none', // No gradient if inactive

  ...theme.typography.h5,
  padding: theme.spacing(2),
  textAlign: 'center',
  '&:hover': {
    cursor: isActive ? 'default' : 'pointer',
    color: 'white'
  },
  color: 'black'
}));

export default function Myevent() {
  const classes = useStyles();
  const [events, setEvents] = useState([]);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [activeButton, setActiveButton] = useState('F');
  const [value, setValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    handleSubmit();
  }, [activeButton, page]);

  // ==============|EVENT LIST API CALL|====================//

  const handleSubmit = async (data) => {
    if (data) {
      setPage(1);
}
    try {
      setLoading(true);

      const requestData = {
        eventType: value,
        eventTimeline: activeButton // Use activeButton as eventTimeline
      };
      const response = await makeApiCall('post', `/event/mypostlist?page=${page}`, requestData);
      setEvents(response.data); // Setting the array of events
      setTotalPages(response.pagination?.totalPages || 1);
    } catch (error) {
      setError(error);
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <Box fullWidth sx={{ '& button': { m: 1 }, marginBottom: 2 }}>
        <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
          <StyledButton
            onClick={() => {
              setActiveButton('P');
              setValue('');
              handleSubmit('initialPageNumberOnSearch');
            }}
            isActive={activeButton === 'P'}
          >
            Past Events
          </StyledButton>
          <StyledButton
            onClick={() => {
              setActiveButton('F');
              setValue('');
              handleSubmit('initialPageNumberOnSearch');
            }}
            isActive={activeButton === 'F'}
          >
            Future Events
          </StyledButton>
          <StyledButton
            onClick={() => {
              setActiveButton('D');
              setValue('');
              handleSubmit('initialPageNumberOnSearch');
            }}
            isActive={activeButton === 'D'}
          >
            Drafts
          </StyledButton>
        </Stack>
      </Box>

      <Box sx={{ background: 'linear-gradient(to right, #ff0093, #7900ff)', padding: 1, borderRadius: 1 }}>
        <form>
          <Grid container sx={{ alignItems: 'center' }} spacing={2}>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="eventType"
                  label="Event Type"
                  select
                  fullWidth
                  name="eventType"
                  value={value}
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  onChange={handleChange}
                  InputProps={
                    value?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px'
                          }}
                        >
                          <IconButton
                            onClick={() => setValue('')} 
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <Button onClick={() => handleSubmit('initialPageNumberOnSearch')} variant="contained" size="large">
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>

      {events.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2, alignItems: 'center', height: '100vh' }}>
          <img src={noData} alt="" style={{ width: '60px', height: '60px' }} />
          <Typography variant="h6">No Data Available</Typography>
        </Box>
      ) : (
        <>
          <Grid container spacing={2} sx={{ marginTop: 4 }}>
            {events.map((event) => (
              <Grid item xs={12} sm={6} md={4} key={event.id} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Link target="_blank" to={`/dashboard/eventdetails/${event.id}`} className={classes.link}>
                  <Card sx={{ maxWidth: 345, borderRadius: 2, display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <CardMedia
                      component="img"
                      height="140"
                      image="https://img.freepik.com/free-photo/group-workers-organizing-protest_23-2151546471.jpg?t=st=1723638883~exp=1723642483~hmac=e4245ac7ea77da58d80f09ec7085905271e3ec0ef620011a7a2a8bcd7b379fd8&w=1060"
                      sx={{ height: 180, objectFit: 'cover' }}
                    />
                    <CardContent
                      sx={{
                        flexGrow: 1,
                        display: 'flex',
                        backgroundColor: '#fff',
                        color: 'black',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        minHeight: 100
                      }}
                    >
                      <Typography variant="h5" sx={{ mb: 0.5 }}>
                        {event.title}
                      </Typography>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          backgroundColor: '#1677ff',
                          color: '#fff',
                          borderRadius: 6,
                          p: 0.5,
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 'bold'
                        }}
                      >
                        <DateRangeIcon sx={{ mr: 1, ml: 1 }} /> {/* Adjust margin right for icon */}
                        {event.eventStartDate} | Starts at {event.eventStartTime}
                        <Link to={`/dashboard/eventupdate/${event.id}`}>
                          <EditIcon
                            sx={{
                              ml: 1,
                              display: 'flex',
                              alignItems: 'center',
                              color: '#fff'
                            }}
                          />
                        </Link>
                      </Typography>

                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          backgroundColor: '#ff0093',
                          color: '#fff',
                          borderRadius: 6,
                          p: 0.5,
                          mr: 1,
                          mb: 1,
                          display: 'flex',
                          alignItems: 'center',
                          fontWeight: 'bold',
                          justifyContent: 'center'
                        }}
                      >
                        {event.eventType == 'OM'
                          ? 'Open Mic'
                          : event.eventType == 'LG'
                            ? 'Live Gig'
                            : event.eventType == 'MF'
                              ? 'Music Festival'
                              : ''}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>

          {/* Pagination Controls */}
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handlePageChange}
              renderItem={(item) => <CustomPaginationItem {...item} />}
              color="primary"
            />
          </Box>
        </>
      )}
    </>
  );
}

const useStyles = makeStyles({
  card: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRadius: 8
  },
  link: {
    textDecoration: 'none', // Remove underline
    color: 'inherit', // Use inherit color
    '&:hover': {
      textDecoration: 'none' // Remove underline on hover as well
    }
  }
});

const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  '&.MuiPaginationItem-page': {
    color: '#fff' // Customize page numbers color
  },
  '&.MuiPaginationItem-previousNext': {
    color: 'red' // Customize next/previous buttons color
  }
}));
