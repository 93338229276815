import * as React from 'react';
import { Grid, Typography, Chip, Stack, Box, CardMedia, IconButton } from '@mui/material';
// import ChatIcon from '@mui/icons-material/Chat';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import makeApiCall from 'common/makeApiCall';
import profile from '../../assets/images/users/profile.png';
import YouTubeIcon from '../../assets/icons/youtube.png';
import InstagramIcon from '../../assets/icons/instagram.png';
import FacebookIcon from '../../assets/icons/facebook.png';
import TwitterIcon from '../../assets/icons/twitter.png';
import LinkedInIcon from '../../assets/icons/linkedin.png';
import SpotifyIcon from '../../assets/icons/spotify.png';
import SoundCloudIcon from '../../assets/icons/soundcloud.png';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import YouTube from 'pages/components-overview/YouTube';

// import { useTheme } from '@mui/material/styles';
// const Item = styled(Paper)(({ theme }) => ({
//     backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//     ...theme.typography.body2,
//     padding: theme.spacing(1),
//     textAlign: 'center',
//     color: theme.palette.text.secondary,
// }));

export default function ArtistMyProfile() {
  // const theme = useTheme();
  const { id } = useParams();
  let navigate = useNavigate();
  console.log('id', id);

  const [artist, setArtist] = useState(null);
  // const genres = artist?.genres;
  // const genresArray = genres?.split(','); // ["JA", "PO"]

  // const instrument = artist?.instruments;
  // const instrumentArray=instrument?.split(',');
  //["GU","PI"]

  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditClick = () => {
    navigate('/dashboard/artistprofile');
  };

  // const isMobile = useMediaQuery('(max-width:600px)');
  // ===================|EVENT DETAILS API CALL WITH SINGLE ID|========================//
  // Fetch artist details based on the provided ID
  useEffect(() => {
    const fetchArtist = async () => {
      try {
        const artistData = await makeApiCall('get', `/artist/myprofile`);
        setArtist(artistData);
      } catch (error) {
        navigate('/dashboard/artistprofile')
        setError('')
      }
    };
    fetchArtist();
  }, [id]);

  if (error) {
    return <Typography color="error">Failed to load artist details. Please try again later.</Typography>;
  }
  if (!artist) {
    return <Typography>Loading...</Typography>;
  }
  // Define social profiles with icons
  const socialProfiles = [
    { name: 'YouTube', icon: <img src={YouTubeIcon} width="22" height="18" alt="YouTubeIcon" />, url: artist.youtubeProfile },
    { name: 'Instagram', icon: <img src={InstagramIcon} width="21" height="20" alt="InstagramIcon" />, url: artist.instagramProfile },
    { name: 'Facebook', icon: <img src={FacebookIcon} width="20" height="20" alt="FacebookIcon" />, url: artist.fbProfile },
    { name: 'Twitter', icon: <img src={TwitterIcon} width="20" height="18" alt="TwitterIcon" />, url: artist.xProfile },
    { name: 'LinkedIn', icon: <img src={LinkedInIcon} width="22" height="18" alt="LinkedInIcon" />, url: artist.linkedinProfile },
    { name: 'Spotify', icon: <img src={SpotifyIcon} width="22" height="22" alt="SpotifyIcon" />, url: artist.spotifyProfile },
    { name: 'SoundCloud', icon: <img src={SoundCloudIcon} width="22" height="18" alt="SoundCloudIcon" />, url: artist.soundcloudProfile }
  ];
  const filteredSocialProfiles = socialProfiles.filter((profile) => profile.url && profile.url.trim() !== '');

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
            // alignItems:{xs:'center'}
          }}
        >
          {/* <Grid container sx={{ mb: 2, display:'flex',justifyContent:{xs:"flex-start",md:'flex-start'}} } backgroundColor='red'> */}
          <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-start', md: 'flex-start' } }}>
            {/* // Adjust this value based on how much space you want on medium screens */}
            <CardMedia
              component="img"
              sx={{
                width: 151,
                height: 151,
                objectFit: 'cover',
                borderRadius: 3
              }}
              image={profile}
              alt="profile"
            />
          </Box>

          <Box sx={{ ml: { xs: 2, md: 2 }, textAlign: { xs: 'left', md: 'left' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'flex-start', md: 'flex-start' } }}>
              <Typography variant="h3" gutterBottom>
                {capitalizeFirstLetter(artist.fullName)}
              </Typography>

              <IconButton
                sx={{
                  ml: 1,
                  bottom: 5,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '50%'
                }}
                onClick={handleEditClick}
              >
                <EditIcon />
              </IconButton>
            </Box>

            <Typography variant="subtitle1" gutterBottom>
              {artist.city}, {artist.stateLabel}, {artist.countryLabel}
            </Typography>

            <Stack sx={{ mb: 2, cursor: 'pointer', alignItems: { xs: 'flex-start', md: 'flex-start' } }} spacing={1}>
              <Chip
                sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                label={artist.artistType}
                variant="outlined"
              />
            </Stack>
          </Box>
        </Box>

        {/* <Stack sx={{ ml: 'auto', mb: 2, cursor: 'pointer' }} direction={{ xs: 'column', sm: 'row' }} spacing={1}>
              {artist.genres.map((genre, index) => (
                <Chip key={index} sx={{ borderRadius: 4, backgroundColor: '#1677ff', color: '#fff' }} label={genre} color="success" />
              ))} */}
        {/* <Chip sx={{ borderRadius: 4 }} icon={<ChatIcon />} color="primary" label="Message" onClick={handleClickOpen} /> */}
        {/* </Stack> */}

        {/* Message Button */}
        {/* Instrument section */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Instruments
          </Typography>
          <Stack
            sx={{
              ml: 'auto',
              mb: 2,
              cursor: 'pointer',
              flexWrap: 'wrap',
              gap: 1
            }}
            direction="row"
          >
            {artist.instrumentLabels.map((instrument, index) => (
              <Chip
                key={index}
                sx={{
                  borderRadius: 4,
                  borderColor: '#fff',
                  color: '#fff',
                  minWidth: '80px' // optional, adjust as needed
                }}
                label={instrument}
                color="success"
                variant="outlined"
              />
            ))}
          </Stack>
        </Box>

        {/* Genre section */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Genre
          </Typography>

          <Stack
            sx={{
              ml: 'auto',
              mb: 2,
              cursor: 'pointer',
              flexWrap: 'wrap',
              gap: 1
            }}
            direction="row"
          >
            {artist.genreLabels.map((genre, index) => (
              <Chip
                key={index}
                sx={{
                  borderRadius: 4,
                  borderColor: '#fff',
                  color: '#fff',
                  minWidth: '80px' // optional, adjust as needed
                }}
                label={genre}
                color="success"
                variant="outlined"
              />
            ))}
          </Stack>
        </Box>
        {/* Social Profiles */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >

         <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Social Links
          </Typography>
          <Grid container spacing={1}>
            {filteredSocialProfiles.map((profile, index) => (
              <Grid item key={index}>
                <Chip
                  icon={profile.icon}
                  label={profile.name}
                  onClick={() => window.open(profile.url, '_blank')}
                  sx={{
                    borderRadius: 4,
                    p: 0.5,
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: 'white',
                      cursor: 'default'
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
          </Box>
          {/* Performance Types */}
          <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >

<Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Performance Types
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Stack sx={{ ml: 'auto', mb: 2, cursor: 'pointer', flexWrap: 'wrap', gap: 1 }} direction="row">
              {artist.performanceTypeLabels.map((value, index) => (
                <Chip
                  key={index}
                  sx={{
                    borderRadius: 4,
                    borderColor: '#fff',
                    color: '#fff',
                    minWidth: '80px'
                    // optional, adjust as needed
                  }}
                  label={value}
                  variant="outlined"
                />
              ))}
            </Stack>
          </Box>
          </Box>

          <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >

        <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Biography:
          </Typography>
          <Grid item xs={12} sx={{ textAlign: { xs: 'start', md: 'start' } }}>
          <Box sx={{  textAlign: 'justify' }}>
            <Typography variant="body1" sx={{textIndent: '100px'}}>{artist.biography}</Typography>
            </Box>
          </Grid>
        </Box>
        {/* YouTube Video */}
        {/* Use the ViewProfile component */}
        <YouTube artist={artist} />
      </Grid>

      {/* YouTube Video */}

      {/* <Box>
      
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            paddingTop: '56.25%', // 16:9 aspect ratio
            borderRadius: 8,
            
            
          }} 
        >
          <iframe 
            src={artist.youtubeUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            style={{
              position: 'absolute',
              borderRadius: 10,
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              border: 0
            }} 
          ></iframe>
        </Box>
       
      </Box> */}
      <>
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Dialog fullScreen={fullScreen} open={open} onClose={handleClose} maxWidth="md">
            <DialogContent>
              <TextField
                id="filled-multiline-static"
                label="Message"
                multiline
                rows={4}
                defaultValue="Message"
                variant="filled"
                sx={{
                  width: {
                    xs: 300,
                    sm: 500,
                    md: 500
                  },
                  height: '200px'
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose} startIcon={<CancelIcon />}>
                Cancel
              </Button>
              <Button variant="contained" onClick={handleClose} endIcon={<SendIcon />}>
                Send
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </>
    </Grid>
  );
}
