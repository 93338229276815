import * as React from 'react';
import { Grid, CardMedia, Typography, Box, Chip, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import makeApiCall from 'common/makeApiCall';
import profile from '../../assets/images/users/profile.png';
import YouTubeIcon from '../../assets/icons/youtube.png';
import InstagramIcon from '../../assets/icons/instagram.png';
import FacebookIcon from '../../assets/icons/facebook.png';
import TwitterIcon from '../../assets/icons/twitter.png';
import LinkedInIcon from '../../assets/icons/linkedin.png';
import SpotifyIcon from '../../assets/icons/spotify.png';
import SoundCloudIcon from '../../assets/icons/soundcloud.png';
import YouTube from 'pages/components-overview/YouTube';

export default function Artistrepertoireview() {
  const { id } = useParams();
  const [artist, setArtist] = useState(null);
  const [error, setError] = useState(null);

  // ===================|EVENT DETAILS API CALL WITH SINGLE ID|========================//
  useEffect(() => {
    const fetchArtist = async () => {
      console.log('id', id);
      try {
        const artistData = await makeApiCall('get', `/artistrepertoire/artistrepertoireview/${id}`);
        setArtist(artistData);
        console.log('res', artistData);
      } catch (error) {
        setError(error);
      }
    };
    fetchArtist();
  }, [id]);

  if (error) {
    return <Typography color="error">Failed to load artist details. Please try again later.</Typography>;
  }
  if (!artist) {
    return <Typography>Loading...</Typography>;
  }

  // const getPaymentStatusLabel = (status) => {
  //     switch (status) {
  //         case 'P':
  //             return 'PAID';
  //         case 'UP':
  //             return 'UNPAID';
  //         default:
  //             return 'UNPAID';
  //     }
  // };
  // Function to map boolean work availability to display text
  // const getActiveStatusLabel = (status) => {
  //     if (status === true) {
  //         return 'AVAILABLE';
  //     } else if (status === false) {
  //         return 'UNAVAILABLE';
  //     } else {
  //         return 'Unknown';
  //     }
  // };

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // Define social profiles with icons
  const socialProfiles = [
    { name: 'YouTube', icon: <img src={YouTubeIcon} width="22" height="18" alt="YouTubeIcon" />, url: artist.youtubeProfile },
    {
      name: 'Instagram',
      icon: <img src={InstagramIcon} width="21" height="20" alt="InstagramIcon" />,
      url: artist.instagramProfile
    },
    { name: 'Facebook', icon: <img src={FacebookIcon} width="20" height="20" alt="FacebookIcon" />, url: artist.fbProfile },
    { name: 'Twitter', icon: <img src={TwitterIcon} width="20" height="18" alt="TwitterIcon" />, url: artist.xProfile },
    { name: 'LinkedIn', icon: <img src={LinkedInIcon} width="22" height="18" alt="LinkedInIcon" />, url: artist.linkedinProfile },
    { name: 'Spotify', icon: <img src={SpotifyIcon} width="22" height="22" alt="SpotifyIcon" />, url: artist.spotifyProfile },
    {
      name: 'SoundCloud',
      icon: <img src={SoundCloudIcon} width="22" height="18" alt="SoundCloudIcon" />,
      url: artist.soundcloudProfile
    }
  ];
  const filteredSocialProfiles = socialProfiles.filter((profile) => profile.url && profile.url.trim() !== '');

  
  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Artist Image and Basic Info */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* Profile Image and Basic Info */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { md: '180%', xs: '100%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <CardMedia component="img" sx={{ width: 151, height: 151, objectFit: 'cover', borderRadius: 3 }} image={profile} alt="profile" />

          <Box sx={{ ml: 2, textAlign: { xs: 'left', md: 'left' } }}>
            <Typography variant="h3">{capitalizeFirstLetter(artist.fullName)}</Typography>
            <Typography variant="subtitle1">
              {artist.city}, {artist.stateLabel}, {artist.countryLabel}
            </Typography>
            <Typography variant="body1">{artist.email}</Typography>

            <Typography variant="body1" gutterBottom>
              {artist.phone}
            </Typography>
          </Box>
        </Box>

 {/* Genre section */}
 <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Genre
          </Typography>

          <Stack
            sx={{
              ml: 'auto',
              cursor: 'pointer',
              flexWrap: 'wrap',
              gap: 1
            }}
            direction="row"
          >
            {artist.genreLabels.map((genre, index) => (
              <Chip
                key={index}
                sx={{
                  borderRadius: 4,
                  borderColor: '#fff',
                  color: '#fff',
                  minWidth: '80px' // optional, adjust as needed
                }}
                label={genre}
                color="success"
                variant="outlined"
              />
            ))}
          </Stack>
        </Box>

        {/* Social Profiles */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Social Links
          </Typography>

          <Grid container spacing={1}>
            {filteredSocialProfiles.map((profile, index) => (
              <Grid item key={index}>
                <Chip
                  icon={profile.icon}
                  label={profile.name}
                  onClick={() => window.open(profile.url, '_blank')}
                  sx={{
                    borderRadius: 4,
                    p: 0.5,
                    backgroundColor: 'white',
                    '&:hover': {
                      backgroundColor: 'white',
                      cursor: 'default'
                    }
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        {/* Social Profiles */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Professional Bio
          </Typography>

          <Grid item xs={12} sx={{ textAlign: { xs: 'start', md: 'start' } }}>
            <Typography variant="h5" gutterBottom>
              Company Name: {artist.company}{' '}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: { xs: 'start', md: 'start' } }}>
            <Typography variant="h5">Biography: </Typography>
            <Box sx={{  textAlign: 'justify' }}>
              <Typography variant="body1" sx={{textIndent: '80px'}}>{artist.biography}</Typography>
            </Box>
          </Grid>
        </Box>

        {/* Performance Types */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Performance Type
          </Typography>

          <Stack sx={{ ml: 'auto', cursor: 'pointer', flexWrap: 'wrap', gap: 1 }} direction="row">
            {artist.performanceTypeLabels.map((value, index) => (
              <Chip
                key={index}
                sx={{
                  borderRadius: 4,
                  borderColor: '#fff',
                  color: '#fff',
                  minWidth: '80px'
                  // optional, adjust as needed
                }}
                label={value}
                variant="outlined"
              />
            ))}
          </Stack>
        </Box>
{/* YouTube Video */}
        {/* Use the youtube component */}
        <YouTube artist={artist} />

      </Grid>
    </Grid>
  );
}
