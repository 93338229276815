import React, { useEffect, useState } from 'react';
import makeApiCall from 'common/makeApiCall';
import { Link } from 'react-router-dom';
import artistprofile from '../../assets/images/users/artistprofile.jpg';
import { styled } from '@mui/material/styles';
import PaginationItem from '@mui/material/PaginationItem';
import {
  Grid,
  Card,
  Box,
  CardContent,
  Typography,
  CardMedia,
  Container,
  LinearProgress,
  Pagination,
  FormControl,
  TextField,
  MenuItem,
  Button,
  IconButton
} from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { states } from 'data/State';
import { Country } from 'data/Country';
import noData from '../../assets/icons/nodata.png';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';
import { genreList, PerformanceTypeList } from 'data/Common';
// import AccountCircle from '@mui/icons-material/AccountCircle';

export default function Artistrepertoirelist() {
  const [artists, setArtists] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState(null);

  const [fullName, setFullName] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedState, setSelectedState] = useState('');
  const [filteredStates, setFilteredStates] = useState([]);
  const [citySearchData, setCitySearchData] = useState('');
  const [genreSearchData, setGenreSearchData] = useState('');
  const [companysearchdata, setCompanysearchdata] = useState('');
  const [performanceTypeSearchData, setPerformanceTypeSearchData] = useState('');



  const handleSubmit = async (data) => {
    if (data) {
      setPage(1);
}
    try {
      setLoading(true);
      const response = await makeApiCall('post', `/artistrepertoire/allartistrepertoire?page=${page}`, {
        fullName: fullName,
        country: selectedCountry,
        state: selectedState,
        city: citySearchData,
        genres: genreSearchData,
        company:companysearchdata,
        performanceType: performanceTypeSearchData,

      });
      setArtists(response.data); // Setting the array of events
      setTotalPages(response.pagination?.totalPages || 1); // Setting the total number of pages with a fallback
    } catch (error) {
      setError(error);
      console.error('Error fetching events:', error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    handleSubmit();
  }, [page]);
  useEffect(() => {
    if (selectedCountry) {
      setFilteredStates(states[selectedCountry] || []);
    } else {
      setFilteredStates([]);
    }
  }, [selectedCountry]);

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
  };
  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handlenameChange = (event) => {
    setFullName(event.target.value);
  };
  const handleCitySearchChange = (event) => {
    setCitySearchData(event.target.value);
  };
  const handlePerformanceTypeSearchChange = (event) => {
    setPerformanceTypeSearchData(event.target.value);
  };
  const handleGenreSearchChange = (event) => {
    setGenreSearchData(event.target.value);
  };
  const handleCompanySearchChange = (event) => {
    setCompanysearchdata(event.target.value);
  };

  if (loading) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
      <Box sx={{ background: 'linear-gradient(to right, #ff0093, #7900ff)', padding: 1, borderRadius: 1 }}>
        <form>
          <Grid container sx={{ alignItems: 'center' }} spacing={2}>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="outlined-basic"
                  label="Name"
                  name="fullname"
                  variant="outlined"
                  value={fullName}
                  onChange={handlenameChange}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  InputProps={
                    fullName?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            backgroundColor: 'white'
                          }}
                        >
                          <IconButton
                            onClick={() => setFullName('')} // Clear the input when clicked
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="country"
                  label="Country"
                  select
                  fullWidth
                  name="country"
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  value={selectedCountry}
                  onChange={handleCountryChange}
                  InputProps={
                    selectedCountry?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px' // Move the button to the left a bit
                          }}
                        >
                          <IconButton
                            onClick={() => 
                                {setSelectedCountry('');
                                setSelectedState('');
                                }} // Clear the input when clicked
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                >
                  {Country.map((option) => (
                    <MenuItem sx={{ display: 'flex', flexWrap: 'wrap' }} key={option.id} value={option.id} disabled={option.disabled}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="state"
                  label="State"
                  select
                  fullWidth
                  name="state"
                  onChange={handleStateChange}
                  value={selectedState}
                  disabled={!selectedCountry || selectedCountry === '0'}
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  InputProps={
                    selectedState?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px' // Move the button to the left a bit
                          }}
                        >
                          <IconButton
                            onClick={() => setSelectedState('')} // Clear the input when clicked
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                  // value={filteredStates.find((state) => state.id === selectedCountry)?.id || ''} // Handle cases where no state matches selectedCountry
                >
                  {filteredStates.map((option) => (
                    <MenuItem sx={{ display: 'flex', flexWrap: 'wrap' }} key={option.id} value={option.id} disabled={option.disabled}>
                      {option.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="outlined-basic"
                  label="City"
                  name="citysearchdata"
                  variant="outlined"
                  value={citySearchData}
                  onChange={handleCitySearchChange}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 2,
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    }
                  }}
                  InputProps={
                    citySearchData?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            backgroundColor: 'white'
                          }}
                        >
                          <IconButton onClick={() => setCitySearchData('')}>
                            <CloseIcon  fontSize="small"/>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="genres"
                  label="Genres"
                  select
                  fullWidth
                  name="genres"
                  value={genreSearchData}
                  onChange={handleGenreSearchChange}
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  InputProps={
                    genreSearchData?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px' // Move the button to the left a bit
                          }}
                        >
                          <IconButton
                            onClick={() => setGenreSearchData('')} // Clear the input when clicked
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                >
                  {genreList.map((genreValue) => (
                    <MenuItem key={genreValue.value} value={genreValue.value}>
                      {genreValue.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="outlined-basic"
                  label="Company"
                  name="companysearchdata"
                  variant="outlined"
                  value={companysearchdata}
                  onChange={handleCompanySearchChange}
                  sx={{
                    backgroundColor: 'white',
                    borderRadius: 2,
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    }
                  }}
                  InputProps={
                    companysearchdata?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            backgroundColor: 'white'
                          }}
                        >
                          <IconButton onClick={() => setCompanysearchdata('')}>
                            <CloseIcon  fontSize="small"/>
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} md={2}>
              <FormControl fullWidth>
                <TextField
                  margin="dense"
                  id="performanceType"
                  label="Performance Type"
                  select
                  fullWidth
                  name="performanceType"
                  value={performanceTypeSearchData}
                  onChange={handlePerformanceTypeSearchChange}
                  sx={{
                    '& .MuiFormLabel-root': {
                      height: '20px'
                    },
                    backgroundColor: 'white',
                    borderRadius: 2
                  }}
                  InputProps={
                    performanceTypeSearchData?.length > 0 && {
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          sx={{
                            marginRight: '12px' // Move the button to the left a bit
                          }}
                        >
                          <IconButton
                            onClick={() => setPerformanceTypeSearchData('')} // Clear the input when clicked
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </InputAdornment>
                      )
                    }
                  }
                >
                  {PerformanceTypeList.map((performanceValue) => (
                    <MenuItem key={performanceValue.value} value={performanceValue.value}>
                      {performanceValue.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </Grid>

            <Grid item xs={12} md={2}>
              <Button onClick={() => handleSubmit('initialPageNumberOnSearch')} variant="contained" size="large">
                Search
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
      {artists.length === 0 ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 2, alignItems: 'center', height: '100vh' }}>
          <img src={noData} alt="" style={{ width: '60px', height: '60px' }} />
          <Typography variant="h6">No Data Available</Typography>
        </Box>
      ) : (
        // {/* ========================|Artist Repertoire CARD LIST |====================== */}
        <Container>
          <Grid container spacing={2} sx={{ marginTop: 4 }}>
            {artists.map((artist) => (
              <Grid key={artist.userId} item xs={12} sm={6} md={4}>
                <Card sx={{ maxWidth: 280, borderRadius: 2 }}>
                  <CardMedia
                    component="img"
                    height="300"
                    sx={{ objectFit: 'fill', width: '100%' }}
                    image={artistprofile || 'default_profile_picture_url'}
                    alt="green iguana"
                  />
                  <CardContent sx={{ backgroundColor: '#fff', color: 'black' }}>
                    <Typography gutterBottom variant="h3" component="div">
                      {capitalizeFirstLetter(artist.fullName)}
                    </Typography>
                    <Typography gutterBottom variant="h5" component="div">
                      {capitalizeFirstLetter(artist.email)}
                    </Typography>
                    <Link target="_blank" to={`/dashboard/atrview/${artist.userId}`} style={{ textDecoration: 'none' }}>
                      <Button variant="contained" endIcon={<ArrowForwardIcon />}>
                        View Profile
                      </Button>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            variant="outlined"
            renderItem={(item) => <CustomPaginationItem {...item} />}
            sx={{ marginTop: '5rem', display: 'flex', justifyContent: 'center' }}
          />
        </Container>
      )}
    </>
  );
}
// const useStyles = makeStyles(() => ({
//     card: {
//         display: 'flex',
//         flexDirection: 'column',
//         borderRadius: 15,
//         position: 'relative',
//         border: '0.01px solid #fff',
//     },
//     avatar: {
//         size: 12,
//     },
// }))

const CustomPaginationItem = styled(PaginationItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white
  },
  '&.MuiPaginationItem-page': {
    color: '#fff' // Customize page numbers color
  },
  '&.MuiPaginationItem-previousNext': {
    color: 'red' // Customize next/previous buttons color
  }
}));
