// assets
import LyricsIcon from '@mui/icons-material/Lyrics';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PianoIcon from '@mui/icons-material/Piano';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LoginIcon from '@mui/icons-material/Login';

// icons
const icons = {
    LyricsIcon,
    QueueMusicIcon,
    PersonAddAltIcon,
    GraphicEqIcon,
    PianoIcon,
    BarChartIcon,
    PeopleIcon,
    LoginIcon,
    LibraryMusicIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //
const userType = localStorage.getItem("userType");
console.log("Unknowns")
const Unknown = !userType && {
    id: 'utilities',
    title: 'Profile',
    type: 'group',
    children: [
        {
        id: 'adminDashboard',
        title: 'Login',
        type: 'item',
        url: 'login',
        icon: icons.LoginIcon
    },
    ]
        }

export default Unknown;
