// src/utils/api.js
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const apiBaseUrl = "https://api.musicindustree.com/api/"; // PROD
//const apiBaseUrl = "http://uatapi.musicindustree.com/api/"; // UAT
// const apiBaseUrl = "http://musicindustreeapi.theopenletter.org/api/"; // UAT
// const apiBaseUrl = "http://localhost:8000/api/"; // Local
async function makeApiCall(method, url, data = null, successMessage = '', errorMessage = '') {
    const token = localStorage.getItem('token');
    const api = axios.create({
        baseURL: apiBaseUrl,// Set your API base URL here
        headers: {
            'Content-Type': 'application/json',
            Authorization: token ? `Bearer ${token}` : '', // Include Authorization header only if token exists
        },
    });
    try {
        const response = await api.request({
            method: method,
            url: url,
            data: data,
        });

        if (successMessage) {
            console.log(successMessage);
            toast.success(response?.data?.message, {
                position: "top-right",
                autoClose: 2000, // Close toast after 2 seconds (2000 milliseconds)
                hideProgressBar: true, // Hide progress bar
            });
        }
        return response.data; // Return data from the response

    } catch (error) {
        if (errorMessage) {
            console.error(errorMessage, error);
            toast.error(error.response?.data?.error || errorMessage, {
                position: "top-right",
                autoClose: 2000, // Close toast after 2 seconds (2000 milliseconds)
                hideProgressBar: true, // Hide progress bar
            });
        }
        throw error; // Throw error for the caller to handle
    }
}

export default makeApiCall;
