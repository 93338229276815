export const states = {
    "0": [{ id: "0", name: "State", disabled: true }],
    "CA": [
        { id: "0", name: "Select a state or Provinces *", disabled: true },
        { id: "AB", name: "Alberta" },
        { id: "BC", name: "British Columbia" },
        { id: "MB", name: "Manitoba" },
        { id: "NB", name: "New Brunswick" },
        { id: "NL", name: "Newfoundland and Labrador" },
        { id: "NT", name: "Northwest Territories" },
        { id: "NS", name: "Nova Scotia" },
        { id: "NU", name: "Nunavut" },
        { id: "ON", name: "Ontario" },
        { id: "PE", name: "Prince Edward Island" },
        { id: "QC", name: "Quebec" },
        { id: "SK", name: "Saskatchewan" },
        { id: "YT", name: "Yukon" }
        // Add more Canadian states here if needed
    ],
    "IN": [
        { id: "0", name: "Select a state", disabled: true },
        { id: "AN", name: "Andaman and Nicobar Islands" },
        { id: "AP", name: "Andhra Pradesh" },
        { id: "AR", name: "Arunachal Pradesh" },
        { id: "AS", name: "Assam" },
        { id: "BR", name: "Bihar" },
        { id: "CH", name: "Chandigarh" },
        { id: "CT", name: "Chhattisgarh" },
        { id: "DN", name: "Dadra and Nagar Haveli and Daman and Diu" },
        { id: "DL", name: "Delhi" },
        { id: "GA", name: "Goa" },
        { id: "GJ", name: "Gujarat" },
        { id: "HR", name: "Haryana" },
        { id: "HP", name: "Himachal Pradesh" },
        { id: "JK", name: "Jammu and Kashmir" },
        { id: "JH", name: "Jharkhand" },
        { id: "KA", name: "Karnataka" },
        { id: "KL", name: "Kerala" },
        { id: "LD", name: "Ladakh" },
        { id: "LD", name: "Lakshadweep" },
        { id: "MP", name: "Madhya Pradesh" },
        { id: "MH", name: "Maharashtra" },
        { id: "MN", name: "Manipur" },
        { id: "ML", name: "Meghalaya" },
        { id: "MZ", name: "Mizoram" },
        { id: "NL", name: "Nagaland" },
        { id: "OR", name: "Odisha" },
        { id: "PY", name: "Puducherry" },
        { id: "PB", name: "Punjab" },
        { id: "RJ", name: "Rajasthan" },
        { id: "SK", name: "Sikkim" },
        { id: "TN", name: "Tamil Nadu" },
        { id: "TG", name: "Telangana" },
        { id: "TR", name: "Tripura" },
        { id: "UP", name: "Uttar Pradesh" },
        { id: "UT", name: "Uttarakhand" },
        { id: "WB", name: "West Bengal" }
        // Add more Indian states here if needed
    ],
    "US": [
        { id: "0", name: "Select a state", disabled: true },
        { id: "AL", name: "Alabama" },
        { id: "AK", name: "Alaska" },
        { id: "AZ", name: "Arizona" },
        { id: "AR", name: "Arkansas" },
        { id: "CA", name: "California" },
        { id: "CO", name: "Colorado" },
        { id: "CT", name: "Connecticut" },
        { id: "DE", name: "Delaware" },
        { id: "FL", name: "Florida" },
        { id: "GA", name: "Georgia" },
        { id: "HI", name: "Hawaii" },
        { id: "ID", name: "Idaho" },
        { id: "IL", name: "Illinois" },
        { id: "IN", name: "Indiana" },
        { id: "IA", name: "Iowa" },
        { id: "KS", name: "Kansas" },
        { id: "KY", name: "Kentucky" },
        { id: "LA", name: "Louisiana" },
        { id: "ME", name: "Maine" },
        { id: "MD", name: "Maryland" },
        { id: "MA", name: "Massachusetts" },
        { id: "MI", name: "Michigan" },
        { id: "MN", name: "Minnesota" },
        { id: "MS", name: "Mississippi" },
        { id: "MO", name: "Missouri" },
        { id: "MT", name: "Montana" },
        { id: "NE", name: "Nebraska" },
        { id: "NV", name: "Nevada" },
        { id: "NH", name: "New Hampshire" },
        { id: "NJ", name: "New Jersey" },
        { id: "NM", name: "New Mexico" },
        { id: "NY", name: "New York" },
        { id: "NC", name: "North Carolina" },
        { id: "ND", name: "North Dakota" },
        { id: "OH", name: "Ohio" },
        { id: "OK", name: "Oklahoma" },
        { id: "OR", name: "Oregon" },
        { id: "PA", name: "Pennsylvania" },
        { id: "RI", name: "Rhode Island" },
        { id: "SC", name: "South Carolina" },
        { id: "SD", name: "South Dakota" },
        { id: "TN", name: "Tennessee" },
        { id: "TX", name: "Texas" },
        { id: "UT", name: "Utah" },
        { id: "VT", name: "Vermont" },
        { id: "VA", name: "Virginia" },
        { id: "WA", name: "Washington" },
        { id: "WV", name: "West Virginia" },
        { id: "WI", name: "Wisconsin" },
        { id: "WY", name: "Wyoming" }
        // Add more USA states here if needed
    ]
};