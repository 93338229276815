import React, { useEffect, useState } from 'react';
import { Table, TableBody, Box, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, TableFooter, TablePagination } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import makeApiCall from 'common/makeApiCall';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.common.white,
      },

  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
        background: 'linear-gradient(to right, #ff0093, #7900ff)',
        color: theme.palette.common.white,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));


export default function Adminpage() {
    const [rows, setRows] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [deletableUserId, setDeletableUserId] = useState(null);
    const [open, setOpen] = React.useState(false);

    const fetchUsers = async (page) => {
        try {
            const response = await makeApiCall('get', `/admin/users?page=${page}`);
            setRows(response.data); // Ensure this is an array of user objects
            setTotalPages(response.pagination.totalPages);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    };

    const handleDelete = async () => {
        const id = deletableUserId;
        try {
            const response = await makeApiCall('post', '/admin/users/delete', { id }, 'success message', 'eror message');
            if (response || response.status === 200) {
                handleClose();

                fetchUsers(page); // Reload the users after deletion
            } else {
                console.error('Failed to delete user:', response);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage + 1);
        fetchUsers(newPage + 1);
    };

    useEffect(() => {
        fetchUsers(page); // Fetch users when the component mounts or page changes
    }, [page]);

    const handleClickOpen = (userId) => {
        setDeletableUserId(userId);
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };

    return (
        <Box>
            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                    <StyledTableRow >
                        <StyledTableCell >Username</StyledTableCell>
                        <StyledTableCell >User Role</StyledTableCell>
                        <StyledTableCell >Email</StyledTableCell>
                        <StyledTableCell >Actions</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.id}>
                            <TableCell>{row.userName}</TableCell>
                            <TableCell>{row.userType}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="delete"
                                    onClick={() =>handleClickOpen(row.id)}
                                    // onClick={() => handleDelete(row.id)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[]}
                            colSpan={4}
                            count={totalPages * 10}
                            rowsPerPage={10}
                            page={page - 1}
                            onPageChange={handleChangePage}
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} of ${count}`}
                        />
                    </TableRow>
                </TableFooter>
            </Table>
        </TableContainer>

{/* Confirmation alert for delete */}
        <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
       <DialogTitle>{"Delete User Account?"}</DialogTitle>
<DialogContent>
  <DialogContentText id="alert-dialog-slide-description">
    Are you sure you want to delete this user account?
  </DialogContentText>
</DialogContent>
<DialogActions>
  <Button onClick={handleClose}>Cancel</Button>
  <Button onClick={handleDelete} color="primary">Delete</Button>
</DialogActions>
      </Dialog>


        </Box>
        

    );
}
