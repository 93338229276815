import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled, keyframes } from '@mui/system';
import { Box, Container } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Footer from 'components/footer/Footer';
import Appbar from 'components/appbar/Appbar';
import Artist from '../../assets/images/home/Artist.svg';
import AMP from '../../assets/images/home/AMP.svg';
import Events from '../../assets/images/home/Events.svg';
import Musician from '../../assets/images/home/Musician.svg';
import Eventlist from 'pages/event/eventlist/Eventlist';
import CssBaseline from '@mui/material/CssBaseline';


export default function Homepage() {
    const [openEventList, setOpenEventList] = useState(false);
    return (
        <>
            <Appbar />
            {!openEventList ?
            <>
            <Grid container spacing={4} alignItems="center" justifyContent="center" style={{ minHeight: '100vh', padding: 5 }}>
                <Grid item xs={12} md={6} container direction="column" alignItems="center" justifyContent="center">
                    <Box align="center" sx={{ marginTop: 7 }}>
                        <AnimatedTypography variant="h1" gutterBottom align="center" sx={{ marginBottom: 4 }}>
                            Music for everyone.
                        </AnimatedTypography>
                        <AnimatedTypography variant="subtitle1" gutterBottom align="center" sx={{ marginBottom: 5 }}>
                            Connecting organizers with artists,<br />
                            our platform makes finding & booking talent effortless.<br />
                            Discover musicians, schedule performances, and manage events seamlessly.<br />
                            streamline your music event planning.<br />
                            Join our community to bring music to life.
                        </AnimatedTypography>
                        <GradientButton variant="contained" size="large" onClick={() => setOpenEventList(true)}>Events</GradientButton>
                    </Box>

                </Grid>

                <Grid item xs={12} md={6} container justifyContent="center">
                    <img src="desktop.svg" alt="" style={{ maxWidth: '90%', height: 'auto' }} />
                </Grid>
            </Grid>
            <Grid container spacing={4} alignItems="center" justifyContent="center" style={{ minHeight: '100vh', padding: 5 }}>
                <Grid item xs={12} md={10} container direction="column" alignItems="center" justifyContent="center">
                    <Box align="center" sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <AnimatedTypography variant="h1" gutterBottom align="center" sx={{ marginBottom: 4 }}>
                            Our Concept
                        </AnimatedTypography>
                        <AnimatedTypography variant="subtitle1" gutterBottom align="center" sx={{ marginBottom: 6 }}>
                            Our platform empowers artists by providing a dynamic space to showcase their talents and connect with their audience. With features like profile creation, social media integration, and content posting, artists can share their work and collaborate with others. Join our diverse community of visual artists, musicians, coders, and multimedia creators to celebrate creativity and innovation together.
                        </AnimatedTypography>
                    </Box>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5, justifyContent: "center" }}>
                        <Card variant="outlined">

                            <CardMedia
                                sx={{ height: 290, width: 250, objectFit: 'contain' }}
                                image={Artist}
                                title="Soul Music"
                            />
                            <Typography variant="h5" align='center' component="div">
                                Artist
                            </Typography>
                        </Card>
                        <Card variant="outlined">
                            <CardMedia
                                sx={{ height: 290, width: 250 }}
                                image={Events}
                                title="Live Concerts"
                            />
                            <Typography variant="h5" align='center' component="div">
                                Organizers
                            </Typography>
                        </Card>
                        <Card variant="outlined">
                            <CardMedia
                                sx={{ height: 290, width: 250 }}
                                image={AMP}
                                title="Dj Sets"
                            />
                            <Typography variant="h5" align='center' component="div">
                                AMP
                            </Typography>
                        </Card>
                        <Card variant="outlined">
                            <CardMedia
                                sx={{ height: 290, width: 250 }}
                                image={Musician}
                                title="Live Streems"
                            />
                            <Typography variant="h5" align='center' component="div">
                                Musicians
                            </Typography>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
            <Footer />
            </>:
            // For display event list without login
            <React.Fragment>
            <CssBaseline />
            <Container fixed sx={{marginTop:10, marginBottom:2}}>
              <Box  >
              <Eventlist />
              </Box>
            </Container>
          </React.Fragment>

            
}
        </>

    )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;
const AnimatedTypography = styled(Typography)({
    animation: `${fadeIn} 2s ease-in-out`,
});
const GradientButton = styled(Button)({
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    border: 0,
    borderRadius: 32,
    size: 20,

    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    width: 200,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(to right, #ff0093, #7900ff)',
    },
});