import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// material-ui
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Box,
  FormGroup,
  Autocomplete,
  TextField,
  Chip,
  Link,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Typography,
  MenuItem,
  Select
} from '@mui/material';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';

// project import
import AnimateButton from 'components/@extended/AnimateButton';

// assets
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import makeApiCall from 'common/makeApiCall';

const dynamicValidationSchema = Yup.object().shape({
  userName: Yup.string().max(255).required('Name is required'),
  email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
  password: Yup.string().min(8, 'Password must be at least 8 characters').max(255).required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm Password is required'),
  mobileNumber: Yup.string()
    .matches(/^\d{10}$/, 'Mobile Number must be 10 digits')
    .required('Mobile Number is required'),
  userType: Yup.string()
    // .oneOf(['AR', 'ORG', 'AMP', 'ATR'], 'User Type is required')
    .required('User Type is required')
})

const AdminAuthRegister = ({ adminUserType, onSuccess }) => {
  const userId = localStorage.getItem('userId');
  const userType = localStorage.getItem('userType');
  const [organizersByAdmin, setOrganizersByAdmin] = useState([]);
  const [existingOrganizerDetails, setExistingOrganizerDetails] = useState(null);
  const [selectedOrganizer, setSelectedOrganizer] = useState(null);

  const [showPassword, setShowPassword] = useState(false);
const {userName, email, mobileNumber, id} = existingOrganizerDetails || [];

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedOrganizer(newValue);
  };

  useEffect(() => {
    fetchOrganizerList();
  }, []);

  const fetchOrganizerList = async () => {
    try {
      const organizerData = await makeApiCall('get', `/admin/organizer/list`, {});
      setOrganizersByAdmin(organizerData?.data);
    } catch (error) {
      // setError(error);
    }
  };

  const fetchOrganizerDetails = async (event) => {
    event.preventDefault(); // Prevent the form from reloading the page
    const data = {
      userId: selectedOrganizer?.id
    };

    try {
      const organizerData = await makeApiCall('post', `/admin/organizer/credentials`, data);
      setExistingOrganizerDetails(organizerData?.data);
    } catch (error) {
      // setError(error);
    }
  };

  return (
    <>
      <Grid container sx={{ alignContent: 'center', justifyContent: 'center', alignItems: 'center', marginBottom: '20px' }}>
        {adminUserType === 'ORGAdmin' && (
          <>
            <form noValidate onSubmit={fetchOrganizerDetails}>
              <Typography variant="h5" style={{ textAlign: 'center' }}>
                Organizer
              </Typography>
              <Grid item xs={12} md={5}>
                <Box>
                  <FormGroup>
                    <Autocomplete
                      sx={{
                        '& .MuiAutocomplete-paper': {
                          backgroundColor: 'white'
                        },
                        width: 400
                      }}
                      value={selectedOrganizer} // Set the value to the selected value from state
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      onChange={handleAutocompleteChange} // Handle change to update state
                      id="size-small-filled-multi"
                      size="small"
                      options={organizersByAdmin ? organizersByAdmin : []}
                      PopperProps={{
                        PaperProps: {
                          style: {
                            backgroundColor: 'white' // Set background color here
                          }
                        }
                      }}
                      getOptionLabel={(option) => option.userName}
                      renderTags={(value, getTagProps) =>
                        value &&
                        value.map((option, index) => (
                          <Chip key={index} variant="outlined" label={option.id} size="small" {...getTagProps({ index })} />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Organizer Name"
                          sx={{
                            color: 'black',
                            backgroundColor: 'white',
                            borderRadius: 1,
                            mb: 2,
                            mt: 2,
                            '& .MuiFilledInput-root': {
                              backgroundColor: 'White' // Ensures consistency with TextField
                            },
                            '& .MuiAutocomplete-tag': {
                              backgroundColor: 'White' // Styles for autocomplete tags
                            },
                            '& .MuiFormLabel-root': {
                              height: '20px'
                            }
                          }}
                          autoComplete="off" // Use "off" to hint the browser to disable autofill
                          placeholder="Select Organizer"
                        />
                      )}
                    />
                  </FormGroup>
                </Box>
              </Grid>

              <AnimateButton>
                <Button
                  disableElevation
                  disabled={!selectedOrganizer}
                  fullWidth
                  size="medium"
                  type="submit"
                  variant="contained"
                  sx={{ background: 'linear-gradient(to right, #ff0093, #7900ff)', marginBottom:'15px' }}
                >
                  Go
                </Button>
              </AnimateButton>
              {existingOrganizerDetails &&
              <AnimateButton>
                <Button
                  disableElevation
                  fullWidth
                  onClick={()=> {
                    setExistingOrganizerDetails('');
                    setSelectedOrganizer(null);
                  }}
                  size="medium"
                  variant="contained"
                  // sx={{ background: 'linear-gradient(to right, #ff0093, #7900ff)' }}
                >
                  Reset
                </Button>
              </AnimateButton>}
            </form>
          </>
        )}
      </Grid>

      <Formik
        initialValues={{
          userName: userName?userName:'',
          email: email?email:'',
          password: '',
          mobileNumber: mobileNumber?mobileNumber:'',
          userType:
            adminUserType === 'ARAdmin'
              ? 'AR'
              : adminUserType === 'ORGAdmin'
                ? 'ORG'
                : adminUserType === 'AMPAdmin'
                  ? 'AMP'
                  : adminUserType === 'ATRAdmin'
                    ? 'ATR'
                    : 0
        }}
        enableReinitialize
        validationSchema={existingOrganizerDetails?null:dynamicValidationSchema}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          if (existingOrganizerDetails) {
            onSuccess(id);
            setSubmitting(false);
            return true;
          }
          const createdBy = userType ? userType : 'ADM';
          const createdUserId = userId;
          const newValues = {
            ...values,
            createdUserId,
            createdBy
          };

          try {
            const responseData = await makeApiCall('post', '/auth/register', newValues, 'Registration successful', 'Registration failed');
            setStatus({ success: true });
            setSubmitting(false);
            console.log('Registration successful', responseData);
            // navigate("/login");
            const userId = responseData.user.id;
            if (onSuccess) {
              onSuccess(userId); // Pass the user ID back to the parent component
            }
          } catch (err) {
            console.error('Registration failed', err);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
          <form noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="name-signup">Name*</InputLabel>
                  <OutlinedInput
                    id="name-signup"
                    type="text"
                    value={values.userName}
                    disabled={userName}
                    name="userName"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="John Doe"
                    fullWidth
                    error={Boolean(touched.userName && errors.userName)}
                  />
                  {touched.userName && errors.userName && (
                    <FormHelperText error id="helper-text-name-signup">
                      {errors.userName}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="email-signup">Email Address*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.email && errors.email)}
                    id="email-signup"
                    type="email"
                    value={values.email}
                    disabled={email}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    placeholder="demo@company.com"
                    inputProps={{}}
                  />
                  {touched.email && errors.email && (
                    <FormHelperText error id="helper-text-email-signup">
                      {errors.email}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              {!existingOrganizerDetails && (
                <>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="password-signup">Password*</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.password && errors.password)}
                        id="password-signup"
                        type={showPassword ? 'text' : 'password'}
                        value={values.password}
                        name="password"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="******"
                        inputProps={{}}
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          borderRadius: 4
                        }}
                      />
                      {touched.password && errors.password && (
                        <FormHelperText error id="helper-text-password-signup">
                          {errors.password}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <InputLabel htmlFor="confirm-password-signup">Confirm Password*</InputLabel>
                      <OutlinedInput
                        fullWidth
                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        id="confirm-password-signup"
                        type={showPassword ? 'text' : 'password'}
                        value={values.confirmPassword}
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                              size="large"
                            >
                              {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                            </IconButton>
                          </InputAdornment>
                        }
                        placeholder="******"
                        inputProps={{}}
                        style={{
                          backgroundColor: 'white',
                          color: 'black',
                          borderRadius: 4
                        }}
                      />
                      {touched.confirmPassword && errors.confirmPassword && (
                        <FormHelperText error id="helper-text-confirm-password-signup">
                          {errors.confirmPassword}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="mobile-number-signup">Mobile Number*</InputLabel>
                  <OutlinedInput
                    fullWidth
                    error={Boolean(touched.mobileNumber && errors.mobileNumber)}
                    type="text" // Change to "text" to prevent increment/decrement arrows
                    value={values.mobileNumber}
                    disabled={mobileNumber}
                    name="mobileNumber"
                    id="outlined-number"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, ''); // Use regex to allow only numbers
                      handleChange({ target: { name: 'mobileNumber', value: value.slice(0, 10) } }); // Limit input to 10 digits
                    }}
                    placeholder="Mobile number"
                    inputProps={{
                      maxLength: 10 // Limit input length to 10 characters
                    }}
                  />
                  {touched.mobilenumber && errors.mobilenumber && (
                    <FormHelperText error id="helper-text-mobileNumber-signup">
                      {errors.mobilenumber}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <InputLabel htmlFor="user-type-signup">User Type*</InputLabel>
                  <FormControl fullWidth>
                    <Select
                      id="user-type-signup"
                      value={values.userType}
                      // inputProps={{ readOnly: userType === 'AD' }}
                      disabled={userType === 'AD'}
                      name="userType"
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <MenuItem value="0" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="AR">Artist</MenuItem>
                      <MenuItem value="ORG">Organizer</MenuItem>
                      <MenuItem value="AMP">Allied Music Professionals</MenuItem>
                      <MenuItem value="ATR">Artists & Repertoire</MenuItem>
                    </Select>
                  </FormControl>
                  {touched.userType && errors.userType && (
                    <FormHelperText error id="helper-text-userType-signup">
                      {errors.userType}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  By Signing up, you agree to our &nbsp;
                  <Link variant="subtitle2" component={RouterLink} to="#">
                    Terms of Service
                  </Link>
                  &nbsp; and &nbsp;
                  <Link variant="subtitle2" component={RouterLink} to="#">
                    Privacy Policy
                  </Link>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AnimateButton>
                  <Button
                    disableElevation
                    fullWidth
                    // size="large"
                    type="submit"
                    variant="contained"
                    sx={{ background: 'linear-gradient(to right, #ff0093, #7900ff)' }}
                  >
                    {existingOrganizerDetails?"Next":"Create Organizer and Proceed"}
                  </Button>
                </AnimateButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AdminAuthRegister;
