import * as React from 'react';
import { Grid, CardMedia, Typography, Chip, Stack, Box, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import makeApiCall from 'common/makeApiCall';
import profile from '../../assets/images/users/profile.png';

export default function Ampview() {
  const { id } = useParams();
  const [artist, setArtist] = useState(null);
  const [error, setError] = useState(null);

  // ===================|EVENT DETAILS API CALL WITH SINGLE ID|========================//
  useEffect(() => {
    const fetchArtist = async () => {
      console.log('id', id);
      try {
        const artistData = await makeApiCall('get', `/amps/ampsview/${id}`);
        setArtist(artistData);
        console.log('res', artistData);
      } catch (error) {
        setError(error);
      }
    };
    fetchArtist();
  }, [id]);

  if (error) {
    return <Typography color="error">Failed to load artist details. Please try again later.</Typography>;
  }
  if (!artist) {
    return <Typography>Loading...</Typography>;
  }

  const getPaymentStatusLabel = (status) => {
    switch (status) {
      case 'P':
        return 'PAID';
      case 'UP':
        return 'UNPAID';
      default:
        return 'UNPAID';
    }
  };
  const getRatesLabel = (ratevalue) => {
    switch (ratevalue) {
      case 'PD':
        return 'Per Day';
      case 'PH':
        return 'Per Hour';
      case 'PJ':
        return 'Per Project';
      default:
        return 'Per Hour';
    }
  };

  // Function to map boolean work availability to display text
  const getActiveStatusLabel = (status) => {
    if (status === true) {
      return 'AVAILABLE';
    } else if (status === false) {
      return 'UNAVAILABLE';
    } else {
      return 'Unknown';
    }
  };

  // Function to capitalize the first letter of a string
  const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <Grid container spacing={3} justifyContent="center">
      {/* Artist Image and Basic Info */}
      <Grid item xs={12} md={6} sx={{ display: 'flex', textAlign: 'flex-start', flexDirection: 'column', alignItems: 'center' }}>
        {/* Profile Image and Basic Info */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { md: '180%', xs: '100%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <CardMedia
            component="img"
            sx={{ width: 151, height: 151, objectFit: 'cover', borderRadius: 3, mb: { md: 1, xs: 0 } }}
            image={profile}
            alt="profile"
          />
          <Box sx={{ ml: { xs: 2, md: 0 }, textAlign: 'left' }}>
            <Typography variant="h3" gutterBottom>
              {capitalizeFirstLetter(artist.fullName)}
            </Typography>

            <Typography variant="subtitle1">
              {artist.city}, {artist.stateLabel}, {artist.countryLabel}
            </Typography>

            <Typography variant="body1" gutterBottom>
              {artist.email}
            </Typography>

            <Link href={artist.website} target="_blank" sx={{ color: '#fff' }}>
              {artist.website}
            </Link>

            <Typography variant="body1" gutterBottom>
              {artist.openForWork}
            </Typography>

            <Stack direction="row" sx={{ cursor: 'pointer', flexWrap: 'wrap', gap: 1 }}>
              <Chip
                sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                label={getActiveStatusLabel(artist.openForWork)}
                color="primary"
                variant="outlined"
              />

              <Chip
                sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                label={getPaymentStatusLabel(artist.paymentStatus)}
                color="primary"
                variant="outlined"
              />

              {artist.paymentStatus === 'P' && (
                <>
                  <Chip
                    sx={{
                      borderRadius: 4,
                      borderColor: '#fff',
                      color: '#fff',
                      minWidth: '80px'
                    }}
                    label={artist.currencyType}
                    color="primary"
                    variant="outlined"
                  />

                  <Chip
                    sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                    label={artist.amount}
                    color="primary"
                    variant="outlined"
                  />
                  <Chip
                    sx={{ borderRadius: 4, borderColor: '#fff', color: '#fff', minWidth: '80px' }}
                    label={getRatesLabel(artist.rates)}
                    color="primary"
                    variant="outlined"
                  />
                </>
              )}
            </Stack>
          </Box>
        </Box>
        {/* YouTube Video */}
        <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom>
            Services
          </Typography>

          {/* <Stack direction="row" spacing={1} sx={{mb:2,cursor: 'pointer',flexWrap: 'wrap',gap:1,ml:20}} backgroundColor='red'> */}
          <Stack direction="row" sx={{ mb: 2, cursor: 'pointer', flexWrap: 'wrap', gap: 1 }}>
            {artist.serviceLabels.map((value, index) => (
              <Chip
                key={index}
                sx={{
                  borderColor: '#fff',
                  color: '#fff',
                  borderRadius: 4,
                  minWidth: '80px'
                  // mb:2
                }}
                label={value}
                variant="outlined"
              />
            ))}
          </Stack>
          </Box>
          <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Service Description:
          </Typography>
          <Grid item xs={12} sx={{ textAlign: { xs: 'start', md: 'start' } }}>
          <Box sx={{  textAlign: 'justify' }}>
          <Typography variant="body1" sx={{textIndent: '200px'}}>{artist.serviceDescription}</Typography>
          </Box>
          </Grid>
          </Box>

          <Box
          sx={{
            p: { xs: 2, md: 3 },
            borderRadius: 2,
            boxShadow: 3,
            backgroundColor: '#616161',
            color: '#fff',
            width: { xs: '100%', md: '180%' }, // Full width
            mb: { xs: 1, md: 2 }
          }}
        >
          
          <Typography variant="h4" gutterBottom sx={{ textAlign: { xs: 'left', md: 'left' } }}>
            Timing
          </Typography>

          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', gap: 1, flex: 'start' }}>
            <Typography variant="h6" size="small">
              Timing {artist.StartTime} to {artist.EndTime}
            </Typography>
          </Stack>
        </Box>
        
      </Grid>
    </Grid>
  );
}
