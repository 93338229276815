export const PerformanceTypeList = [
  { value: 'LP', label: 'Live Performance' },
  { value: 'SM', label: 'Session Musicians' },
  { value: 'CO', label: 'Collaboration' },
  { value: 'HI', label: 'Hire' },
  { value: 'OM', label: 'Open Mic' },
  { value: 'MF', label: 'Music Festivals' }
];
export const instrumentList = [
  { value: 'GU', label: 'Guitar' },
  { value: 'PI', label: 'Piano' },
  { value: 'UK', label: 'Ukulele' },
  { value: 'VI', label: 'Violin' },
  { value: 'DR', label: 'Drums' },
  { value: 'CE', label: 'Cello' },
  { value: 'FL', label: 'Flute' },
  { value: 'OT', label: 'Others' }
];
export const genreList = [
  { value: 'JA', label: 'Jazz' },
  { value: 'PO', label: 'Pop' },
  { value: 'RO', label: 'Rock' },
  { value: 'CL', label: 'Classical' },
  { value: 'HH', label: 'Hip-Hop' },
  { value: 'RA', label: 'Rap' },
  { value: 'FM', label: 'Folk Music' },
  { value: 'CM', label: 'Country Music' },
  { value: 'BL', label: 'Blues' },
  { value: 'OT', label: 'Others' }
];

export const serviceDetailList = [
  { value: 'RS', label: 'Recording Studios' },
  { value: 'SE', label: 'Sound Engineer' },
  { value: 'VI', label: 'Videography' },
  { value: 'PH', label: 'Photography' },
  { value: 'MI', label: 'Mixing' },
  { value: 'MA', label: 'Mastering' },
  { value: 'ED', label: 'Editing' },
  { value: 'PR', label: 'Producer' },
  { value: 'ARP', label: 'A & R Professional' },
  { value: 'TSP', label: 'Talent Search Professional' },
  { value: 'OT', label: 'Others' }
];