// assets

import LyricsIcon from '@mui/icons-material/Lyrics';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import PianoIcon from '@mui/icons-material/Piano';
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic';


// icons
const icons = {
    LyricsIcon,
    QueueMusicIcon,
    PersonAddAltIcon,
    GraphicEqIcon,
    PianoIcon,
    LibraryMusicIcon
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //
const userType = localStorage.getItem("userType");

const Artist = userType === 'AR' && {
    id: 'utilities',
    title: 'Profile',
    type: 'group',
    children: [
        userType === 'AR' && {
            id: 'util-artistprofile',
            // title: 'Create Artist Profile',
            title: 'Artist Profile',
            type: 'item',
            url: '/dashboard/artistprofile',
            icon: icons.PersonAddAltIcon
        },
        userType === 'AR' && {
            id: 'util-eventlist',
            title: 'Event Lists ',
            type: 'item',
            url: '/dashboard/eventlist',
            icon: icons.QueueMusicIcon
        },
        userType === 'AR' && {
            id: 'util-amplist',
            title: 'Amp Lists ',
            type: 'item',
            url: '/dashboard/amplist',
            icon: icons.GraphicEqIcon
        },
        userType === 'AR' && {
            id: 'util-sessionmusicians',
            title: 'Session Musicians',
            type: 'item',
            url: '/dashboard/session-musicians',
            icon: icons.PianoIcon
        },
        userType === 'AR' && {
            id: 'util-artistrepertoire',
            title: 'Artist & Repertoire',
            type: 'item',
            url: '/dashboard/artist-repertoire',
            icon: icons.LibraryMusicIcon
        },

    ].filter(Boolean)
};

export default Artist;
