import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useNavigate } from 'react-router-dom';
import { Box, Menu, MenuItem, Typography, Link } from '@mui/material';

// const pages = ['Products', 'Pricing', 'Blog'];
// Custom styled AppBar

// Custom styled AppBar
const CustomAppBar = styled(AppBar)({
    display: "flex",
    height: 50,
    borderRadius: 8,
    backdropFilter: 'blur(10px) saturate(150%)',
    backgroundColor: 'rgba(255, 255, 255, 0.30)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'fixed', // Ensure fixed positioning for consistent placement
    top: 15,
    left: 20,
    right: 20,
    width: 'calc(100% - 35px)',
});

// Custom styled Toolbar
const CustomToolbar = styled(Toolbar)({
    minHeight: 80,
    display: 'flex',
    padding:0, // For Logo of Mobile view
    justifyContent: 'space-between',
    alignItems: 'center',
});
export default function Appbar() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const navigate = useNavigate(); // Use navigate hook for routing

    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLoginOption = (option) => {
        handleClose(); // Close dropdown after selection
        navigate(option.path); // Navigate to the selected login route
    };

    return (
        <CustomAppBar>
            <Container maxWidth="xl">
                <CustomToolbar >

                    <Typography
                        variant="h6"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MUSICINDUSTREE
                    </Typography>

                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            // onClick={handleOpenNavMenu}
                            color="inherit"
                        >

                        </IconButton>
                    </Box> */}

                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            fontSize:'13px',
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        MUSICINDUSTREE
                    </Typography>
                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page}
                                // onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box> */}

                    <Box sx={{ flexGrow: 0 }}>
                        <Link href="/login">
                            <GradientButton>
                                Login
                            </GradientButton>
                        </Link>
                        <Menu
                            id="login-menu"
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            MenuListProps={{ 'aria-labelledby': 'login-button' }}
                        >
                            <MenuItem onClick={() => handleLoginOption({ path: '/login', })}>
                                Login as an Artist
                            </MenuItem>
                            <MenuItem onClick={() => handleLoginOption({ path: '/login', })}>
                                Login as an Organizer
                            </MenuItem>
                        </Menu>
                    </Box>
                </CustomToolbar>
            </Container>
        </CustomAppBar>
    )
}
const GradientButton = styled(Button)({
    background: 'linear-gradient(to right, #ff0093, #7900ff)',
    border: 0,
    borderRadius: 32,
    size: 20,

    color: 'white',
    height: 35,
    width: 130,
    padding: '0 30px',
    '&:hover': {
        background: 'linear-gradient(to right, #ff0093, #7900ff)',
    },
});