// project import
import pages from './pages';
import dashboard from './dashboard';
import utilities from './utilities';
import Organizerlist from './Organizerlist';
import Artist from './Artist';
import Amp from './Amp';
import Admin from './Admin';
import ArtistsRepertoire from './ArtistsRepertoire';
import Unknown from './Unknown';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, pages, utilities, Organizerlist, Artist, Amp, ArtistsRepertoire, Admin,Unknown]
};

export default menuItems;
